import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { withRouter } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// import images
import cresusFond from "../../assets/images/cresusfond.png"
import axios from "axios"
import API from "../../api"

const Login = props => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [years, setYears] = useState([])
  // const years = [...Array(20).keys()].map(i => new Date().getFullYear() - i) 
  const signIn = async () => {
    const res = await axios.post("https://backcommercial.cresus-institut.ovh/api/login", {
     //const res = await axios.post("http://localhost:8000/api/login", {
      email,
      password,
      api_password: "sKgkGkRhObr0kXdrNyjW5S",
      year: selectedYear,

    }).then(
      res => {
        if (res.data.status === 200) {
          localStorage.setItem("userAuth", JSON.stringify(res.data))

          props.history.push("/dashboard")
        } else {
          toast.error("⛔ Veuillez vérifier votre login et mot de passe !", {
            containerId: "A",
          })

        }
      }
    )

  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get("/annee/get").then(
          resC => {
            setYears(resC.data)
           
          }
        )
        // setYears(response.data);

      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();

    // Cleanup function
    return () => {
      // Vous pouvez mettre ici le code de nettoyage si nécessaire
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${cresusFond})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "120vh",
        width: "100vw",
      }}
    >
      <React.Fragment>
        <MetaTags>
          <title>Login | Cresus</title>
        </MetaTags>
        <div style={{ paddingTop: "10%" }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div>
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Bienvenue </h5>
                          <p>Connectez-vous pour continuer à Cresus ERP.</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <AvForm className="form-horizontal">
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            required
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="year">Année</label>
                          <select
                            id="year"
                            name="year"
                            className="form-control"
                            value={selectedYear}
                            onChange={e => setSelectedYear(e.target.value)}
                          >
                            {years.map(year => (
                              <option key={year.value} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            onClick={signIn}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            CONNECTER
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-1 text-center">
                  <p>
                    © {new Date().getFullYear()} Cresus. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by{" "}
                    <a href="https://cresus.pro/">cresus.pro</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
