import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import API from "../../../api";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useSectionContext } from "pages/Inventaire/Inventaire";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [depotNotifications, setDepotNotifications] = useState([]);

  useEffect(() => {
    const checkDepot = async () => {
      const response = await API.get("inventaire/verif_inventaire");
      setDepotNotifications(response.data.lignes);
    };

    checkDepot();

    const intervalId = setInterval(checkDepot, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const getColorForMessage = (notification) => {
    if (notification.color === "red") {
      return "red";
    } else if (notification.color === "yellow") {
      return "balck";
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i style={{ color: "white" }} className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {depotNotifications.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{props.t("Notifications")}</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {depotNotifications.map((notification, index) => (
              <Link
                to={`/Inventaire/`} 
                className="text-reset notification-item"
                key={index}
              >
                <div className="media">
                  <div className="media-body">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{
                        marginRight: "5px",
                        color: getColorForMessage(notification),
                      }}
                    />
                    <span
                      className="mt-0 mb-1"
                      style={{ color: getColorForMessage(notification) }}
                    >
                      {notification.warning_message}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi me-1"></i>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
