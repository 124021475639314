import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import classnames from "classnames"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap"

const DetailProduit = (props) => {
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  const [dateDeb, setDateDeb] = useState(new Date())
  const [dateFin, setDateFin] = useState(new Date())
  const [user, setUser] = useState("")
  const [arrayProduit, setArrayProduit] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        setId(id)
        const response = await API.post("inventaire/consulter_details_inventaire", {
          id: id
        })
        const data = response.data

        setId(data.id)
        setDateDeb(new Date(data.date_debut))
        setDateFin(new Date(data.date_fin))
        setUser(data.user_name)
        setArrayProduit(data)
        setLoading(true)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <AvForm>
                    <Form>
                      <Row>
                        <Col>
                          <Card className="overflow-hidden">
                            <CardBody>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Informations :
                              </CardTitle>
                              <Row>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input4">
                                      Code
                                    </Label>
                                    <AvField
                                      name="code"
                                      placeholder="code"
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={id}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Date debut</Label>
                                    <DatePicker
                                      name="Date debut"
                                      selected={dateDeb}
                                      className="form-control ddate"
                                      dateFormat="dd/MM/yyyy"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Date fin</Label>
                                    <DatePicker
                                      name="Date fin"
                                      selected={dateFin}
                                      className="form-control ddate"
                                      dateFormat="dd/MM/yyyy"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input4">
                                      Utilisateur
                                    </Label>
                                    <AvField
                                      name="user"
                                      placeholder="user"
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={user}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <CardTitle className="h4 mt-4"></CardTitle>
                              <div>
                                {Object.keys(arrayProduit.lignes_par_depot).map((depotId, index) => (
                                  <div key={index}>
                                    <h5> {arrayProduit.lignes_par_depot[depotId].designation}</h5>
                                    <table className="table table-bordered mt-4">
                                      <thead>
                                        <tr>
                                        <th style={{ textAlign: "center" }} scope="col">
                                            Code article
                                          </th>
                                          <th style={{ textAlign: "center" }} scope="col">
                                            Article
                                          </th>
                                          <th style={{ textAlign: "center" }} scope="col">
                                            Quantité après inventaire
                                          </th>
                                          <th style={{ textAlign: "center" }} scope="col">
                                            Quantité avant inventaire
                                          </th>
                                         
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {arrayProduit.lignes_par_depot[depotId].lignes_inv.map((ligne, index) => (
                                          <tr key={index}>
                                            <td style={{ textAlign: "center" }}>{ligne.article_code}</td>

                                            <td style={{ textAlign: "center" }}>{ligne.article_name}</td>
                                            <td style={{ textAlign: "center" }}>{ligne.quantite_apres_inventaire}</td>
                                            <td style={{ textAlign: "center" }}>{ligne.quantite_avant_inventaire}</td>
                                           
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                ))}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div className="text-center mt-4">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Produits")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </AvForm>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default DetailProduit
DetailProduit.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  history: PropTypes.object,
}
