import React, { useEffect, useState, Fragment } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"

import {
    Col,
    Form,
    Label,
    Row,
    CardTitle,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const detailReglementClient = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [id, setId] = useState("")
    const [client, setClient] = useState([])
    const [selectClient, setSelectClient] = useState("")
    const [date, setDate] = useState(new Date())
    const [dateEsp, setDateEsp] = useState("")
    const [dateEch, setDateEch] = useState("")
    const [dateEnc, setDateEnc] = useState("")
    const [listFacture, setListFacture] = useState([])
    const [blockList, setBlockList] = useState(false)
    const [disable, setDisable] = useState(false)
    const [mntTotal, setMntTotal] = useState(0)
    const [modePay, setModePay] = useState([])
    const [banque, setBanque] = useState([])
    const [compte, setCompte] = useState([])
    const [modal, setModal] = useState(false)
    const [indexLigne, setIndexLigne] = useState("")
    const [taux, setTaux] = useState([])
    const [arrayIdFacture, setArrayIdFacture] = useState([])
    const [blockPaiement, setBlockPaiement] = useState(false)
    const [facture, setFacture] = useState([]);
    const [loading, setLoading] = useState(false)

    const [arrayModePaiement, setArrayModePaiement] = useState([])

    useEffect(async () => {
            var url = window.location.href
            var array = url.split("=")
            var id = array[1]
            setId(id)
            const res = await API.post("reglement/client/get_by_id", { id }).then(
                ({ data }) => {
                    // setDisbutReg()
                    setSelectClient(data.ReglementClient.client_id);
                    setArrayModePaiement(data.ReglementClient.arrayModePaiement);
                }
            );

            setLoading(true);

        }
        , [])


    const toggle = indexOfLigne => {
        setModal(!modal)
        setIndexLigne(indexOfLigne)
    }

    const toggleDelete = async () => {
        arrayModePaiement.splice(indexLigne, 1)
        setModal(false)
    }
    const search = async () => {
        if (selectClient != null) {
            const res = await API.post("reglement/client/list_cli", {
                client_id: selectClient.value,
            }).then((res) => {
                setListFacture(res.data.List);
                setBlockList(true);
            });

        }
    };
    const pay = async () => {
        if (selectClient != null) {
            const resA = await API.post("reglement/client/list_cli", {
                client_id: selectClient.value,
        }).then((resA) => {
            const numeros = resA.data.List.map((facture) => facture.numero);
            setFacture(numeros);
            setBlockPaiement(true);
        }
        );
    }}
    return (
        <React.Fragment >
            <div  className="page-content card">
                <Form  >
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Client</Label>
                                <Select
                                    value={selectClient}
                                    isSearchable={true}
                                    isDisabled={true}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">Date</Label>
                                <Col md={12} className="pr-0">
                                    <DatePicker
                                        className="form-control ddate"
                                        selected={date}
                                        dateFormat="dd/MM/yyyy"
                                        disabled
                                    />
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <div
                    className="mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    {" "}
                    <div className="text-center mt-4">
                        <button
                            type="button"
                            style={{
                                backgroundColor: "#761C19",
                                borderColor: "#761C19",
                                color: "white",
                            }}
                            className="btn mb-2 me-2"
                            onClick={search}
                        >
                            Afficher
                        </button>
                    </div>
                </div>
                {blockList === true ? (
                    <Fragment>
                        {" "}
                        <table className="table mt-4">
                            <thead className="table-light">
                            <tr>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Num Facture
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Date
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant HT
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant TTC
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant déja payé
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant restant
                                </th>
                            </tr>
                            </thead>
                            {listFacture.map((el, index) => (
                                <tbody key={el.id}>
                                <tr>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                color: "#5784BA",
                                                textDecoration: "underline",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {el.numero}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {el.date}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.totalHT).toFixed(3)}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.mntTotal).toFixed(3)}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.mntPaye).toFixed(3)}
                                        </p>
                                    </td>

                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <input
                                            type="number"
                                            disabled
                                            value={Number.parseFloat(el.mntReste).toFixed(3)}
                                            style={{
                                                textAlign: "center",
                                                borderRadius: "5px",
                                            }}
                                        />
                                    </td>
                                    <td className="col-md-1">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>

                        <div
                            className="mb-4"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            {" "}
                            <div
                                className="text-center mt-4"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <button type="button" className="btn btn-primary " onClick={pay}>
                                    Procédure de paiement
                                </button>
                            </div>
                        </div>

                        {blockPaiement === false ? (
                            <Fragment>
                                <Row className="mb-2">
                                    <Col sm="4">
                                        <CardTitle
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                            className="h4 mt-4"
                                        >
                                            Mode de paiement :{" "}
                                        </CardTitle>
                                    </Col>
                                </Row>
                                <Row>
                                    {arrayModePaiement.map((el, index) => (

                                        <Col key={index} lg="12" className="pt-4">
                                            <Form>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Facture
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            value={el.numfact}
                                                            isSearchable={true}
                                                            //F onChange={e => addFacture(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Mode de paiement
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            // value={modePay}
                                                            value={el.mode_paiement}
                                                            isSearchable={true}
                                                            // onChange={e => addModePay(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Montant
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            value={el.montantdejapaye}
                                                            // onChange={e => addMnt(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                {el.modPay === 1 ? (
                                                    <div className="row mb-4">
                                                        <Label
                                                            htmlFor="horizontal-firstname-Input"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Date
                                                        </Label>
                                                        <Col md={9} className="pr-0">
                                                            <DatePicker
                                                                placeholderText="__/__/____"
                                                                className="form-control ddate"
                                                                // selected={dateEsp}
                                                                value={el.dateEsp}
                                                                //   onChange={e => addDate(e, index)}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </Col>
                                                    </div>
                                                ) : null}
                                                {el.modPay === 2 ? (
                                                    <Fragment>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Echeance
                                                            </Label>
                                                            <Col sm={9}>
                                                                <DatePicker
                                                                    placeholderText="__/__/____"
                                                                    className="form-control ddate"
                                                                    //selected={dateEch}
                                                                    value={el.dateEch}
                                                                    //     onChange={e => addDateEch(e, index)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Numéro cheque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="..."
                                                                    value={el.numCheque}
                                                                    //onChange={e => addNumCheque(e, index)}
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Banque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Select
                                                                    value={el.banque}
                                                                    isSearchable={true}
                                                                    // onChange={e => addBanque(e, index)}
                                                                />
                                                            </Col>
                                                        </div>
                                                    </Fragment>
                                                ) : el.modPay === 3 ? (
                                                    <Fragment>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Date Encaisement
                                                            </Label>
                                                            <Col sm={9}>
                                                                <DatePicker
                                                                    className="form-control ddate"
                                                                    //selected={dateEnc}
                                                                    value={el.encaissement}
                                                                    //onChange={e => addDateEnc(e, index)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Numéro cheque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Input
                                                                    value={el.numCheque}
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="..."
                                                                    // onChange={e => addNumCheque(e, index)}
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Banque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Select
                                                                    value={el.banque}
                                                                    isSearchable={true}
                                                                    // onChange={e => addBanque(e, index)}
                                                                />
                                                            </Col>
                                                        </div>
                                                    </Fragment>
                                                ) : el.modPay === 4 ? (
                                                    <Fragment>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Echeance
                                                            </Label>
                                                            <Col sm={9}>
                                                                <DatePicker
                                                                    placeholderText="__/__/____"
                                                                    className="form-control ddate"
                                                                    //selected={dateEch}
                                                                    value={el.dateEch}
                                                                    //onChange={e => addDateEch(e, index)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Numéro cheque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Input
                                                                    value={el.numCheque}
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="..."
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Banque
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Select
                                                                    value={el.banque}
                                                                    isSearchable={true}

                                                                />
                                                            </Col>
                                                        </div>
                                                    </Fragment>
                                                ) : el.modPay === 5 ? (
                                                    <Fragment>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Taux
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Select
                                                                    value={el.taux}
                                                                    isSearchable={true}
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Montant taux
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="..."
                                                                    value={el.mntTaux}
                                                                />
                                                            </Col>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <Label
                                                                htmlFor="horizontal-firstname-Input"
                                                                className="col-sm-3 col-form-label"
                                                            >
                                                                Montant Net
                                                            </Label>
                                                            <Col sm={9}>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="..."
                                                                    value={el.montantNet}
                                                                />
                                                            </Col>
                                                        </div>
                                                    </Fragment>
                                                ) : null}
                                            </Form>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <i
                                                    style={{ color: "#cc0000", cursor: "pointer" }}
                                                    className="mdi mdi-delete font-size-18"
                                                    id="deletetooltip"
                                                    //    onClick={() => toggle(index)}
                                                />
                                            </div>{" "}
                                            <div style={{ borderBottom: "1px solid #E4E9E2" }}></div>{" "}
                                        </Col>
                                    ))}
                                </Row>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {" "}
                                    <Col lg="6">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                            className="text-center mt-4"
                                        >
                                            <Button
                                                type="button"
                                                color="warning"
                                                className="btn btn-warning  mb-2 me-2"
                                                onClick={() => props.history.push("/ReglementClient")}
                                            >
                                                Annuler
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                            className="text-center mt-4"
                                        >
                                            {/* <button
                                                disabled={!disbutReg}
                                                type="button"
                                                className="btn btn-primary "
                                                // onClick={save}
                                            >
                                                CONFIRMER
                                            </button>*/}
                                        </div>
                                    </Col>
                                </div>
                            </Fragment>
                        ) : null}
                    </Fragment>
                ) : null}
                <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalHeader toggle={toggle} tag="h4">
                        Suppression ligne{" "}
                    </ModalHeader>
                    <ModalBody>
                        <div
                            style={{
                                fontFamily: "Montserrat, sans-serif",
                                FontSize: "14px",
                                FontWeight: "700",
                                LineHeight: "18.375px",
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                            </div>
                            <div
                                className="hvr-push"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginBottom: "15px",
                                }}
                            >
                                <div>
                                    <Button
                                        onClick={toggleDelete}
                                        color="info"
                                        className="btn-rounded "
                                    >
                                        <i
                                            style={{ color: "white" }}
                                            className="far fa-thumbs-up"
                                        ></i>
                                        Oui
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={toggle}
                                        color="danger"
                                        className="btn-rounded "
                                    >
                                        <i
                                            style={{ color: "white" }}
                                            className="far fa-thumbs-down"
                                        ></i>
                                        Non
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <ToastContainer
                    transition={Slide}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
            </div>

        </React.Fragment>
    )
}

export default detailReglementClient
detailReglementClient.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
}