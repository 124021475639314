import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import API from "../../api"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const [listmenu, setListmenu] = useState([])
  const [userdata, setUserdata] = useState([])
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const [MenuOpen, setMenuOpen] = useState({})
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)

    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])


  useEffect(() => {
    ref.current.recalculate()
  })


  useEffect(() => {
    // Create an object with each menu index as key and set value to false
    const initialMenuOpenState = userdata.reduce((acc, curr, index) => {
      acc[index] = false;
      return acc;
    }, {});
    setMenuOpen(initialMenuOpenState);
  }, [userdata]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const toggleDashboard = () => {
    setDashboardOpen(!dashboardOpen); 
    console.log("menu",MenuOpen)
  };
  
  const toggleMenu = (index) => {
    setMenuOpen({
      ...MenuOpen,
      [index]: !MenuOpen[index]
    }); 
  };


  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
                  <li>
              <Link to="/dashboard" className="" onClick={toggleDashboard}>
              <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {dashboardOpen &&
            userdata.map((menuData, index) => (
              <li key={index}>
                <a className="has-arrow " onClick={() => toggleMenu(index)}>
                  <i className={menuData.icon} ></i>
                <span>{menuData.menu}</span>
                </a>
                <ul className="sub-menu" aria-expanded="false">
                  {MenuOpen[index] &&
                  menuData.sous_menus.map((subMenuData, subIndex) => (

                    <li key={subIndex} >
                     <Link to={subMenuData.url}> <span>{subMenuData.sous_menu}</span></Link>
                     
                      
                    </li>
                   
                  ))}
                </ul>
                  
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
                    )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
