/* eslint-disable react/no-unknown-property */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  CardTitle,
  Form,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import Select from "react-select"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Link } from "react-router-dom"


const DataTableFactureBlClient = props => {
  const [loading, setLoading] = useState(false)
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  //
  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")
  const [montant, setMontant] = useState("")
  const [numFacture, setNumFacture] = useState([])
  const [selectNumFacture, setSelectNumFacture] = useState("")
  const [client, setClient] = useState([])
  const [selectClient, setSelectClient] = useState("")
//////permission//////
const [userdata, setUserdata] = useState([])
const [perm, setPerm] = useState({
  add: 0,
  delete:0,
  edit:0,
  imp:0,
  view:0
});

useEffect(async () => {
  const userAuth = JSON.parse(localStorage.getItem('userAuth'));
  console.log(userAuth)
  setUserdata(userAuth.user.userData)
console.log(userAuth.user.userData)
}, [])


useEffect(() => {
console.log(userdata)
  const typeArticleSubMenu = userdata.find(user => 
    user.sous_menus.some(subMenu => subMenu.sous_menu === "Facture de Livraison")
  );

  if (typeArticleSubMenu) {
    console.log(typeArticleSubMenu)
    const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
      subMenu => subMenu.sous_menu === "Facture de Livraison"
    ).permissions[0];
    console.log(typeArticlePermission)
    setPerm(prevPerm => ({
      ...prevPerm,
      add: typeArticlePermission.add,
      delete:typeArticlePermission.delete,
      edit:typeArticlePermission.edit,
      imp:typeArticlePermission.imp,
      view:typeArticlePermission.view
    }));
  }
}, [userdata]);
console.log(perm)
///////////permission////

  const columns = [
    {
      name: "Numéro",
      selector: "numero",
      sortable: false,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: false,
      center: true,
    },
    {
      name: "Code client",
      selector: "codeClient",
      sortable: false,
      center: true,
    },
    {
      name: "Raison sociale",
      selector: "raisonSocial",
      sortable: false,
      center: true,
    },
    {
      name: "Montant HT",
      selector: "total_ht",
      sortable: false,
      center: true,
    },
    {
      name: "Montant TTC",
      selector: "total_ttc",
      sortable: false,
      center: true,
    },
    {
      name: "Consultation",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.view == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          style={{ color: "mediumslateblue", cursor: "pointer" }}
          className="fas fa-info-circle"
          onClick={() =>
            props.history.push("/DetailFactBlClient?id=" + row.id)
          }
        ></i>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-info-circle"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
    {
      name: "Modification",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.edit == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          style={{ color: "cornflowerblue", cursor: "pointer" }}
          className="fas fa-edit"
          onClick={() =>
            props.history.push("/EditFactBlClient?id=" + row.id)
          }
        ></i>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-edit"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
    {
      name: "Suppression",
      // eslint-disable-next-line react/display-name
      cell: row =>
        row.count == 0 && perm.delete == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggleDel(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Type d'article déjà utilisé"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },
    {
      name: "Impression",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.imp == 1 ? (
          <div style={{display: "flex", justifyContent: "center" }}>
          <a
          href={
            "https://backcommercial.cresus-institut.ovh/api/pdf/generate_pdf_liv_client?id=" +
            row.id 
           
          }
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            className="fas fa-file-download"
          ></i>
        </a>
        </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-file-download"
              title="Type d'article déjà utilisé"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
        paddingLeft: "2px",
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
    const resC = await API.get("facture/client/select_client").then(resC => {
      setClient(resC.data.Client)
    })
    const resF = await API.get("facture/client/select").then(resF => {
      setNumFacture(resF.data.FactureClient)
    })
    const res = await API.post("facture/client/list_liv", {
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
    setLoading(true)
  }, [])

  const filterSearch = async e => {
    setFilterElement(e.target.value)
    setMycurrentPage(1)
    const res = await API.post("facture/client/list_liv", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: 1,
    }).then(res => {
      console.log("search",res)
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
  }

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("facture/client/list_liv", {
      filterElement: filterElement,

      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("facture/client/list_liv", {
      filterElement: filterElement,

      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
  }

  const search = async () => {
    if (selectNumFacture != null) {
      var facture_id = selectNumFacture.value
    } else {
      var facture_id = 0
    }
    if (selectClient != null) {
      var client_id = selectClient.value
    } else {
      var client_id = 0
    }
    if (dateDebut != "") {
      let dateD = dateDebut
      let month = "" + (dateD.getMonth() + 1)
      let day = "" + dateD.getDate()
      let year = dateD.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateDebut = [year, month, day].join("-")
    } else {
      var convertDateDebut = 0
    }
    if (dateFin != "") {
      let dateF = dateFin
      let month = "" + (dateF.getMonth() + 1)
      let day = "" + dateF.getDate()
      let year = dateF.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateFin = [year, month, day].join("-")
    } else {
      var convertDateFin = 0
    }
    const res = await API.post("facture/client/search_liv", {
      facture_id,
      client_id,
      date_debut: convertDateDebut,
      date_fin: convertDateFin,
    }).then(res => {
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
  }

  const searchAll = async () => {
    setSelectNumFacture("")
    setSelectClient("")
    setDateDebut("")
    setDateFin("")
    //
    const res = await API.post("facture/client/list_liv", {
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.FactureClient[0].FactureClient)
      setNbrTotlEl(res.data.FactureClient[0].total)
    })
  }

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const toggleSupp = async () => {
    const res = await API.post("facture/client/delete_liv", {
      id: id,
    }).then(res => {
      setModal(!modal)
      const resD = API.post("facture/client/list_liv", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.FactureClient[0].FactureClient)
        setNbrTotlEl(resD.data.FactureClient[0].total)
      })
    })
  }

  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de recherche
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">N° Facture :</Label>
                <Select
                  options={numFacture}
                  isClearable={true}
                  isSearchable={true}
                  onChange={e => setSelectNumFacture(e)}
                />
              </div>
            </Col>
            {/* <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Montant TTC = :</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Montant TTC"
                  onChange={e => setMontant(e.target.value)}
                />
              </div>
            </Col> */}
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Client : </Label>
                <Select
                  options={client}
                  isSearchable={true}
                  isClearable={true}
                  onChange={e => setSelectClient(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Début</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateDebut}
                    onChange={e => setDateDebut(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Fin</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateFin}
                    onChange={e => setDateFin(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </Form>
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {" "}
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={search}
              >
                Rechercher
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={searchAll}
              >
                Afficher Tout
              </button>
            </div>
          </Col>
        </div>
        {loading ? (
          <Row>
            <div className="mb-2 row">
              <div className="col-md-4">
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        className="form-control "
                        placeholder="Search"
                        onChange={(currentRowsPerPage, currentPage) =>
                          filterSearch(currentRowsPerPage, currentPage)
                        }
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </div>
            </div>
            <Col xs="12">
              <DataTable
                columns={columns}
                data={data}
                noDataComponent="Pas de Données Disponibles"
                customStyles={customStyles}
                highlightOnHover={false}
                pagination={true}
                paginationServer={true}
                onChangePage={(page, totalRows) => newElement(page, totalRows)}
                paginationTotalRows={nbrTotlEl}
                paginationPerPage={nbrEltperPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                  perPage(currentRowsPerPage, currentPage)
                }
                paginationResetDefaultPage={resetFirstPage}
                paginationComponentOptions={{
                  rowsPerPageText: "Elements par page:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                }}
              />
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <Delete> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression facture BL")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cette facture ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DataTableFactureBlClient))
DataTableFactureBlClient.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
