import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';

const StatistiqueBonEntreeFrs = props =>{
    const [etatFacturationValues, setEtatFacturationValues] = useState({
        totalBonAvoirPercentage: 0,
        totalFact: 0,
        total_facture_direct: 0,
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('fournisseur/entree/get_stat');
                const data = response.data.bonEntreeFrs[0];
                setEtatFacturationValues({
                    total_Bon_entree_percentage: parseFloat(data.total_Bon_entree_percentage),
                    totalFact: parseFloat(data.totalFact),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const getOptionFacturation = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} ({d}%)",
            },
            legend: {
                orient: "vertical",
                x: "left",
                data: ["Bon Entree", "Total des factures"], // Updated legend names
                textStyle: {
                    color: ["#74788d"],
                },
            },
            color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
            series: [
                {
                    name: "Total sales",
                    type: "pie",
                    radius: ["50%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: "30",
                                fontWeight: "bold",
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                    data: [
                        { value: etatFacturationValues.total_Bon_entree_percentage, name: 'Bon Entree' },
                        { value: etatFacturationValues.totalFact - etatFacturationValues.total_Bon_entree_percentage, name: 'Total des factures' },
                    ],
                },
            ],
        };
    };

    return (
        <React.Fragment>
            <p>Bon Entree</p>
            <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
        </React.Fragment>
    );
};

export default  StatistiqueBonEntreeFrs;
