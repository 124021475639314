import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import {
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Card,
  CardBody,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const DataTableClient = props => {
  const [loading, setLoading] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [id, setId] = useState("")
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [data, setData] = useState([])
//////permission//////
const [userdata, setUserdata] = useState([])
const [perm, setPerm] = useState({
  add: 0,
  delete:0,
  edit:0,
  imp:0,
  view:0
});

useEffect(async () => {
  const userAuth = JSON.parse(localStorage.getItem('userAuth'));
  console.log(userAuth)
  setUserdata(userAuth.user.userData)
console.log(userAuth.user.userData)
}, [])


useEffect(() => {
console.log(userdata)
  const typeArticleSubMenu = userdata.find(user => 
    user.sous_menus.some(subMenu => subMenu.sous_menu === "Client")
  );

  if (typeArticleSubMenu) {
    console.log(typeArticleSubMenu)
    const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
      subMenu => subMenu.sous_menu === "Client"
    ).permissions[0];
    console.log(typeArticlePermission)
    setPerm(prevPerm => ({
      ...prevPerm,
      add: typeArticlePermission.add,
      delete:typeArticlePermission.delete,
      edit:typeArticlePermission.edit,
      imp:typeArticlePermission.imp,
      view:typeArticlePermission.view
    }));
  }
}, [userdata]);
console.log(perm)
///////////permission////


  const columns = [
    {
      name: "Code",
      selector: "code",
      sortable: false,
      center: true,
    },
    {
      name: "Comptable",
      selector: "codeComptable",
      sortable: false,
      center: true,
    },
    {
      name: "Raison social",
      selector: "raisonSocial",
      sortable: false,
      center: true,
    },
    {
      name: "Téléphone",
      selector: "telephone",
      sortable: false,
      center: true,
    },
    {
      name: "Email",
      // eslint-disable-next-line react/display-name
      cell: row => <div style={{ whiteSpace: "nowrap" }}>{row.email}</div>,
      center: true,
      reorder: true,
    },
    {
      name: "Consultation",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.view == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <i
            style={{ color: "mediumslateblue", cursor: "pointer" }}
            className="fas fa-info-circle"
            onClick={() =>
              props.history.push("/DetailClient?id=" + row.id)
            }
          ></i>
        </div>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-info-circle"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
    {
      name: "Modification",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.edit == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <Link to={"/EditClient?id=" + row.id}>
          <i
            style={{ color: "cornflowerblue", cursor: "pointer" }}
            className="fas fa-edit"
          ></i>
        </Link>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-edit"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    }, 
    {
      name: "Suppression",
      // eslint-disable-next-line react/display-name
      cell: row =>
        row.count == 0 && perm.delete == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggleDel(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Type d'article déjà utilisé"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
    const res = await API.post("client/list", {
      filterElement: filterElement,
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.Data[0].Client)
      setNbrTotlEl(res.data.Data[0].total)
    })
    setLoading(true)
  }, [])

  const filterSearch = async e => {
    setFilterElement(e.target.value)
    const res = await API.post("client/list", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: mycurrentPage,
    }).then(res => {
      setData(res.data.Data[0].Client)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("client/list", {
      filterElement: filterElement,
      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data.Data[0].Client)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("client/list", {
      filterElement: filterElement,
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.Data[0].Client)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const toggleDel = id => {
    setModalDel(!modalDel)
    setId(id)
  }

  const toggleSupp = async () => {
    const res = await API.post("client/delete", {
      id: id,
    }).then(res => {
      setModalDel(!modalDel)
      const resD = API.post("client/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Data[0].Client)
        setNbrTotlEl(resD.data.Data[0].total)
      })
    })
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {loading ? (
            <Col xs="12">
              <div className="mb-2 row">
                <div className="col-md-4">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={(currentRowsPerPage, currentPage) =>
                            filterSearch(currentRowsPerPage, currentPage)
                          }
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover={false}
                pagination={true}
                paginationServer={true}
                onChangePage={(page, totalRows) => newElement(page, totalRows)}
                paginationTotalRows={nbrTotlEl}
                paginationPerPage={nbrEltperPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                  perPage(currentRowsPerPage, currentPage)
                }
                paginationResetDefaultPage={resetFirstPage}
                paginationComponentOptions={{
                  rowsPerPageText: "Elements par page:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                }}
              />
            </Col>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                Chargement en cours ...{" "}
              </h4>
            </div>
          )}
        </CardBody>
      </Card>
      {/* <Delete> */}
      <Modal isOpen={modalDel} toggle={toggleDel} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleDel}
        >
          {props.t("Suppression d'un client")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>{props.t("Êtes-Vous sûr de vouloir supprimer ce client ?")}</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDel}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableClient))
DataTableClient.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
