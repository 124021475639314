import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';

const StatLiv = () => {
    const [etatPercentages, setEtatPercentages] = useState([]);
    const [etatFacturationPercentages, setEtatFacturationPercentages] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('bon/livraison/client/get_stat');
                const data = response.data.LivraisonClient;
                const avoirPercentage = parseFloat(data.etat_avoir_percentages[0]["avoir"]);
                const nonAvoirPercentage = parseFloat(data.etat_avoir_percentages["non avoir"]);
                const nonAvoirPercentageTotal = 100 - avoirPercentage;
                const [totalCommandes, facture, nonFacture] = data.etat_percentages[0];
                setEtatPercentages([
                    { value: avoirPercentage, name: 'avoir' },
                    { value: nonAvoirPercentageTotal, name: 'non avoir' },
                ]);
                setEtatFacturationPercentages([
                    { value: totalCommandes, name: 'totalCommandes' },
                    { value: facture, name: 'facturé' },
                    { value: nonFacture, name: 'non facturé' },
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const getOptionEtat = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: ['avoir', 'non avoir'],
                textStyle: {
                    color: ['#74788d'],
                },
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'État Avoir',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: etatPercentages,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };

    const getOptionFacturation = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                x: 'left',
                data: ['totalCommandes', 'facturé', 'non facturé'],
                textStyle: {
                    color: ['#74788d'],
                },
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'État Facturation',
                    type: 'pie',
                    radius: ['50%', '60%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                    data: etatFacturationPercentages,
                },
            ],
        };
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '20px' }}>
                <p>État Avoir</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionEtat()} />
            </div>
            <div style={{ flex: 1 }}>
                <p>État Facturation</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
            </div>
        </div>
    );
};

export default StatLiv;
