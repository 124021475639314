import React, { useEffect, useState,useRef  } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button, Label, Input } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
//PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import LogoC from "../../assets/images/LOGOerp.png"
//Excel 
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from "react-toastify"
import { Slide } from "react-toastify"



const AddInventaire = props => {
  const [loading, setLoading] = useState(false)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [depot_id, setDepot_id] = useState(0)
  const [code, setCode] = useState("")
  const [designation, setDesignation] = useState("")
  const [quantite, setQuantite] = useState("")
  const [quantite_max, setQuantiteMaximal] = useState("")
  const [quantite_min, setQuantiteMinimal] = useState("")
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  const [suivi, setSuivi] = useState([])
  const [userdata, setUserdata] = useState([])

  //pdf 
  const contentRef = useRef(null);

  //date
  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")
  // Produits
  const [produit_id, setProduit_id] = useState(0)
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState()

  //les quantites 
  const [quantite_actuelle, setQuantite_actuelle] = useState("")
  const [quantite_DateF, setQuantite_DateF] = useState("")
  const [quantite_DateDeb, setQuantite_DateDeb] = useState("")
  //table 
  const [arrayProduit, setArrayProduit] = useState([])



  //verification
  const [depotError, setDepotError] = useState("");
  const [dateDebutError, setDateDebutError] = useState("");
  const [dateFinError, setDateFinError] = useState("");
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });
  const maxHeight = 150;

  /*const handleDownload = async () => {
    try {
      const response = await API.post("stock/generate_excel2", {
        depot_id: depot_id,
        article_id: selectProduit.value 
      }, );

     
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.xlsx');
  
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
    }
  };*/
  
 const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(arrayProduit.map(el => ({
      'Dépôt': selectDepot.label,
      'Article': selectProduit.label,
      'Quantité initiale': quantite_DateDeb,
      'Quantité actuelle de Dépôt': quantite_actuelle,
      'Quantité prévue': quantite_DateF,
   

      'Action': el.type_action,
      'Fournisseur/Client': el.fournisseur_client,
      'Nom': el.nom_fournisseur_client,
      'Quantité action': el.quantite,
      'Date action': el.date,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Suivi');
    XLSX.writeFile(workbook, "Suivi.xlsx");
  };

  //verificationnnnnnnn


  useEffect(() => {
    // Validation pour le dépôt
    if (!selectDepot) {
      setDepotError("Veuillez sélectionner un dépôt.");
    } else {
      setDepotError("");
    }
  }, [selectDepot]);

  useEffect(() => {
    // Validation pour la date de début
    if (!dateDebut) {
      setDateDebutError("Veuillez sélectionner une date de début.");
    } else {
      setDateDebutError("");
    }
  }, [dateDebut]);

  useEffect(() => {
    // Validation pour la date de fin
    if (!dateFin) {
      setDateFinError("Veuillez sélectionner une date de fin.");
    } else {
      setDateFinError("");
    }
  }, [dateFin]);

  const handleDepotChange = (selectedOption) => {
    setDepot_id(selectedOption.value);
    setProduits([]);
    
    //setSelectProduit([]);
    setSelectDepot(selectedOption);
    //setSelectProduit(false);

  };
  const handleArticleChange = (selectedOption) => {
    setProduit_id(selectedOption.value)
    setSelectProduit(selectedOption)
    // setSelectDepot(true);
    // setSelectProduit(true);

  };



  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Inventaire")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Inventaire"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(async () => {
    const resDE = await API.get("depot/select").then(resDE => {
      setDepot(resDE.data.Depot)
  

    })


    setLoading(true);
  }, []);

  useEffect(async () => {
    const resP = await API.post("stock/get_articles_by_depot", {
      depot_id: depot_id
    }).then(resP => {
      setProduits(resP.data);
      if(resP.data.length ===0){
        setSelectProduit()
        setArrayProduit([]);
        setQuantite_DateDeb("")
        setQuantite_actuelle("")
        setQuantite_DateF("")
      }
      else{setSelectProduit(resP.data[0]);}
    });
  
    setLoading(true);
  }, [depot_id]);
  
  const confirmer = async () => {

    if (dateDebut!="" && dateFin != "" && selectDepot ){
      if(dateFin > dateDebut){
  
    if (selectProduit) { 
      const resArt = await API.post("stock/suivie", {
        depot_id: depot_id,
        article_id: selectProduit.value,
        dateDeb : dateDebut,
        dateFin:dateFin
      
      });

              setQuantite_DateDeb(resArt.data.quantite_date_debut);
                setQuantite_actuelle(resArt.data.quantite_actuelle);
                setQuantite_DateF(resArt.data.quantite_date_fin);
                setArrayProduit(resArt.data.log);
       }
      }else
      {
        toast.error("⛔ La date de fin doit être plus récente que la date de debut", {
           containerId: "A",
        })
      }
      } else  {
        toast.error("⛔ Remplir les champs obligatoire", {
           containerId: "A",
        })
      }
      
    
      
      
  };
  

  



  /*useEffect(async () => {
    //get suivi 
    const resArt = await API.post("stock/suivie", {
      depot_id: depot_id,
      article_id: produit_id

    })
      .then(resArt => {
        setQuantite_initiale(resArt.data.quantite_de_depart)
        setQuantite_actuelle(resArt.data.quantite_actuelle)
        setQuantite_preveu(resArt.data.quantite_attendue)
        setArrayProduit(resArt.data.log)
        console.log('suivvvvv', resArt.data.log)
        console.log("depotId", depot_id)
        console.log("AArt", produit_id)
        console.log("Suiviiiiii", resArt)
      })
  }, [produit_id])*/


  






  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }


  const generatePDF = () => {
    const pdf = new jsPDF();
    pdf.setDrawColor(0); 
    pdf.setLineWidth(0.5);  
    pdf.setFillColor(0); 
    pdf.rect(10, 10, 190, 30, 'F');
    const logo = LogoC;
    pdf.addImage(logo, "PNG", 15, 15, 25, 25); 
    const nomSociete = "CresusERP";
    pdf.setFontSize(16);
    pdf.text(nomSociete, 50, 25); 
    const offsetY = 50; 
  
    const fields = [
      `Dépôt : ${selectDepot.label}`,
      `Article : ${selectProduit.label}`,
      `Quantité date debut : ${quantite_DateDeb}`,
      `Quantité actuelle de Dépôt : ${quantite_actuelle}`,
    ];
  
    fields.forEach((field, index) => {
      pdf.text(10, offsetY + (index * 10), field);
    });
  
    const tableData = arrayProduit.map((el) => [
      el.type_action,
      el.fournisseur_client,
      el.nom_fournisseur_client,
      el.quantite,
      el.date,
    ]);
  
    pdf.autoTable({
      startY: offsetY + ((fields.length + 1) * 10),
      head: [['Action', 'Fournisseur/Client', 'Nom', 'Quantité action', 'Date action']],
      body: tableData,
    });
  
    pdf.save("Suivi.pdf");
  };
  
  

  


 

  const { SearchBar } = Search

  return (
    <React.Fragment>

      <div ref={contentRef}>

        {loading ? (

          <Row>

<Col md={6} sm={6}>
    <div className="mb-3">
      <Label for="basicpill-lastname-input2">Date Début</Label>
      <Col md={12} className="pr-0">
        <DatePicker
          className="form-control ddate"
          selected={dateDebut}
          onChange={e => setDateDebut(e)}
          dateFormat="dd/MM/yyyy"
          required 
        />
      </Col>
    </div>
  </Col>
  
  <Col md={6} sm={6}>
    <div className="mb-3">
      <Label for="basicpill-lastname-input2">Date Fin</Label>
      <Col md={12} className="pr-0">
        <DatePicker
          className="form-control ddate"
          selected={dateFin}
          onChange={e => setDateFin(e)}
          dateFormat="dd/MM/yyyy"
          required 
        />
      </Col>
    </div>
  </Col>



            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Depot
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={depot}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleDepotChange(selectedOption);


                  }}
                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}

                />

              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Article
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                  required
                  options={produits}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleArticleChange(selectedOption);
                  }}
                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}
                  value={selectProduit}
                />

              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Quantite actuelle de Dépôt</Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  value={quantite_actuelle}
                  readOnly={true}
                />
              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Quantite date debut </Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  value={quantite_DateDeb}
                  readOnly={true}
                />
              </div>
            </Col>
            <col></col>
          
            <Col md={4}  >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                style ={{  
                  marginLeft: "0",
                  //marginRight: "0",
                  }}
                  type="submit"
                  className="btn btn-primary "
                  onClick={confirmer}
                 
                >
                  Confirmer 
                </button>
              </div>
            </Col>

            {/*<Col sm="12">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    className="form-control "
                    placeholder="Search"
                    onChange={(currentRowsPerPage, currentPage) =>
                      filterSearch(currentRowsPerPage, currentPage)
                    }
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
                  </Col>*/}
            <Col xs="12">
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      Action
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Fournisseur/Client
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Nom
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité action
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      date action
                    </th>



                  </tr>
                </thead>
                {arrayProduit.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.type_action}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.fournisseur_client}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.nom_fournisseur_client}
                      </td>
                      <td style={{ textAlign: "center", width: "8%" }}>
                        {el.quantite}
                      </td>
                      <td style={{ textAlign: "center", width: "7%" }}>
                        {el.date}
                      </td>

                    </tr>
                  </tbody>
                ))}
                <tbody  style={{ border: "none " }}>
                  <tr style={{ border: "none" }}>
                    <th style={{ textAlign: "center", backgroundColor: "white", border: "none " }} scope="col"></th>
                    <td style={{ textAlign: "center", backgroundColor: "white" , border: "none "}}></td>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantite date fin :
                    </th>
                    <td style={{ textAlign: "center", width: "20%" }}>
                      {quantite_DateF}
                    </td>
                  </tr>
                </tbody>

              </table>
            </Col>
            <Col md={4}  > </Col>

           {/* <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Quantite prévue</Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  value={quantite_preveu}
                  readOnly={true}
                />
              </div>
                </Col>*/}

            <Col md={4}  > </Col>
           


            <Row className="justify-content-center">
                <Col md={3} className="text-center">
                  <button  className="btn btn-primary " onClick={generatePDF} >
                  <FontAwesomeIcon icon={faFilePdf} size="2x" />
                  Télécharger PDF
                </button>
              </Col>

              <Col md={3} className="text-center">
                <button  className="btn btn-primary " onClick={generateExcel}>
                  <FontAwesomeIcon icon={faFileExcel} size="2x" />
                  Export Excel
                </button>
              </Col>
            </Row>
            <Col md={4}  >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >

              </div>
            </Col>



          </Row>


        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />


    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddInventaire))
AddInventaire.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
