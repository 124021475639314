/* eslint-disable react/no-unknown-property */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  CardTitle,
  Form,
  Label,
  Input,
  CardBody,
  Card,
} from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DataTableFournisseur = props => {
  const [loading, setLoading] = useState(false)
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  //
  const [etat, setEtat] = useState([
    { value: 0, label: "Actif" },
    { value: 1, label: "Inactif" },
  ])
  const [selectEtat, setSelectEtat] = useState("")
  const [codeFournisseur, setCodeFournisseur] = useState([])
  const [selectCodeFournisseur, setSelectCodeFournisseur] = useState("")
  const [raisonSocial, setRaisonSocial] = useState([])
  const [selectRaisonSocial, setSelectRaisonSocial] = useState("")

  const [userdata, setUserdata] = useState([])
  const [perm, setPerm] = useState({
    add: 0,
    delete:0,
    edit:0,
    imp:0,
    view:0
  });
  
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    console.log(userAuth)
    setUserdata(userAuth.user.userData)
  console.log(userAuth.user.userData)
  }, [])
  
  
  useEffect(() => {
  console.log(userdata)
    const typeArticleSubMenu = userdata.find(user => 
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Fournisseur")
    );
  
    if (typeArticleSubMenu) {
      console.log(typeArticleSubMenu)
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Fournisseur"
      ).permissions[0];
      console.log(typeArticlePermission)
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete:typeArticlePermission.delete,
        edit:typeArticlePermission.edit,
        imp:typeArticlePermission.imp,
        view:typeArticlePermission.view,
  
      }));
    }
  }, [userdata]);
  console.log(perm)
  ///////////permission////
  const columns = [
    {
      name: "Code",
      selector: "code",
      sortable: false,
      center: true,
    },
    {
      name: "Raison sociale",
      selector: "raisonSocial",
      sortable: false,
      center: true,
    },
    {
      name: "Code TVA",
      selector: "codeTVA",
      sortable: false,
      center: true,
    },
    {
      name: "Raison sociale",
      selector: "raisonSocial",
      sortable: false,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
      center: true,
    },
    {
      name: "Téléphone",
      selector: "tel",
      sortable: false,
      center: true,
    },
    {
      name: "Consultation",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.view == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          style={{ color: "mediumslateblue", cursor: "pointer" }}
          className="fas fa-info-circle"
          onClick={() =>
            props.history.push("/DetailFournisseur?id=" + row.id)
          }
        ></i>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-info-circle"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
    {
      name: "Modification",
      // eslint-disable-next-line react/display-name
      cell: row =>
      perm.edit == 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <i
          style={{ color: "cornflowerblue", cursor: "pointer" }}
          className="fas fa-edit"
          onClick={() =>
            props.history.push("/ModifFournisseur?id=" + row.id)
          }
        ></i>
      </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
            className="fas fa-edit"
            title="pas de perm"
          ></i>
        </div>
      ),
      center: true,
      reorder: true,
    },
   
    {
      name: "Suppression",
      // eslint-disable-next-line react/display-name
      cell: row =>
        row.count == 0 && perm.delete == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggle(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "rgb(167, 163, 163)", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Type d'article déjà utilisé"
            ></i>
          </div>
        ),
      center: true,
      reorder: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
        paddingLeft: "2px",
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
    const resF = await API.get("fournisseur/select_code").then(resF => {
      setCodeFournisseur(resF.data.Fournisseur)
    })
    const resC = await API.get("fournisseur/select_raisonSocial").then(resF => {
      setRaisonSocial(resF.data.Fournisseur)
    })

    const res = await API.post("fournisseur/list", {
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.Fournisseur[0].Fournisseur)
      setNbrTotlEl(res.data.Fournisseur[0].total)
    })
    setLoading(true)
  }, [])

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("fournisseur/list", {
      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data.Fournisseur[0].Fournisseur)
      setNbrTotlEl(res.data.Fournisseur[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("fournisseur/list", {
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.Fournisseur[0].Fournisseur)
      setNbrTotlEl(res.data.Fournisseur[0].total)
    })
  }

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const toggleSupp = async () => {
    const res = await API.post("fournisseur/delete", {
      id: id,
    }).then(res => {
      setModal(!modal)
      const resD = API.post("fournisseur/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Fournisseur[0].Fournisseur)
        setNbrTotlEl(resD.data.Fournisseur[0].total)
      })
    })
  }

  const search = async () => {
    if (selectCodeFournisseur != null) {
      var codeFournissuer = selectCodeFournisseur.value
    } else {
      var codeFournissuer = 0
    }
    if (selectRaisonSocial != null) {
      var raisonSocial = selectRaisonSocial.value
    } else {
      var raisonSocial = 0
    }
    if (selectEtat != null) {
      var etat = selectEtat.value
    } else {
      var etat = 0
    }
    const res = await API.post("fournisseur/search", {
      code: codeFournissuer,
      raisonSocial: raisonSocial,
      etat: etat,
    }).then(res => {
      setData(res.data.Fournisseur[0].Fournisseur)
      setNbrTotlEl(res.data.Fournisseur[0].total)
    })
  }

  const searchAll = async () => {
    const res = await API.post("fournisseur/list", {
      nbrElParPage: 5,
      page: 1,
    }).then(res => {
      setData(res.data.Fournisseur[0].Fournisseur)
      setNbrTotlEl(res.data.Fournisseur[0].total)
    })
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody>
            <CardTitle style={{ color: "#556ee6" }} className="h4">
              Critéres de recherches
            </CardTitle>
            <Form className="mt-4">
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">
                      Code Fournisseur :
                    </Label>
                    <Select
                      options={codeFournisseur}
                      isClearable={true}
                      isSearchable={true}
                      onChange={e => setSelectCodeFournisseur(e)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">
                      Raison sociale :
                    </Label>
                    <Select
                      options={raisonSocial}
                      isClearable={true}
                      isSearchable={true}
                      onChange={e => setSelectRaisonSocial(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Etat :</Label>
                    <Select
                      options={etat}
                      isClearable={true}
                      isSearchable={true}
                      onChange={e => setSelectEtat(e)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div
              className="mb-4"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {" "}
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    style={{
                      backgroundColor: "#761C19",
                      borderColor: "#761C19",
                    }}
                    className="btn btn-success mb-2 me-2"
                    onClick={search}
                  >
                    Rechercher
                  </button>
                </div>
              </Col>
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    style={{
                      backgroundColor: "#761C19",
                      borderColor: "#761C19",
                    }}
                    className="btn btn-success mb-2 me-2"
                    onClick={searchAll}
                  >
                    Afficher Tout
                  </button>
                </div>
              </Col>
            </div>
            {loading ? (
              <Row>
                <Col sm="4">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
                <Col xs="12">
                  <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    highlightOnHover={false}
                    pagination={true}
                    paginationServer={true}
                    onChangePage={(page, totalRows) =>
                      newElement(page, totalRows)
                    }
                    paginationTotalRows={nbrTotlEl}
                    paginationPerPage={nbrEltperPage}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                      perPage(currentRowsPerPage, currentPage)
                    }
                    paginationResetDefaultPage={resetFirstPage}
                    paginationComponentOptions={{
                      rowsPerPageText: "Elements par page:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: false,
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("Chargement en cours...")}
                </h4>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      {/* <Delete> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>
                {props.t(
                  "Êtes-Vous sûr de vouloir supprimer cette fournisseur ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DataTableFournisseur))
DataTableFournisseur.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
