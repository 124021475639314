/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Label, Row } from "reactstrap"
import API from "../../../api"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddFamArt = props => {
  const [code, setCode] = useState("")
  const [etatCode, setEtatCode] = useState(false)
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [name, setName] = useState("")

  useEffect(async () => {
    const res = await API.get("groupe/article/select").then(res => {
      setGroupe(res.data.GroupeArticle)
    })
  }, [])

  const save = async () => {
    if (name != "" && code != "") {
      if (selectGroupe != "") {
        const res = await API.post("famille/groupe/article/add", {
          groupe_article_id: selectGroupe.value,
          code: code,
          name: name,
        }).then(res => {
          if (res.data.status == 200) {
            setEtatCode(false)
            props.setSection(1)
          } else {
            setEtatCode(true)
          }
        })
      } else {
        toast.error("Veuillez selectionner un groupe", {
          containerId: "A",
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row>
          {etatCode ? (
            <div className="alert alert-danger">
              <strong>Code deja utilisé!</strong>
            </div>
          ) : null}
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Code")}
                  </Label>
                  <AvField
                    name="code"
                    placeholder=" Entrer code"
                    type="text"
                    errorMessage="* Code obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setCode(e.target.value)}
                    maxlength="5"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Groupe")}
                  </Label>
                  <Select
                    name="Groupe"
                    options={groupe}
                    isSearchable={true}
                    onChange={setSelectGroupe}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Famille")}
                  </Label>
                  <AvField
                    name="famille"
                    placeholder="Entrer famille"
                    type="text"
                    errorMessage="* Famille obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    onClick={save}
                    type="submit"
                    className="btn btn-primary "
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withTranslation()(AddFamArt)
AddFamArt.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
