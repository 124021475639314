import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Card,
  CardBody,
  Table,
  Container,
  Spinner,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const DetailCommandeFournisseur = props => {
  const [numCommande, setNumCommande] = useState("")
  const [fournisseur, setFournisseur] = useState([])
  const [selectFournisseur, setSelectFournisseur] = useState("")
  const [adresse, setAdresse] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [modeReglement, setModeReglement] = useState([])
  const [selectModeReglement, setSelectModeReglement] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [etatFodec, setEtatFodec] = useState("")
  const [date, setDate] = useState("")
  const [dateLiv, setDateLiv] = useState("")
  const [observation, setObservation] = useState("")
  const [arrayProduit, setArrayProduit] = useState([])
  const [produits, setProduits] = useState([])
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("commande/get_by_id", { id }).then(res => {
      setNumCommande(res.data.CommandeFournisseur.num_cmd)
      setSelectFournisseur(res.data.CommandeFournisseur.fournisseur_id)
      setAdresse(res.data.CommandeFournisseur.adresseFournisseur)
      setCodeTva(res.data.CommandeFournisseur.codeTVA)
      setEtatFodec(res.data.CommandeFournisseur.fodec)
      setObservation(res.data.CommandeFournisseur.observation)
      setSelectModeReglement(res.data.CommandeFournisseur.mode_reglement_id)
      setTotalHT(res.data.CommandeFournisseur.total_ht)
      setTotalTTC(res.data.CommandeFournisseur.total_ttc)
      setTotalTva(res.data.CommandeFournisseur.total_tva)
      setTotalRemise(res.data.CommandeFournisseur.total_remise)
      setTotalFodec(res.data.CommandeFournisseur.total_fodec)
      setArrayProduit(res.data.CommandeFournisseur.ligne)
      setDate(new Date(res.data.CommandeFournisseur.date))
      setDateLiv(new Date(res.data.CommandeFournisseur.date_liv))
      setRaisonSocial(res.data.CommandeFournisseur.raisonSocial)
      
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            N° commande
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            disabled
                            value={numCommande}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Fournisseur
                          </Label>
                          <Select
                            options={fournisseur}
                            isSearchable={true}
                            value={selectFournisseur}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">Adresse</Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Adresse"
                            value={adresse}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Raison sociale
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Raison sociale"
                            value={raisonSocial}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Code TVA
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Code tva"
                            value={codeTva}
                          />
                        </div>
                      </Col>
                      <Col className="mt-3" md={3}>
                        <div className="mb-3 mt-3">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="horizontal-customCheck"
                              checked={etatFodec == 1}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="horizontal-customCheck"
                            >
                              Fodec
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Mode de règlement
                          </Label>
                          <Select
                            options={modeReglement}
                            isSearchable={true}
                            value={selectModeReglement}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Date de la commande
                          </Label>
                          <Col md={12} className="pr-0">
                            <DatePicker
                              className="form-control ddate"
                              selected={date}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Date prévue de livraison
                          </Label>
                          <Col md={12} className="pr-0">
                            <DatePicker
                              className="form-control ddate"
                              selected={dateLiv}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Mémo bon de commande
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            placeholder="Mémo"
                            value={observation}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <CardTitle className="h4 mt-4">Ajouter Produit</CardTitle>
                  <div>
                    <table className="table table-bordered mt-4">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            Article
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Désignation
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Qte
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            P.U.H.T
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Remise
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Fodec
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant HT
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            TVA
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant TTC
                          </th>
                        </tr>
                      </thead>
                      {arrayProduit.map((el, index) => (
                        <tbody key={index}>
                          <tr>
                            <td style={{ textAlign: "center", width: "13%" }}>
                              <Select
                                options={produits}
                                isSearchable={true}
                                value={el.produit_id}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "13%" }}>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="..."
                                value={el.designation}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "8%" }}>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="..."
                                value={el.qte}
                                min={0}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "8%" }}>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="..."
                                value={el.prix_ht}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "8%" }}>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="..."
                                value={el.remise}
                                min={0}
                                max={100}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "8%" }}>
                              <Input
                                type="checkbox"
                                className="form-check-Input"
                                id="horizontal-customCheck"
                                checked={el.fodec == 1}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "13%" }}>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="..."
                                value={el.montant_ht}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "8%" }}>
                              <Input value={el.tva.label} disabled />
                            </td>
                            <td style={{ textAlign: "center", width: "13%" }}>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="..."
                                value={el.montant_ttc}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">Total </CardTitle>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <td>Total des remises :</td>
                              <td>{totalRemise}</td>
                            </tr>
                            <tr>
                              <td>Total HT :</td>
                              <td>{totalHT}</td>
                            </tr>
                            <tr>
                              <td>Total fodec :</td>
                              <td>{totalFodec}</td>
                            </tr>
                            <tr>
                              <td>Total TVA :</td>
                              <td>{totalTva}</td>
                            </tr>
                            <tr>
                              <td>Avance/Impot :</td>
                              <td>{""}</td>
                            </tr>
                            <tr>
                              <td>Total TTC : </td>
                              <td>{totalTTC}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                            props.history.push("/CommandeFournisseur")
                          }
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default DetailCommandeFournisseur
DetailCommandeFournisseur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
