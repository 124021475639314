import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button, Label, Input } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


const AddInventaire = props => {
  const [loading, setLoading] = useState(false)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [depot_id, setDepot_id] = useState(0)
  const [code, setCode] = useState("")
  const [designation, setDesignation] = useState("")
  const [quantite, setQuantite] = useState("")
  const [quantite_max, setQuantiteMaximal] = useState("")
  const [quantite_min, setQuantiteMinimal] = useState("")
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  const [userdata, setUserdata] = useState([])
  const [arrayProduit, setArrayProduit] = useState([])
  //date
const [dateDebut, setDateDebut] = useState("")
const [dateFin, setDateFin] = useState("")


//verification
const [depotError, setDepotError] = useState("");
const [dateDebutError, setDateDebutError] = useState("");
const [dateFinError, setDateFinError] = useState("");
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });
  const maxHeight = 150;

  //verificationnnnnnnn


  useEffect(() => {
    // Validation pour le dépôt
    if (!selectDepot) {
      setDepotError("Veuillez sélectionner un dépôt.");
    } else {
      setDepotError("");
    }
  }, [selectDepot]);

  useEffect(() => {
    // Validation pour la date de début
    if (!dateDebut) {
      setDateDebutError("Veuillez sélectionner une date de début.");
    } else {
      setDateDebutError("");
    }
  }, [dateDebut]);

  useEffect(() => {
    // Validation pour la date de fin
    if (!dateFin) {
      setDateFinError("Veuillez sélectionner une date de fin.");
    } else {
      setDateFinError("");
    }
  }, [dateFin]);

  const handleDepotChange = (selectedOption) => {
    setSelectDepot(true);
    // Autres actions à effectuer lors du changement de dépôt
  };

  const handleDateDebutChange = (e) => {
    setDateDebut(e.target.value);
    // Autres actions à effectuer lors du changement de la date de début
  };

  const handleDateFinChange = (e) => {
    setDateFin(e.target.value);
    // Autres actions à effectuer lors du changement de la date de fin
  };
  const filterSearch = async e => {
    setFilterElement(e.target.value)
    const res = await API.post("stock/consulter_par_depot", {
      filterElement: e.target.value,
      nbrElParPage: nbrEltperPage,
      page: mycurrentPage,
    }).then(res => {
      setData(res.data.Data[0].DevisClient)
      setNbrTotlEl(res.data.Data[0].total)
    })
  }

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    setUserdata(userAuth.user.userData)
  }, [])

  useEffect(() => {
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Inventaire")
    );

    if (typeArticleSubMenu) {
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Inventaire"
      ).permissions[0];
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,
      }));
    }
  }, [userdata]);

  useEffect(async () => {
    const resDE = await API.get("stock/all_articles_par_depot");
    const depots = resDE.data.depots.map(depot => ({
      value: depot.id,
      label: depot.designation,
      articles: depot.articles.map(article => ({ ...article, nouvelle_quantite: 0, remarque: (article.quantite - article.quantite_min)<10 ? "Attention" : "Tout est bien" }))
    }));
   
    setDepot(depots);
    setLoading(true);
  }, []);

  

  

  const handleNewQuantityChange = (depotId, articleIndex, value) => {
    setDepot(prevDepot => {
      const updatedDepot = prevDepot.map(dep => {
        if (dep.value === depotId) {
          const updatedArticles = dep.articles.map((article, index) => {
            if (index === articleIndex) {
              const nouvelle_quantite = value.trim() === "" ? 0 : parseFloat(value);
              let nouvelleRemarque;
              if (nouvelle_quantite === 0) {
                nouvelleRemarque = article.quantite - article.quantite_min < 10 ? "Attention" : "Tout est bien";
              } else {
                nouvelleRemarque = nouvelle_quantite+article.quantite - article.quantite_min < 10 ? "Attention" : "Tout est bien";
              }
              return { ...article, nouvelle_quantite: nouvelle_quantite, remarque: nouvelleRemarque };
            }
            return article;
          });
          return { ...dep, articles: updatedArticles };
        }
        return dep;
      });
      setData(updatedDepot.find(dep => dep.value === depot_id)?.articles || []);
      return updatedDepot;
    });
  };
  
  
  
  
  const [tousLesZero, setTousLesZero] = useState(true)
  useEffect(() => {
    verifier_saisie();
  }, [depot,dateDebut,dateFin]); 
  

 

  const verifier_saisie = () => {
   let verif_zero=0;
    for (const d of depot) {
      for (const article of d.articles) {
        if (article.nouvelle_quantite !==0 && ( (dateDebut!=="" &&  dateDebut!==null) &&( dateFin!=="" && dateFin!==null))) {
         verif_zero=1;
          
        }
        
      }
      
    }
if(verif_zero>0){
  setTousLesZero(false);

}
else{
  setTousLesZero(true);

}


   
  }




  const getStockParDepot = selectedDepot => {
    const depotArticles = depot.find(dep => dep.value === selectedDepot.value)?.articles;

    setData(depotArticles || []);
    setDepot_id(selectedDepot.value);

  };
  

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", 
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  /* useEffect(async () => {
     const res = await API.post("stock/consulter_par_depot", {
       nbrElParPage: 5,
       page: 1,
     }).then(res => {
       setData(res.data)
       console.log("table", data)
       //setNbrTotlEl(res.data.Article[0].total)
     })
     setLoading(true)
   }, [])
 
   /*const newElement = async (page, totalRows) => {
     setMycurrentPage(page)
     const res = await API.post("article/list", {
       nbrElParPage: nbrEltperPage,
       page: page,
     }).then(res => {
       setData(res.data.Article[0].Article)
       setNbrTotlEl(res.data.Article[0].total)
     })
   }*/

  /*const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("article/list", {
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.Article[0].Article)
      setNbrTotlEl(res.data.Article[0].total)
    })
  }*/

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const [isDisabled, setIsDisabled] = useState(true);

  // Fonction de validation pour le champ de sélection du dépôt
const validateDepot = () => {
  if (!depotSelected) {
    return "Le champ Dépôt est requis.";
  }
  return "";
};

// Fonction de validation pour le champ de date de début
const validateDateDebut = () => {
  if (!dateDebutSelected) {
    return "Le champ Date Début est requis.";
  }
  return "";
};

// Fonction de validation pour le champ de date de fin
const validateDateFin = () => {
  if (!dateFinSelected) {
    return "Le champ Date Fin est requis.";
  }
  return "";
};


  const save = async () => {
    if (!depot_id) {
      return;
    }
    let isEmpty = true;
    depot.forEach(dep => {
      dep.articles.forEach(article => {
        if (article.nouvelle_quantite !== "") {
          isEmpty = false;
        }
      });
    });

    if (isEmpty) {
      return;
    }
    const userA = JSON.parse(localStorage.getItem('userAuth'))
    const userID= userA.user.id;
    try {
      const res = await API.post("inventaire/add", {
        depot: depot,
        dateDeb: dateDebut,
        dateFin: dateFin,
        user_id:userID
      });
  
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);

    }
    window.location.reload()
  };


  const toggleSupp = async (id) => {
    const res = await API.post("article/delete", {
      id: id,
    }).then(res => {
      setModal(!modal)
      const resD = API.post("article/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Article[0].Article)
        setNbrTotlEl(resD.data.Article[0].total)
      })
    })
  }

  const getRemarqueTextColorClass = (remarque) => {
    return remarque === "Tout est bien" ? "text-success" : "text-danger";
  };

  const { SearchBar } = Search

  return (
    <React.Fragment>

      <div>

        {loading ? (

          <Row>
             
            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-arround",
                  }}
                >
                  <Label for="basicpill-firstname-input1">
                    Depot
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>
                <Select
                required 
                  options={depot}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={(selectedOption) => {
                    handleDepotChange(selectedOption);
                    getStockParDepot(selectedOption); 
                  }}
                  styles={{
                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                  }}
                  
                />
                  {depotError && <div className="error-message" style={{ color: "red" }}>{depotError}</div>}
              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Début</Label>
                <Col md={12} className="pr-0">
                <DatePicker
                    className="form-control ddate"
                    selected={dateDebut}
                    onChange={e => setDateDebut(e)}
                    dateFormat="dd/MM/yyyy"
                    required 
                    
                  />
                </Col>
              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Fin</Label>
                <Col md={12} className="pr-0">
                <DatePicker
                    className="form-control ddate"
                    selected={dateFin}
                    onChange={e => setDateFin(e)}
                    dateFormat="dd/MM/yyyy"
                    required 
                  />
                </Col>
              </div>
            </Col>


          



            <Col sm="12">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <input
                    id="search-bar-0"
                    type="text"
                    aria-labelledby="search-bar-0-label"
                    className="form-control "
                    placeholder="Search"
                    onChange={(currentRowsPerPage, currentPage) =>
                      filterSearch(currentRowsPerPage, currentPage)
                    }
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col xs="12">
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      code
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Désignation
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité depot
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité maximal
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité Minimal
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Quantité Totale de l article
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                    Remarque
                    </th>

                    <th style={{ textAlign: "center" }} scope="col">
                      Nouvelle Quantité
                    </th>

                    
                  </tr>
                </thead>
                {data.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.code}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.designation}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.quantite}
                      </td>
                      <td style={{ textAlign: "center", width: "8%" }}>
                        {el.quantite_max}
                      </td>
                      <td style={{ textAlign: "center", width: "7%" }}>
                        {el.quantite_min}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        {el.quantite_totale}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }} className={getRemarqueTextColorClass(el.remarque)}>
                        {el.remarque}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <Input
                        required 
                          type="number"
                          className="form-control"
                          placeholder="Nouvelle quantité"
                          value={el.nouvelle_quantite === 0 ? '' : el.nouvelle_quantite}
                          onChange={(e) => handleNewQuantityChange(depot_id, index, e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}

              </table>
            </Col>
            <Col md={4}  > </Col>
            <Col md={1}  ></Col>
            <Col md={4}  >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="submit"
                  className="btn btn-primary "
                  onClick={save}
                  disabled={tousLesZero} // Désactiver le bouton si tousLesZero est vrai
                >
                  Terminer Inventaire
                </button>
              </div>
            </Col>



          </Row>


        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      
    
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddInventaire))
AddInventaire.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
