import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';

const StatistiqueFactDirectFrs = () => {
    const [etatPercentages, setEtatPercentages] = useState([]);
    const [etatFacturationValues, setEtatFacturationValues] = useState({ total_Bl: 0, totalFact: 0 });

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('fournisseur/facture/get_stat');
                const data = response.data["FactureDirect"]; // Use the correct key for FactureBl

                setEtatPercentages([
                    { value: parseFloat(data.etat_regelement_percentages["réglé"]), name: 'réglé' },
                    { value: parseFloat(data.etat_regelement_percentages["non réglé"]), name: 'non réglé' },
                ]);

                setEtatFacturationValues({
                    total_direct: parseFloat(data.total_facture_direct[0].total_direct),
                    totalFact: parseFloat(data.total_facture_direct[0].totalFact),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);


    const getOptionEtat = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: ['réglé', 'non réglé'],
                textStyle: {
                    color: ['#74788d'],
                },
            },
            color: ['#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'État Règlement',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: etatPercentages,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };

    const getOptionFacturation = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} ({d}%)",
            },
            legend: {
                orient: "vertical",
                x: "left",
                data: ["Facture Direct", "Total des factures"],
                textStyle: {
                    color: ["#74788d"],
                },
            },
            color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
            series: [
                {
                    name: "Total sales",
                    type: "pie",
                    radius: ["50%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: 30,
                                fontWeight: "bold",
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                    data: [
                        { value: etatFacturationValues.total_direct, name: 'Facture Direct' },
                        { value: etatFacturationValues.totalFact - etatFacturationValues.total_direct, name: 'Total des factures' },
                    ],
                },
            ],
        };
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
                <p>État Règlement</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionEtat()} />
            </div>
            <div style={{ flex: 1 }}>
                <p>Facture Direct</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
            </div>
        </div>
    );
};

export default StatistiqueFactDirectFrs ;