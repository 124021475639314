/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Label, Row } from "reactstrap"
import API from "../../../api"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddTypeArt = props => {
  const [code, setCode] = useState("")
  const [etatCode, setEtatCode] = useState(false)
  const [name, setName] = useState("")

  const save = async () => {
    if (name != "" && code != "") {
      const res = await API.post("type/article/add", {
        code: code,
        name: name,
      }).then(res => {
        if (res.data.status == 200) {
          setEtatCode(false)
          props.setSection(1)
        } else {
          setEtatCode(true)
        }
      })
    }
  }
  return (
    <React.Fragment>
      <div>
        <Row>
          {etatCode ? (
            <div className="alert alert-danger">
              <strong>Code deja utilisé!</strong>
            </div>
          ) : null}
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Code")}
                  </Label>
                  <AvField
                    name="code"
                    placeholder=" Entrer code"
                    type="text"
                    errorMessage="* Code obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setCode(e.target.value)}
                    maxlength="5"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Type d'article")}
                  </Label>
                  <AvField
                    name="type"
                    placeholder="Entrer type d'article"
                    type="text"
                    errorMessage="* Type d'article obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={save}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default withTranslation()(AddTypeArt)
AddTypeArt.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
