import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';

const StatCmd = () => {
    const [etatPercentages, setEtatPercentages] = useState([]);
    const [etatFacturationPercentages, setEtatFacturationPercentages] = useState([]);
    const [stat, setStat] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('bon/comande/client/get_stat');
                const data = response.data.CommandeClient;
                setStat(data.etat_percentages);

                setEtatPercentages([
                    { value: parseFloat(data.etat_percentages.total), name: 'total' },
                    { value: parseFloat(data.etat_percentages.partielle), name: 'partielle' },
                    { value: parseFloat(data.etat_percentages['non satisfait']), name: 'non satisfait' },
                ]);

                setEtatFacturationPercentages([
                    { value: data.etat_facturation_percentages[0], name: 'totalCommandes' },
                    { value: data.etat_facturation_percentages[1], name: 'facturé' },
                    { value: data.etat_facturation_percentages[2], name: 'non facturé' },
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);
console.log(etatFacturationPercentages);
    const getOptionEtat = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: ['total', 'partielle', 'non satisfait'],
                textStyle: {
                    color: ['#74788d'],
                },
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'Total sales',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: etatPercentages,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };

    const getOptionFacturation = () => {
        return {
            tooltip: {
                trigger: "axis",
            },
            grid: {
                zlevel: 0,
                x: 50,
                x2: 50,
                y: 30,
                y2: 30,
                borderWidth: 0,
            },
            xAxis: {
                type: "category",
                data: ["total de commande", "facturé", "non facturé"],
                axisLabel: {
                    color: "#74788d",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: "#74788d",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
            series: [
                {
                    data: etatFacturationPercentages,
                    type: "line",
                },
            ],
            color: ["#556ee6"],
            textStyle: {
                color: ["#74788d"],
            },
        };
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '20px' }}>
                <p>État Paiement</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionEtat()} />
            </div>
            <div style={{ flex: 1 }}>
                <p>État Facturation</p>
                <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
            </div>
        </div>
    );
};

export default StatCmd;
