
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { Col, Label, Progress, Row, Spinner, Input, CardTitle } from "reactstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { options } from "toastr";
import API from "../../api"
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
import { check } from "prettier";




const AddPromo = props => {
    const [loading, setLoading] = useState(false);
    const [Id, setId] = useState()
    const [diplayArticles, setDisplayArticles] = useState(false)
    const [diplayClients, setDisplayClients] = useState(false)
    const [displayedArticles, setDisplayedArticles] = useState([])
    const [promo, setPromo] = useState([])
    const maxHeight = 190

    const options = [
        { value: 'Articles', label: 'Sur articles' },
        { value: 'Client', label: 'Sur client' },
        //{ value: 'Facture', label: 'Sur facture' },
        //{ value: 'Commande', label: 'Sur commande' },
        // { value: 'Livraison', label: 'Sur livraison' },


    ];


    useEffect(async () => {
        var type_promo = promo.type_promo
        if (type_promo === "Articles") {
            setDisplayArticles(true)
            setDisplayClients(false)
        } else if (type_promo === "Client") {
            setDisplayClients(true)
            setDisplayArticles(false)
        }
    }, [promo])

    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        setId(id)
        const res = await API.post("promotion/get_by_id", { id }).then(res => {
            setDisplayedArticles(res.data.lignes)
            setPromo(res.data.promo)
            setLoading(true)
        })

    }, [])




    return (
        <React.Fragment>
            <div className="card"  >
                <div style={{ textAlign: "center", marginTop: "10%", marginLeft: "3%", marginRight: "3%", }}>
                    <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                        Consultation détails promotion
                    </CardTitle>
                    {loading ? (

                        <div>
                            <Row>

                                <Col md={3}>
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Nom De Promotion
                                            </Label>

                                        </div>
                                        <Input
                                            classNamePrefix="select2-selection"
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                            value={promo.nom_promo}
                                            disabled={true}

                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Date de debut de promotion
                                            </Label>

                                        </div>
                                        <Input
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                            value={promo.date_debut_promotion}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Date de fin de promotion
                                            </Label>

                                        </div>
                                        <Input
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                            value={promo.date_fin_promotion}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Type
                                            </Label>

                                        </div>
                                        <Input
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                            value={promo.type_promo}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {diplayArticles ? (
                                <div>



                                    <Row>
                                        <div style={{ justifyContent: "center" }}  >
                                            <table className="table table-bordered mt-4 "  >
                                                <thead>
                                                    <tr>

                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Code
                                                        </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Prix de achat
                                                        </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Prix de vente                     </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Taux de promotion
                                                        </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Prix apres promotion
                                                        </th>

                                                    </tr>
                                                </thead>
                                                {displayedArticles.map((el, index) => (
                                                    <tbody key={index}>
                                                        <tr>

                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.nom}
                                                            </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.article.prix_achat_ht}                      </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.prix_avant_promotion}
                                                            </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                <Input type="number" value={el.taux}

                                                                />
                                                            </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                <Input value={el.prix_apres_promotion !== undefined ? el.prix_apres_promotion : ""} disabled />
                                                            </td>

                                                            {/*<td style={{ textAlign: "center", width: "10%" }}>
                                                        <Input
                                                        required 
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Nouvelle quantité"
                                                            value={el.nouvelle_quantite === 0 ? '' : el.nouvelle_quantite}
                                                            onChange={(e) => handleNewQuantityChange(depot_id, index, e.target.value)}
                                                />
                                                </td>*/}
                                                        </tr>
                                                    </tbody>
                                                ))}

                                            </table>

                                        </div>





                                        <Col md={4}></Col>
                                        <Col md={1}></Col>
                                        <Col md={4}>




                                        </Col>
                                    </Row>
                                </div>
                            ) : null}

                            {diplayClients ? (
                                 <div>



                                    <Row>
                                        <div style={{ justifyContent: "center" }}  >
                                            <table className="table table-bordered mt-4 "  >
                                                <thead>
                                                    <tr>
                                                    <th style={{ textAlign: "center" }} scope="col">
                                                            code client
                                                        </th>

                                                        <th style={{ textAlign: "center" }} scope="col">
                                                            Nom client
                                                        </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                           Taux
                                                        </th>
                                                        <th style={{ textAlign: "center" }} scope="col">
                                                           Type de client           </th>
                                                        

                                                    </tr>
                                                </thead>
                                                {displayedArticles.map((el, index) => (
                                                    <tbody key={index}>
                                                        <tr>
                                                            
                                                        <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.client_id}
                                                            </td>

                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.nom}
                                                            </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.taux}                      </td>
                                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                                {el.famille}
                                                            </td>
                                                            
                                                
                                                        </tr>
                                                    </tbody>
                                                ))}

                                            </table>

                                        </div> 
                                    </Row>
                                </div>

                            ) : null }


                        </div>

                    ) : (

                        <div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {" "}
                                <Spinner type="grow" className="ms-6" color="primary" />
                            </div>
                            <h4
                                style={{ textAlign: "center", marginTop: "6%" }}
                                className="ms-6"
                            >
                                {" "}
                                {props.t("Chargement en cours...")}
                            </h4>
                        </div>
                    )} </div>
            </div>

        </React.Fragment >
    );
};

AddPromo.propTypes = {
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(AddPromo);
