/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Spinner, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap"
import API from "../../../api"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddCompteur = props => {
  const [array, setArray] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const res = await API.get("compteur/get_by_id").then(res => {
      setArray(res.data.Compteur)
      setLoading(true)
    })
  }, [])

  const addPrefixAchat = (event, indexOfPrefix) => {
    let pref = event
    setArray(
      array.map((el, id) =>
        id === indexOfPrefix
          ? Object.assign(el, {
              prefix: pref,
            })
          : el
      )
    )
  }

  const addNumeroAchat = (event, indexOfNum) => {
    let num = event
    setArray(
      array.map((el, id) =>
        id === indexOfNum
          ? Object.assign(el, {
              numero: num,
            })
          : el
      )
    )
  }

  const addPrefixVente = (event, index) => {
    let indexPrefix = index
    setArray(
      array.map((el, id) =>
        id === indexPrefix + 7
          ? Object.assign(el, {
              prefix: event,
            })
          : el
      )
    )
  }

  const addNumeroVente = (event, index) => {
    let num = event
    let indexNum = index + 7
    setArray(
      array.map((el, id) =>
        id === indexNum
          ? Object.assign(el, {
              numero: num,
            })
          : el
      )
    )
  }

  const save = async () => {
    const res = await API.post("compteur/add", {
      ligne: array,
    }).then(res => {
      toast.success(" numéro modifié avec succès ", {
        containerId: "A",
      })
    })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Row>
            <Col xl="6">
              <Card>
                <CardBody className="overflow hidden">
                  <CardTitle style={{ color: "#556ee6" }} className="h4">
                    Achat :
                  </CardTitle>
                  {array.slice(0, 7).map((elem, index) => (
                    <div key={index}>
                      <Row>
                        <Col className="mt-2" lg={4}>
                          <span>{elem.name}</span>
                        </Col>
                        <Col lg={2}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="..."
                              value={elem.prefix}
                              onChange={e =>
                                addPrefixAchat(e.target.value, index)
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Input
                              type="number"
                              className="form-control"
                              value={elem.numero}
                              onChange={e =>
                                addNumeroAchat(e.target.value, index)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
            {/*  */}
            <Col xl="6">
              <Card>
                <CardBody className="overflow hidden">
                  <CardTitle style={{ color: "#556ee6" }} className="h4">
                    Vente :
                  </CardTitle>
                  {array.slice(7, 15).map((elem, index2) => (
                    <div key={index2}>
                      <Row>
                        <Col className="mt-2" lg={4}>
                          <span>{elem.name}</span>
                        </Col>
                        <Col lg={2}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="..."
                              value={elem.prefix}
                              onChange={e =>
                                addPrefixVente(e.target.value, index2)
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Input
                              type="number"
                              className="form-control"
                              value={elem.numero}
                              onChange={e =>
                                addNumeroVente(e.target.value, index2)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                  onClick={save}
                  type="submit"
                  className="btn btn-primary "
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddCompteur

AddCompteur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
