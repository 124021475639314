import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import API from "../../api"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
} from "reactstrap"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"

//i18n
import { withTranslation } from "react-i18next"

import PierChartTier from "./PierChartTier"

const Dashboard = props => {
  const [produits, setProduits] = useState("")
  const [tiers, setTiers] = useState("")
  const [services, setServices] = useState("")
  const [fournisseurs, setFournisseurs] = useState("")
  const [clients, setClients] = useState("")
  const [prospects, setProspects] = useState("")
  const [commende_frs, setCommende_frs] = useState("")
  const [livraion_frs, setLivraion_frs] = useState("")
  const [facture_frs, setFacture_frs] = useState("")

  useEffect(async () => {
    const res = await API.get("stat_dash")
    setProduits(res.data.Stat.produits)
    setServices(res.data.Stat.services)
    setTiers(res.data.Stat.tiers)
    setFournisseurs(res.data.Stat.fr)
    setClients(res.data.Stat.clt)
    setProspects(res.data.Stat.pros)
    setCommende_frs(res.data.Stat.cmd_frs)
    setLivraion_frs(res.data.Stat.liv_frs)
    setFacture_frs(res.data.Stat.fact_frs)
  }, [])

  const reports = [
    {
      title: "Produits",
      iconClass: "bx bx-group",
      description: produits,
      color: "#1A374D",
    },
    {
      title: "Services",
      iconClass: "bx-archive-in",
      description: services,
      color: "#406882",
    },
    {
      title: "Tiers",
      iconClass: "bx-purchase-tag-alt",
      description: tiers,
      color: "#6998AB",
    },
    {
      title: "Fournisseurs",
      iconClass: "bx-purchase-tag-alt",
      description: fournisseurs,
      color: "#876445",
    },
    {
      title: "Clients",
      iconClass: "bx-purchase-tag-alt",
      description: clients,
      color: "#CA965C",
    },
    {
      title: "Prospects",
      iconClass: "bx-purchase-tag-alt",
      description: prospects,
      color: "#EEC373",
    },
    {
      title: "Commandes Client",
      iconClass: "bx-purchase-tag-alt",
      description: "4",
      color: "#E26A2C",
    },
    {
      title: "Commandes Fournisseur",
      iconClass: "bx-purchase-tag-alt",
      description: commende_frs,
      color: "#FF8243",
    },
    {
      title: "Livraisons Client",
      iconClass: "bx-purchase-tag-alt",
      description: "4",
      color: "#FDA65D",
    },
    {
      title: "Livraisons Fournisseur",
      iconClass: "bx-purchase-tag-alt",
      description: livraion_frs,
      color: "#B983FF",
    },
    {
      title: "Factures Clients",
      iconClass: "bx-purchase-tag-alt",
      description: "4",
      color: "#94B3FD",
    },
    {
      title: "Factures Fournisseur",
      iconClass: "bx-purchase-tag-alt",
      description: facture_frs,
      color: "#94DAFF",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Cresus</title>
        </MetaTags>
        <Container fluid>

          <Row>
            <Col xl="11">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              {/* <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <div className="stats-card purple-card mb-4">
                            <Media style={{ height: "20px" }} body>
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>
                            <i
                              style={{ backgroundColor: report.color }}
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row> */}

              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <Col lg="6">
                      <Card>
                        <CardBody>
                          <CardTitle>Total Tier</CardTitle>
                          <div id="pie-chart" className="e-chart">
                            <PierChartTier />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
