
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { Col, Label, Progress, Row, Spinner, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { options } from "toastr";
import API from "../../api"
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
import { check } from "prettier";


import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddPromo = props => {
  const [loading, setLoading] = useState(false);
  const [dateDebut, setDateDebut] = useState(new Date());
  const [dateFin, setDateFin] = useState(new Date());
  const [Taux, setTaux] = useState(0);
  const [NomPromo, setNomPromo]= useState ("");
  const [Type, setType] = useState("");
  const [SelectType, setSelectType] = useState("");
  const [data, setData] = useState([])
  const [arrayProduit, setArrayProduit] = useState([])
  const [arrayClient, setArrayClient] = useState([])
  const [displayedClients, setDisplayedClients] = useState([])

  const [displayBlock, setDisplayBlock] = useState(false);
  const [displayBlockClient, setDisplayBlockClient] = useState(false);


  const [marque, setMarque] = useState([])
  const [selectMarque, setSelectMarque] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")

  const [famille, setFamille] = useState([])
  const [selectFamille, setSelectFamille] = useState("")
  const [famille_Client, setFamille_Client] = useState([])

  const [type_article, setTypeArticle] = useState([])
  const [selectTypeArticle, setSelectTypeArticle] = useState("")
  const [checked_all, setchecked_all] = useState(false)
  const [checked_all_clients, setchecked_all_clients] = useState(false)
  const [CIN_Client, setCIN_Client] = useState("")
  const [selected_familleClient, setselected_familleClient] = useState({ value: null, label: "(None)" })

  const [displayedArticles, setDisplayedArticles] = useState([])
  const maxHeight = 190

  const options = [
    { value: 'Articles', label: 'Sur articles' },
    { value: 'Client', label: 'Sur client' },
    //{ value: 'Facture', label: 'Sur facture' },
    //{ value: 'Commande', label: 'Sur commande' },
    // { value: 'Livraison', label: 'Sur livraison' },


  ];


  const change_taux_total = (e) => {
    if (e.target.value < 101) { setTaux(e.target.value); }
    else {
      toast.error(
        "Le taux de remise ne doit pas dépasser 100%",
        {
          containerId: "A",
        }
      )
    }

  };


  useEffect(() => {
    setArrayProduit(
      arrayProduit.map((el) => {
        if (el.etat === 1) {
          return {
            ...el,
            taux: Taux,
            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
          };
        } else {
          return el;
        }
      })
    );
    setDisplayedArticles(
      displayedArticles.map((el) => {
        if (el.etat === 1) {
          return {
            ...el,
            taux: Taux,
            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
          };
        } else {
          return el;
        }
      })
    );
  }, [Taux]);



  const check_all_clients = (event) => {
    if (event.target.checked == true) {
      setchecked_all_clients(true)
      let etat = 1;

      const modifiedIds = displayedClients.map(el => el.id);
      setArrayClient(arrayClient.map(el => {
        if (modifiedIds.includes(el.id)) {
          return {
            ...el,
            etat: etat
          };
        }
        return el;
      }));
      setDisplayedClients(displayedClients.map(el => ({
        ...el,
        etat: etat,
      })));
    }
    else {
      setchecked_all_clients(false)
      let etat = 0;
      setArrayClient(
        arrayClient.map((el, id) => {
          return {
            ...el,
            etat: etat,
          };
        })
      );
      setDisplayedClients(
        displayedClients.map((el, id) => {
          return {
            ...el,
            etat: etat
          };
        })
      );
    }
  }


  const check_all = (event) => {
    if (event.target.checked == true) {
      setchecked_all(true)
      let etat = 1;

      const modifiedIds = displayedArticles.map(el => el.id);
      setArrayProduit(arrayProduit.map(el => {
        if (modifiedIds.includes(el.id)) {
          return {
            ...el,
            etat: etat,
            taux: Taux,
            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
          };
        }
        return el;
      }));
      setDisplayedArticles(displayedArticles.map(el => ({
        ...el,
        etat: etat,
        taux: Taux,
        prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
      })));
    }
    else {
      setchecked_all(false)
      let etat = 0;

      setArrayProduit(
        arrayProduit.map((el, id) => {
          return {
            ...el,
            etat: etat,
            taux: 0,
            prix_apres_prom: undefined
          };
        })
      );
      setDisplayedArticles(
        displayedArticles.map((el, id) => {
          return {
            ...el,
            etat: etat,
            taux: 0,
            prix_apres_prom: undefined
          };
        })
      );

    }

  }


  const coche_client = (event, index, id) => {
    if (event.target.checked == true) {
      let etat = 1
      setArrayClient(
        arrayClient.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: etat,
            })
            : el
        )
      )
      setDisplayedClients(
        displayedClients.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: etat,
            })
            : el
        )
      )

    } else {
      setArrayClient(
        arrayClient.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: 0,
            })
            : el
        )
      )
      setDisplayedClients(
        displayedClients.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: 0,
            })
            : el
        )
      )
    }
  }



  const coche = (event, index, id) => {
    if (event.target.checked == true) {
      let etat = 1
      setArrayProduit(
        arrayProduit.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: etat,
              taux: Taux,
              prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
            })
            : el
        )
      )
      setDisplayedArticles(
        displayedArticles.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: etat,
              taux: Taux,
              prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
            })
            : el
        )
      )

    } else {
      setArrayProduit(
        arrayProduit.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: 0,
              taux: 0,
              prix_apres_prom: undefined
            })
            : el
        )
      )
      setDisplayedArticles(
        displayedArticles.map((el, idx) =>
          el.id == id
            ? Object.assign(el, {
              etat: 0,
              taux: 0,
              prix_apres_prom: undefined
            })
            : el
        )
      )
    }
  }
  const change_taux = (e, index, id) => {
    if (e.target.value < 101) {
      setArrayProduit(
        arrayProduit.map((el, idx) =>
          el.id == id
            ? {
              ...el,
              taux: e.target.value,
              prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (e.target.value / 100))
            }
            : el
        )
      );
      setDisplayedArticles(
        displayedArticles.map((el, idx) =>
          el.id == id
            ? {
              ...el,
              taux: e.target.value,
              prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (e.target.value / 100))
            }
            : el
        )
      );
    }
    else {
      toast.error(
        "Le taux de remise ne doit pas dépasser 100%",
        {
          containerId: "A",
        }
      )
    }

  };



  const save = async () => {
    if (dateDebut != "" && dateFin != "" && SelectType != undefined && NomPromo != "") {
      if (dateDebut <= dateFin) {
        if (SelectType.label === "Sur articles") {
          const res = await API.post("promotion/add", {
            dateDebut: dateDebut,
            dateFin: dateFin,
            Taux: Taux,
            Type: SelectType.value,
            NomPromo: NomPromo,
            arrayProduit: arrayProduit

          }).then(res => {
            props.setSection(1);
          })
        } else if (SelectType.label === "Sur client") {
          if (Taux !== "") {
            const res = await API.post("promotion/add", {
              dateDebut: dateDebut,
              dateFin: dateFin,
              Taux: Taux,
              Type: SelectType.value,
              NomPromo: NomPromo,
              arrayClient: arrayClient

            }).then(res => {
              props.setSection(1)
            })
          } else {
            toast.error(
              "Veuillez remplir Taux ",
              {
                containerId: "A",
              }
            )
          }
        } else {
          toast.error(
            "La date de début doit être antérieure à la date de fin",
            {
              containerId: "A",
            }
          )
        }
      }
    } else {
      toast.error(
        "Veuillez remplir tous les champs obligatoires ",
        {
          containerId: "A",
        }
      )
    }
  }
  const Liste = async (selectedOption) => {
    setSelectType(selectedOption)
    if (selectedOption.label === "Sur articles") {
      const res = await API.get("article/index_all")
        .then(res => {
          setArrayProduit(res.data.Article[0].Article)
          setDisplayedArticles(res.data.Article[0].Article)
          setDisplayBlock(true)
          setDisplayBlockClient(false)

        })
    }

    if (selectedOption.label === "Sur client") {
      const res = await API.get("client/index_all")
        .then(res => {
          setArrayClient(res.data)
          setDisplayedClients(res.data)
          setDisplayBlock(false)
          setDisplayBlockClient(true)
        })

      const res1 = await API.get("famille/client/select").then(res => {
        const elementVide = { value: null, label: "(None)" };
        const nouvellesFamilles_Client = [elementVide, ...res.data.FamilleClient];
        setFamille_Client(nouvellesFamilles_Client)
      })

    }

    const res = await API.get("marque/select")

      .then(res => {
        const elementVide = { id: null, label: "(None)" };
        const nouvellesMarques = [elementVide, ...res.data.Marque];
        setMarque(nouvellesMarques);
      });

    const resC = await API.get("groupe/article/select")

      .then(resC => {
        const elementVide = { id: null, label: "(None)" };
        const nouveauxGroupes = [elementVide, ...resC.data.GroupeArticle];
        setGroupe(nouveauxGroupes);
      });


    const resD = await API.get("famille/groupe/article/select")
      .then(resD => {
        const elementVide = { id: null, label: "(None)" };
        const nouvellesFamilles = [elementVide, ...resD.data.FamilleGroupeArticle];
        setFamille(nouvellesFamilles);
      });

    const resFO = await API.get("type/article/select")
      .then(resFO => {
        const elementVide = { id: null, label: "(None)" };
        const nouvellesArticles = [elementVide, ...resFO.data.TypeArticle];
        setTypeArticle(nouvellesArticles);
      });

  }


  useEffect(async () => {
  


    const contientEtatZero = displayedClients.some(objet => objet.etat === 0 || objet.etat === undefined);
    if (contientEtatZero) {
      setchecked_all_clients(false)
    }
    else { setchecked_all_clients(true) }
  }, [arrayClient, displayedClients]);


  useEffect(async () => {
    

    const contientEtatZero = displayedArticles.some(objet => objet.etat === 0 || objet.etat === undefined);
    if (contientEtatZero) {
      setchecked_all(false)
    }
    else { setchecked_all(true) }


  }, [arrayProduit, displayedArticles]);

  useEffect(async () => {
    const filteredArticles = arrayProduit.filter(produit => {
      return (selectMarque.value == null || produit.marque && produit.marque.id === selectMarque.value) &&
        (selectGroupe.value == null || produit.groupe && produit.groupe.id === selectGroupe.value) &&
        (selectFamille.value == null || produit.famille && produit.famille.id === selectFamille.value) &&
        (selectTypeArticle.value == null || produit.type && produit.type.id === selectTypeArticle.value);
    });



    // const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);
    // const filteredArticles = arrayProduit.filter(produit => produit.groupe.id == selectGroupe.value);
    /*  const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);
      const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);*/
    // Mettre à jour l'état displayedArticles avec les articles filtrés
    setDisplayedArticles(filteredArticles);
    setchecked_all(false)
    /*console.log("Marque", selectMarque)
     const res = await API.post("article/recherche", {
       marque_id: selectMarque.value,
       groupe_id: selectGroupe.value,
       famille_id: selectFamille.value,
       type_article_id: selectTypeArticle.value
     }
     ).then(res => {
       console.log("recherche", res)
       setArrayProduit(res.data.Data[0].Articles)
     })*/
  }, [selectMarque, selectGroupe, selectFamille, selectTypeArticle]);


  useEffect(async () => {
   
    const filteredClients = arrayClient.filter(client => {
      return (!CIN_Client || client.cin && client.cin.includes(CIN_Client)) &&
        (selected_familleClient.value === null || client.famille && client.famille.value === selected_familleClient.value);
    });

    setDisplayedClients(filteredClients);
    setchecked_all(false)
    /*console.log("Marque", selectMarque)
     const res = await API.post("article/recherche", {
       marque_id: selectMarque.value,
       groupe_id: selectGroupe.value,
       famille_id: selectFamille.value,
       type_article_id: selectTypeArticle.value
     }
     ).then(res => {
       console.log("recherche", res)
       setArrayProduit(res.data.Data[0].Articles)
     })*/
  }, [CIN_Client, selected_familleClient]);



  useEffect(async () => {
    setLoading(true);
  }, []);

  return (
    <React.Fragment>
      <div >
        {loading ? (
          <Row>
            <Col md={4} sm={6}>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    //justifyContent: "space-around",
                  }}
                >
                  <Label for="basicpill-firstname-input2">
                    Type
                  </Label>
                  <Label style={{ color: "red", paddingLeft: "5px" }}>
                    *
                  </Label>
                </div>

                <Select

                  options={options}
                  isSearchable={true}
                  classNamePrefix="select2-selection"
                  onChange={selectedOption => { Liste(selectedOption) }}

                />


              </div>
            </Col>
            <Col md={4} sm={6}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Nom de promotion</Label>
                    <Col md={12} className="pr-0">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                           type="text"
                           onChange={e=> {setNomPromo(e.target.value)}}
                          
                        />

                      </div>
                    </Col>
                  </div>
                </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Début</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateDebut}
                    onChange={date => setDateDebut(date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </Col>
              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Date Fin</Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={dateFin}
                    onChange={date => setDateFin(date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </Col>
              </div>
            </Col>

            <Col md={4} sm={6}>
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Taux</Label>
                <Col md={12} className="pr-0">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      // type="number"
                      value={Taux > 0 ? Taux : ""}
                      onChange={e => change_taux_total(e)}
                    />

                  </div>
                </Col>
              </div>
            </Col>

            {displayBlock ? (


              <div>
                <Row>
                  <Col md={3}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-arround",
                      }}
                    >
                      <Label for="basicpill-firstname-input1">
                        Marque
                      </Label>

                    </div>
                    <Select
                      options={marque}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={e => {
                        setSelectMarque(e);

                      }}

                    />
                  </Col>
                  <Col md={3}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-arround",
                      }}
                    >
                      <Label for="basicpill-firstname-input1">
                        Groupe
                      </Label>

                    </div>
                    <Select
                      options={groupe}
                      isSearchable={true}
                      classNamePrefix="select2-selection"
                      onChange={e => setSelectGroupe(e)}
                      styles={{
                        menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-arround",
                        }}
                      >
                        <Label for="basicpill-firstname-input1">
                          Famille
                        </Label>

                      </div>
                      <Select
                        options={famille}
                        isSearchable={true}
                        classNamePrefix="select2-selection"
                        onChange={e => setSelectFamille(e)}
                        styles={{
                          menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-arround",
                        }}
                      >
                        <Label for="basicpill-firstname-input1">
                          Type Article
                        </Label>

                      </div>
                      <Select
                        options={type_article}
                        isSearchable={true}
                        classNamePrefix="select2-selection"
                        onChange={e => setSelectTypeArticle(e)}
                        styles={{
                          menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>

                      <th style={{ textAlign: "center" }} scope="col">
                        Code
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Prix de achat
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Prix de vente                     </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Taux de promotion
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Prix apres promotion
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Cocher { }
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          onChange={e => check_all(e)}
                          checked={checked_all}

                        />
                      </th>
                    </tr>
                  </thead>
                  {displayedArticles.map((el, index) => (
                    <tbody key={index}>
                      <tr>

                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.nom}
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.prix_achat_ht}                      </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.prix_vente_ht}
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input type="number" value={el.etat === 1 ? el.taux : ''}
                            onChange={e => change_taux(e, index, el.id)}
                            disabled={!el.etat}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input value={el.prix_apres_prom !== undefined ? el.prix_apres_prom : ""} disabled />
                        </td>
                        <td className="col-md-1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              onChange={e => coche(e, index, el.id)}
                              checked={el.etat === 1 ? true : false}
                            />
                          </div>
                        </td>
                        {/*<td style={{ textAlign: "center", width: "10%" }}>
                       <Input
                        required 
                          type="number"
                          className="form-control"
                          placeholder="Nouvelle quantité"
                          value={el.nouvelle_quantite === 0 ? '' : el.nouvelle_quantite}
                          onChange={(e) => handleNewQuantityChange(depot_id, index, e.target.value)}
                />
                </td>*/}
                      </tr>
                    </tbody>
                  ))}

                </table>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={save}
                  >
                    Confirmer
                  </button>
                </div>
              </div>



            ) : null}


            {displayBlockClient ? (


              <div>
                <Row>

                  <Col md={3}>
                    <div className="mb-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-arround",
                        }}
                      >
                        <Label for="basicpill-firstname-input1">
                          Famille
                        </Label>

                      </div>
                      <Select
                        options={famille_Client}
                        isSearchable={true}
                        classNamePrefix="select2-selection"
                        onChange={e => setselected_familleClient(e)}
                        styles={{
                          menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                        }}
                        value={selected_familleClient}

                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-arround",
                        }}
                      >
                        <Label for="basicpill-firstname-input1">
                          CIN
                        </Label>

                      </div>
                      <Input
                        onChange={e => setCIN_Client(e.target.value)}
                        styles={{
                          menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Code
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        CIN
                      </th>

                      <th style={{ textAlign: "center" }} scope="col">
                        Nom client

                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Telephone        </th>

                      <th style={{ textAlign: "center" }} scope="col">
                        Cocher { }
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          onChange={e => check_all_clients(e)}
                          checked={checked_all_clients}

                        />
                      </th>
                    </tr>
                  </thead>
                  {displayedClients.map((el, index) => (
                    <tbody key={index}>
                      <tr>

                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.code}
                        </td>

                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.cin}
                        </td>

                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.raisonSocial}
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          {el.telephone}                      </td>



                        <td className="col-md-1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              onChange={e => coche_client(e, index, el.id)}
                              checked={el.etat === 1 ? true : false}
                            />
                          </div>
                        </td>
                        {/*<td style={{ textAlign: "center", width: "10%" }}>
         <Input
          required 
            type="number"
            className="form-control"
            placeholder="Nouvelle quantité"
            value={el.nouvelle_quantite === 0 ? '' : el.nouvelle_quantite}
            onChange={(e) => handleNewQuantityChange(depot_id, index, e.target.value)}
  />
  </td>*/}
                      </tr>
                    </tbody>
                  ))}

                </table>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={save}
                  >
                    Confirmer
                  </button>
                </div>
              </div>



            ) : null}
            <Col md={4}></Col>
            <Col md={1}></Col>
            <Col md={4}>




            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  );
};

AddPromo.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(AddPromo);
