import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import {
    Col, Label, Input,
} from "reactstrap";
import Select from "react-select"

import DatePicker from "react-datepicker";
import API from '../../api';
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const StatistiqueSuivi = () => {
    const [etatPercentages, setEtatPercentages] = useState([]);
    //const [etatFacturationPercentages, setEtatFacturationPercentages] = useState([]);
    const [stat, setStat] = useState([]);
    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")

    const [displayBlock, setDisplayBlock] = useState(false);
    const [NbrFact, setStateNbrFact] = useState("")
    const [NbrLiv, setStateNbrLiv] = useState("")
    const [NbrVente, setStateNbrVente] = useState("")

    const [NbrTotaleQuantite,setstateNbrTotaleQuantite]=useState("")
    const [NbrQuantiteProduit,setstateNbrQuantiteProduit]=useState("")
    const [critere_suivie,setCritere_suivie]=useState("")




    const options = [
        { value: '1', label: 'Statistiques par nombre de ventes' },
        { value: '2', label: 'Statistiques par quantite vendues' },

    ];
    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };



    const getOptionEtat = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: '10px',
                top: 'top',
                data: etatPercentages.map(item => item.name),
                textStyle: {
                    color: '#74788d',
                },
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf', '#8e44ad', '#e74c3c', '#27ae60', '#f39c12', '#3498db', '#9b59b6', '#2ecc71', '#e67e22', '#1abc9c', '#c0392b', '#16a085', '#d35400', '#2980b9', '#8e44ad'],
            series: [
                {
                    name: 'Total sales',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: etatPercentages,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };



    const handleClickAfficher = async () => {
        if (!selectedOption) {
            toast.error("⛔Veuillez sélectionner un type de suivi", {
                containerId: "A",
            });
            return;
        }
        if (dateDebut && dateFin) {
            try {
                const response = await API.post('stock/stat_suivie_par_livraison_and_facture', {
                    dateDeb: dateDebut,
                    dateFin: dateFin,
                    ValSelect:selectedOption.value
                });
                

                const data = response.data.etatPercentages;
                if(selectedOption.value==='1'){
                    setStateNbrFact(response.data.nb_factures)
                    setStateNbrLiv(response.data.nb_livraison)
                    setStateNbrVente(response.data.nb_ventes)
                    setCritere_suivie(1)
                
                }

                else if (selectedOption.value==='2'){
                    setstateNbrTotaleQuantite(response.data.nb_total_des_quantites)
                    setstateNbrQuantiteProduit(response.data.nb_quanties_par_produit)
                    setCritere_suivie(2)
                }

                const formattedData = Object.entries(data).map(([key, value]) => ({
                    name: key,
                    value: parseFloat(value.replace('%', '')),
                }));

                setEtatPercentages(formattedData);
                setDisplayBlock(true);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Une erreur s'est produite lors du chargement des données.", {
                    containerId: "A",
                });
            }
        } else {
            toast.error("⛔Veuillez sélectionner une date de début et une date de fin", {
                containerId: "A",
            })
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '20px', height: '180px' }}>
                <div className="mb-3" style={{ marginRight: '20px' }}>
                    <Label for="basicpill-lastname-input2">Date Début</Label>
                    <DatePicker
                        className="form-control date"
                        selected={dateDebut}
                        onChange={date => setDateDebut(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>

                <div className="mb-3" style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <Label for="basicpill-lastname-input2">Date Fin</Label>
                    <DatePicker
                        className="form-control date"
                        selected={dateFin}
                        onChange={date => setDateFin(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>

                <div className="mb-3" style={{ marginRight: '20px', marginLeft: '20px'  ,width: '300px'}}>
                    <Label for="basicpill-lastname-input2">Type du suivi</Label>
                    <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        required
                         
                    />
                </div>
            </div>

            <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
                <div className="text-center mt">
                    <button
                        type="button"
                        style={{
                            backgroundColor: "#761C19",
                            borderColor: "#761C19",
                            color: "white",
                        }}
                        className="btn mb-1 me-1"
                        onClick={handleClickAfficher}
                    >
                        Afficher
                    </button>
                </div>
            </div>

            {displayBlock ? (

                <div style={{ display: 'flex' }}>

                    <div style={{ flex: 1, marginRight: '20px' }}>
                        {critere_suivie===1 ?(
                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">Nombre  de facture:</Label>
                                <p>{NbrFact}</p>
                            </div>

                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">Nombre  de livraison:</Label>
                                <p>{NbrLiv}</p>
                            </div>

                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">Nombre  de vente:</Label>
                                <p>{NbrVente}</p>
                            </div>
                           
                        </div> 
                         ): null}


                            {critere_suivie===2 ?(
                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                            <div className="mb-1" style={{ marginRight: '20px' }}>
                                <Label for="basicpill-lastname-input2">nombre de quantiée vendue:</Label>
                                <p>{NbrTotaleQuantite}</p>
                            </div>

                           

                            
                           
                        </div> 
                         ): null}       
                        <p>Ventes</p>

                        <ReactEcharts style={{ height: '350px' }} option={getOptionEtat()} />
                    </div>
                </div>
            ) : null}
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </div>
    );
};

export default StatistiqueSuivi;