import React, { useEffect, useState, Fragment } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"

import {
    Col,
    Form,
    Label,
    Row,
    CardTitle,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
import { set } from "lodash"

const AddReglementClient = props => {
    const [loading, setLoading] = useState(false)
    const [disbutReg, setDisbutReg] = useState(true)
    const [client, setClient] = useState([])
    // const [numReglement, setNumReglement] = useState("")
    const [selectClient, setSelectClient] = useState("")
    const [date, setDate] = useState(new Date())
    const [dateEsp, setDateEsp] = useState("")
    const [dateEch, setDateEch] = useState("")
    const [dateEnc, setDateEnc] = useState("")
    const [listFacture, setListFacture] = useState([])
    const [blockList, setBlockList] = useState(false)
    const [disable, setDisable] = useState(false)
    const [mntTotal, setMntTotal] = useState(0)
    const [mntPaye, setMntPaye] = useState(0)

    const [modePay, setModePay] = useState([])
    const [banque, setBanque] = useState([])
    const [compte, setCompte] = useState([])
    const [modal, setModal] = useState(false)
    const [indexLigne, setIndexLigne] = useState("")
    const [taux, setTaux] = useState([])
    const [arraypaiement, setArraypaiement] = useState([])
    const [blockPaiement, setBlockPaiement] = useState(false)
    const [facture, setFacture] = useState([])
    const [arrayModePaiement, setArrayModePaiement] = useState([
        {
            modPay: "",
            montant: "",
        },
    ])



    useEffect(() => {
        const fetchData = async () => {
            try {
                const resClient = await API.get("client/select");
                setClient(resClient.data.Client);

                const resModePay = await API.get("paiement/select");
                setModePay(resModePay.data.TypePaiement);
                const resB = await API.get("banque/matricule/select").then(resB => {
                    setBanque(resB.data.MatriculeBancaire)
                })
                const resTaux = await API.get("taux/retenue/select");
                const formattedTauxData = resTaux.data.TauxRetenue.map(item => ({
                    value: item.label,
                    label: item.label
                }));
                setTaux(formattedTauxData);
            } catch (error) {
                // Handle error if needed
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    // Ajout Ligne
    const addLigne = () => {
        let element = {
            modPay: "",
            montant: "",
        }
        setArrayModePaiement([...arrayModePaiement, element])
    }

    const toggle = indexOfLigne => {
        setModal(!modal)
        setIndexLigne(indexOfLigne)
    }

    const toggleDelete = async () => {
        arrayModePaiement.splice(indexLigne, 1)
        setModal(false)
    }

    const addFacture = (e, indexOfFacture) => {
        var facture_id = e.value
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfFacture
                    ? Object.assign(el, {
                        facture_id: facture_id,
                    })
                    : el
            )
        )
    }

    const addModePay = (e, indexOfModePay) => {

        const newArrayPaiement = [...arraypaiement]
        newArrayPaiement[indexOfModePay].modePaiement = e
        // newArrayPaiement[indexOfModePay].modePaiementOptions=e
        setArraypaiement(newArrayPaiement);
        var modePay = e.value
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfModePay
                    ? Object.assign(el, {
                        modPay: modePay,
                    })
                    : el
            )
        )
    }

    const addMnt = (e, indexOfMnt) => {
        var mnt = e.target.value
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfMnt
                    ? Object.assign(el, {
                        montant: mnt,
                    })
                    : el
            )
        )
    }

    const addDate = (e, indexDate) => {
        var date = e
        if (date != "") {
            let dateEsp = date
            let month = "" + (dateEsp.getMonth() + 1)
            let day = "" + dateEsp.getDate()
            let year = dateEsp.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [day, month, year].join("/")

            setDateEsp(dateEsp)
            setArraypaiement(arraypaiement.map((el, id) =>
                id === indexDate ? { ...el, dateEsp: dateEsp } : el
            ));
        } else {
            var convertDate = ""
            setDateEsp(null)
        }

    }

    const addDateEch = (e, indexOfDateEch) => {
        var date = e
        if (date != "") {
            let dateEch = date
            let month = "" + (dateEch.getMonth() + 1)
            let day = "" + dateEch.getDate()
            let year = dateEch.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("/")
            setDateEch(dateEch)
            setArraypaiement(arraypaiement.map((el, id) =>
                id === indexOfDateEch ? { ...el, dateEch: dateEch } : el));
        } else {
            var convertDate = ""
            setDateEch(null)

        }

    }

   

    const addNumCheque = (e, indexOfNumCheq) => {
        var numCheque = e.target.value
        setArraypaiement(arraypaiement.map((el, id) =>
            id === indexOfNumCheq ? { ...el, numCheque: numCheque } : el));
    }

    const addBanque = async (e, indexOfBanque) => {
        var selectBanque = e.value
        setArraypaiement(
            arraypaiement.map((el, id) =>
                id === indexOfBanque
                    ? {
                        ...el,
                        banque: selectBanque,
                    }
                    : el
            )
        )
    }

    const addCompte = (e, indexOfCompte) => {
        var selectCompte = e.value
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfCompte
                    ? Object.assign(el, {
                        compte: selectCompte,
                    })
                    : el
            )
        )
    }

    const addDateEnc = (e, indexOfDateEnc) => {
        var date = e
        if (date != "") {
            let dateEnc = date
            let month = "" + (dateEnc.getMonth() + 1)
            let day = "" + dateEnc.getDate()
            let year = dateEnc.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("/")
            setDateEnc(dateEnc);
            
            setArraypaiement(arraypaiement.map((el, id) =>
                id === indexOfDateEnc ? { ...el, dateEnc: dateEnc } : el));
        } else {
            var convertDate = ""
            setDateEnc(null);


        }
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfDateEnc
                    ? Object.assign(el, {
                        dateEnc: convertDate,
                    })
                    : el
            )
        )
    }

    const addTaux = (e, indexOfTaux) => {
        var taux = parseFloat(e.label)
        var taux_id = e.value
        var montant = arrayModePaiement[indexOfTaux].montant
        var mntTaux = (montant * taux) / 100
        var montantNet = montant - mntTaux
        setArrayModePaiement(
            arrayModePaiement.map((el, id) =>
                id === indexOfTaux
                    ? Object.assign(el, {
                        taux: taux_id,
                        mntTaux: mntTaux,
                        montantNet: montantNet,
                    })
                    : el
            )
        )
    }

    const search = async () => {
        if (selectClient != null) {
            var client_id = selectClient.value;
            const res = await API.post("reglement/client/list_cli", {
                client_id: client_id,
            }).then(res => {
                const updatedList = res.data.List.map(el => {
                    return {
                        ...el,
                        checkboxDisb: el.mntTotal === el.mntPaye,
                    };
                });

                setListFacture(updatedList);
                setBlockList(true);
            });
        }
    };



    /*  const handleBlur = indexOfBlur => {
          var id = listFacture[indexOfBlur].id
          var array = arraypaiement
          array.push(id)
          var unique = [...new Set(array)]
          setArraypaiement(unique)
      }*/

    const editDate = date => {
        setDate(date)
    }

    const addMontant = (event, indexOfMontant) => {
        var mntPaiement = event.target.value
        var mntPaye = listFacture[indexOfMontant].mntPaye
        var mntAPaye = listFacture[indexOfMontant].mntAPaye
        var total = listFacture[indexOfMontant].mntTotal
        if (mntPaye == 0) {
            if (parseFloat(mntAPaye) >= parseFloat(mntPaiement)) {
                var mntRestant = mntAPaye - mntPaiement
                setListFacture(
                    listFacture.map((el, id) =>
                        id === indexOfMontant
                            ? Object.assign(el, {
                                mnt: mntPaiement,
                                mntReste: mntRestant,
                            })
                            : el
                    )
                )

                const option = {
                    value: listFacture[indexOfMontant].id,
                    label: listFacture[indexOfMontant].numero,
                    montantPaye: listFacture[indexOfMontant].mnt,
                    modePaiement: null,
                    modePaiementOptions: null
                };

                const id = listFacture[indexOfMontant].id;
                const existingIndex = arraypaiement.findIndex(item => item.value === id);

                if (existingIndex !== -1) {
                    const newArray = [...arraypaiement];
                    newArray[existingIndex] = option;
                    setArraypaiement(newArray);
                } else {
                    var newArray = [...arraypaiement, option];
                    setArraypaiement(newArray);
                    
                }


                var mntTotal = 0
                for (let i = 0; i < listFacture.length; i++) {
                    mntTotal += parseFloat(listFacture[i].mnt)
                }
                setMntTotal(Number.parseFloat(mntTotal).toFixed(3))
            } else {
                toast.error("⛔ Le montant est supérieur !", {
                    containerId: "A",
                })
            }
        } else if (mntPaye > 0) {
            var resteMontant = total - mntPaye
            if (parseFloat(mntAPaye) >= parseFloat(mntPaiement)) {
                mntRestant = resteMontant - mntPaiement
                setListFacture(
                    listFacture.map((el, id) =>
                        id === indexOfMontant
                            ? Object.assign(el, {
                                mnt: mntPaiement,
                                mntReste: mntRestant,
                            })
                            : el
                    )
                )


                const option = {
                    value: listFacture[indexOfMontant].id,
                    label: listFacture[indexOfMontant].numero,
                    montantPaye: listFacture[indexOfMontant].mnt,
                    modePaiement: null,
                    modePaiementOptions: null
                };

                const id = listFacture[indexOfMontant].id;
                const existingIndex = arraypaiement.findIndex(item => item.value === id);

                if (existingIndex !== -1) {
                    const newArray = [...arraypaiement];
                    newArray[existingIndex] = option;
                    setArraypaiement(newArray);
                } else {
                    var newArray = [...arraypaiement, option];
                    setArraypaiement(newArray);
                     
                }


                var mntTotal = 0
                for (let i = 0; i < listFacture.length; i++) {
                    mntTotal += parseFloat(listFacture[i].mnt)
                }
                setMntTotal(Number.parseFloat(mntTotal).toFixed(3))
            } else {
                toast.error("⛔ Le montant est supérieur !", {
                    containerId: "A",
                })
            }
        }
        
        if (!(mntPaiement > 0)) {
            const newData = arraypaiement.filter(item => item.value !== listFacture[indexOfMontant].id);
            setArraypaiement(newData);
        }
    }

    const cocheMontant = (e, indexOfCoche) => {

        if (e.target.checked === true) {

            var montant = listFacture[indexOfCoche].mntReste

            var diff = listFacture[indexOfCoche].mntTotal - listFacture[indexOfCoche].mntPaye

            /* var montantRestant =
                 listFacture[indexOfCoche].mntAPaye -
                 (parseFloat(listFacture[indexOfCoche].mntPaye) + montant)*/
            setListFacture(
                listFacture.map((el, id) =>
                    id === indexOfCoche
                        ? Object.assign(el, {
                            mnt: diff,
                            mntReste: 0,
                        })
                        : el
                )
            )
            var mntTotal = 0
            for (let i = 0; i < listFacture.length; i++) {
                mntTotal += parseFloat(listFacture[i].mnt)
            }
            const existingPayment = arraypaiement.some(item => item.value === listFacture[indexOfCoche].id);

      if (!existingPayment) {
        const option = {
          value: listFacture[indexOfCoche].id,
          label: listFacture[indexOfCoche].numero,
          montantPaye: listFacture[indexOfCoche].mnt,
          modePaiement: null,
          modePaiementOptions: null,
        };
      
        setArraypaiement(prevArray => [...prevArray, option]);
      } else {
        setArraypaiement(prevArray =>
          prevArray.map(item =>
            item.value === listFacture[indexOfCoche].id
              ? {
                  ...item,
                  montantPaye: listFacture[indexOfCoche].mnt,
                }
              : item
          )
        );
      }
        } else {
            var id = listFacture[indexOfCoche].id
            var array = arraypaiement
            array.splice(indexOfCoche, 1)
            setArraypaiement(array)
            var montant = 0
            /* var montantRestant =
                 listFacture[indexOfCoche].mntAPaye -
                 (parseFloat(listFacture[indexOfCoche].mntPaye) + montant)*/
            var diff = listFacture[indexOfCoche].mntTotal - listFacture[indexOfCoche].mntPaye
            setListFacture(
                listFacture.map((el, id) =>
                    id === indexOfCoche
                        ? Object.assign(el, {
                            mnt: 0,
                            mntReste: diff,
                        })
                        : el
                )
            )
            var array = arraypaiement.filter(item => item.value !== listFacture[indexOfCoche].id);
            setArraypaiement(array);
            var mntTotal = 0
           /* for (let i = 0; i < listFacture.length; i++) {
                mntTotal += parseFloat(listFacture[i].mnt)
            }

            setMntTotal(Number.parseFloat(mntTotal).toFixed(3))*/
        }

        var sommeMnt = listFacture.reduce((acc, facture) => acc + parseFloat(facture.mnt), 0);
                    setMntTotal(sommeMnt)



    }

    const pay = async () => {



        setBlockPaiement(true)

    }

    const save = async () => {
        var sommePaiement = 0
        sommePaiement = parseFloat(mntTotal)
        var sommeReg = 0
        for (let i = 0; i < arrayModePaiement.length; i++) {
            sommeReg += parseFloat(arrayModePaiement[i].montant)
        }
        /*if (sommePaiement != sommeReg) {
             toast.error("⛔ Veuillez vérifier les sommes saisie !", {
                 containerId: "A",
             })
         } else {*/
        setDisbutReg(false)
        var userAuth = JSON.parse(localStorage.getItem("userAuth"))
        var userId = userAuth.user.id
        if (date != "") {
            let dateReg = date
            let month = "" + (dateReg.getMonth() + 1)
            let day = "" + dateReg.getDate()
            let year = dateReg.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        var client_id = selectClient.value
        const res = await API.post("reglement/client/add", {
            //numero: numReglement,
            user_id: userId,
            date: convertDate,
            client_id: client_id,
            arrayModePaiement: arrayModePaiement,
            arraypaiement: arraypaiement,
            total: mntTotal,
            LigneFacture: listFacture,
            LigneModePay: arrayModePaiement,
        }).then(res => {
            props.setSection(1)
        })
        //}
    }

    return (
        <React.Fragment>
            <Form>
                <Row>
                    {/* <Col md={3}>
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                N° Reglement
                            </Label>
                            <Input
                                lg="3"
                                type="text"
                                className="form-control"
                                disabled
                                value={numLivraison}
                            />
                        </div>
                    </Col>*/}
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Client</Label>
                            <Select
                                options={client}
                                isSearchable={true}
                                onChange={e => setSelectClient(e)}
                            />

                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">Date</Label>
                            <Col md={12} className="pr-0">
                                <DatePicker
                                    className="form-control ddate"
                                    selected={date}
                                    onChange={editDate}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div
                className="mb-4"
                style={{ display: "flex", justifyContent: "center" }}
            >
                {" "}
                <div className="text-center mt-4">
                    <button
                        type="button"
                        style={{
                            backgroundColor: "#761C19",
                            borderColor: "#761C19",
                            color: "white",
                        }}
                        className="btn mb-2 me-2"
                        onClick={search}
                    >
                        Afficher
                    </button>
                </div>
            </div>
            {blockList === true ? (
                <Fragment>
                    {" "}
                    <table className="table mt-4">
                        <thead className="table-light">
                            <tr>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Num Facture
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Date
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant HT
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant TTC
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant déja payé
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant a payé
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Montant restant
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                    Cocher
                                </th>
                            </tr>
                        </thead>
                        {listFacture.map((el, index) => (
                            <tbody key={el.id}>
                                <tr>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                color: "#5784BA",
                                                textDecoration: "underline",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const url = `/DetailFactClient?id=${el.id}`;
                                              window.location.href = url;
                                            }}
                                          >
                                            {el.numero}
                                          </p>
                                        </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {el.date}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.totalHT).toFixed(3)}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.mntTotal).toFixed(3)}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <p
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {Number.parseFloat(el.mntPaye).toFixed(3)}
                                        </p>
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <input
                                            type="number"
                                            value={el.mnt}
                                            //onBlur={() => handleBlur(index)}
                                            onChange={e => addMontant(e, index)}
                                            style={{
                                                textAlign: "center",
                                                borderRadius: "5px",
                                                border: "solid 1px #B3B3B3",
                                            }}
                                        />
                                    </td>
                                    <td className="col-md-2" style={{ textAlign: "center" }}>
                                        <input
                                            type="number"
                                            disabled
                                            value={Number.parseFloat(el.mntReste).toFixed(3)}
                                            style={{
                                                textAlign: "center",
                                                borderRadius: "5px",
                                            }}
                                        />
                                    </td>
                                    <td className="col-md-1">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Checkbox
                                                onChange={e => cocheMontant(e, index)}
                                                disabled={el.checkboxDisb}
                                                
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                    <table className="table mt-4">
                        <tbody>
                            <tr style={{ backgroundColor: "#f8f8fb" }}>
                                <td style={{ fontWeight: "bold" }} className="col-md-2">
                                    {" "}
                                    Montant Total Payé :{" "}
                                </td>
                                <td className="col-md-2"> </td>
                                <td className="col-md-2"> </td>
                                <td className="col-md-2"> </td>
                                <td
                                    style={{ fontWeight: "bold", textAlign: "center" }}
                                    className="col-md-1"
                                >
                                    {mntTotal}
                                </td>
                                <td className="col-md-2"> </td>
                                <td className="col-md-2"> </td>
                                <td className="col-md-1"> </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        className="mb-4"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {" "}
                        <div
                            className="text-center mt-4"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <button type="button" className="btn btn-primary " onClick={pay}>
                                Procédure de paiement
                            </button>
                        </div>
                    </div>
                    {blockPaiement === true && arraypaiement.map((el, index) => (


                        <Fragment key={index} >
                            <Row className="mb-2">
                                <Col sm="4">
                                    <CardTitle
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                        className="h4 mt-4"
                                    >
                                        Mode de paiement :{" "}
                                    </CardTitle>
                                </Col>

                            </Row>
                            <Row>
                                <Col key={index} lg="12" className="pt-4">
                                    <Form>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-firstname-Input"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Facture
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    value={el.label}
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-firstname-Input"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Mode de paiement
                                            </Label>
                                            <Col sm={9}>
                                                <Select
                                                    options={modePay}
                                                    isSearchable={true}
                                                    onChange={e => addModePay(e, index)}
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-firstname-Input"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Montant
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="..."
                                                    value={el.montantPaye}

                                                />
                                            </Col>
                                        </div>
                                        {el.modePaiement && el.modPay === 1 ? (
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Date1
                                                </Label>
                                                <Col md={9} className="pr-0">
                                                    <DatePicker
                                                        placeholderText="__/__/____"
                                                        className="form-control ddate"
                                                        selected={dateEsp}
                                                        onChange={e => addDate(e, index)}
                                                        dateFormat="dd/MM/yyyy"

                                                    />
                                                </Col>
                                            </div>
                                        ) : null}
                                        {el.modePaiement && el.modPay === 2 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Echeance1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            placeholderText="__/__/____"
                                                            className="form-control ddate"
                                                            selected={dateEch}
                                                            //value={el.dateEch}
                                                            onChange={e => addDateEch(e, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modPay === 3 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Date Encaisement2
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            className="form-control ddate"
                                                            selected={dateEnc}
                                                            onChange={e => addDateEnc(e, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />

                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque2
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque2
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modPay === 4 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Echeance
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            placeholderText="__/__/____"
                                                            className="form-control ddate"
                                                            selected={dateEch}
                                                            //value={el.dateEch}
                                                            onChange={e => addDateEch(e, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque3
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque3
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modPay === 5 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Taux
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={taux}
                                                            isSearchable={true}
                                                            onChange={e => addTaux(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Montant taux
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            value={el.mntTaux}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Montant Net
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            value={el.montantNet}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>



                                        ) : null}

                                        {el.modePaiement && el.modePaiement.value === 1 ? (
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-3 col-form-label"
                                                >
                                                    Date
                                                </Label>
                                                <Col md={9} className="pr-0">
                                                    <DatePicker
                                                        placeholderText="__/__/____"
                                                        className="form-control ddate"
                                                        selected={el.dateEsp}
                                                        //value={el.dateEsp}
                                                        onChange={e => addDate(e, index)}
                                                        dateFormat="dd/MM/yyyy"

                                                    />
                                                </Col>
                                            </div>
                                        ) : null}
                                        {el.modePaiement && el.modePaiement.value === 2 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Echeance33
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            placeholderText="__/__/____"
                                                            className="form-control ddate"
                                                            selected={el.dateEch}
                                                            value={el.dateEch}
                                                            onChange={e => addDateEch(e, index)}
                                                            dateFormat="dd/MM/yyyy"

                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque4
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque4
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modePaiement.value === 3 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Date Encaisement1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            className="form-control ddate"
                                                            selected={el.dateEnc}
                                                            onChange={e => addDateEnc(e, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque5
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque5
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modePaiement.value === 4 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Echeance4
                                                    </Label>
                                                    <Col sm={9}>
                                                        <DatePicker
                                                            placeholderText="__/__/____"
                                                            className="form-control ddate"
                                                            selected={dateEch}
                                                            //value={el.dateEch}
                                                            onChange={e => addDateEch(e, index)}
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Numéro cheque6
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            onChange={e => addNumCheque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Banque6
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={banque}
                                                            isSearchable={true}
                                                            onChange={e => addBanque(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : el.modePaiement && el.modePaiement.value === 5 ? (
                                            <Fragment>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Taux
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Select
                                                            options={taux}
                                                            isSearchable={true}
                                                            onChange={e => addTaux(e, index)}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Montant taux
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            value={el.mntTaux}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Montant Net
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="..."
                                                            value={el.montantNet}
                                                        />
                                                    </Col>
                                                </div>
                                            </Fragment>
                                        ) : null}

                                    </Form>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <i
                                            style={{ color: "#cc0000", cursor: "pointer" }}
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                            onClick={() => toggle(index)}
                                        />
                                    </div>{" "}
                                    <div style={{ borderBottom: "1px solid #E4E9E2" }}></div>{" "}
                                </Col>

                            </Row>

                        </Fragment>
                    ))}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {" "}
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={props.back}
                                >
                                    Annuler
                                </Button>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                                <button
                                    disabled={!disbutReg}
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={save}
                                >
                                    CONFIRMER
                                </button>
                            </div>
                        </Col>
                    </div>
                </Fragment>


            ) : null}


            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle} tag="h4">
                    Suppression ligne{" "}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    onClick={toggleDelete}
                                    color="info"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default AddReglementClient
AddReglementClient.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
}