/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import Select from "react-select"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

const AddFournisseur = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [codeFourniseur, setCodeFournisseur] = useState()
  const [codeComptable, setCodeComptable] = useState("")
  const [file, setFile] = useState(null)
  // information
  const [raisonSocial, setRaisonSocial] = useState("")
  const [representant, setRepresentant] = useState([])
  const [selectRepresentant, setSelectRepresentant] = useState("")
  const [charge, setCharge] = useState([])
  const [selectCharge, setSelectCharge] = useState("")
  const [portable, setPortable] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [fax, setFax] = useState("")
  // contact
  const [telContact, setTelContact] = useState("")
  const [portableContact, setPortableContact] = useState("")
  const [emailContact, setEmailContact] = useState("")
  const [siteInternet, setSiteInternet] = useState("")
  // Identifiant
  const [typeIdentifiant, setTypeIdentifiant] = useState([])
  const [selectIdentifiant, setSelectIdentifiant] = useState("")
  const [code, setCode] = useState("")
  const [famille, setFamille] = useState([])
  const [selectFamille, setSelectFamille] = useState("")
  // Adresse
  const [adresse1, setAdresse1] = useState("")
  const [gouvernorat, setGouvernorat] = useState([])
  const [selectGouvernorat, setSelectGouvernorat] = useState("")
  const [municipalite, setMunicipalite] = useState([])
  const [selectMunicipalite, setSelectMunicipalite] = useState("")
  const [delegation, setDelegation] = useState([])
  const [selectDelegation, setSelectDelegation] = useState("")
  const [codePostal, setCodePostal] = useState("")
  // Nature
  const [formeJuridique, setFormeJuridique] = useState([])
  const [selectForme, setSelectForme] = useState("")
  const [etat, setEtat] = useState("1")
  const [etatTimbre, setEtatTimbre] = useState("")
  const [etatFodec, setEtatFodec] = useState("")
  const [etatRegime, setEtatRegime] = useState("")
  const [etatAssujetti, setEtatAssujetti] = useState("")
  const [etatAttestation, setEtatAttestation] = useState("")
  const [etat2, setEtat2] = useState(1)
  const [dateReception, setDateReception] = useState(new Date())
  const [dateDebut, setDateDebut] = useState(new Date())
  const [dateFin, setDateFin] = useState(new Date())
  // Divers
  const [modeReglement, setModeReglement] = useState([])
  const [selectModeReglement, setSelectModeReglement] = useState("")
  const [delaiReglement, setDelaiReglement] = useState([])
  const [banque, setBanque] = useState([])
  const [selectBanque, setSelectBanque] = useState("")
  const [rib, setRib] = useState("")
  const [remise, setRemise] = useState("")
  const [tauxRetenue, setTauxRetenue] = useState([])
  const [selectTauxRetenue, setSelectTauxRetenue] = useState("")
  const [observation, setObservation] = useState("")

  useEffect(async () => {
    const res = await API.get("personnel/select").then(res => {
      setRepresentant(res.data.Personnel)
    })
    const resC = await API.get("personnel/select").then(resC => {
      setCharge(resC.data.Personnel)
    })
    const resT = await API.get("type/identifiant/select").then(resT => {
      setTypeIdentifiant(resT.data.TypeIdentifiant)
    })
    const resF = await API.get("famille/fournisseur/select").then(resF => {
      setFamille(resF.data.FamilleFournisseur)
    })
    const resG = await API.get("gouvernerat/select").then(resG => {
      setGouvernorat(resG.data.Gouvernerat)
    })
    const resM = await API.get("minicipalite/select").then(resM => {
      setMunicipalite(resM.data.Minicipalite)
    })
    const resD = await API.get("delegation/select").then(resD => {
      setDelegation(resD.data.Delegation)
    })
    const resFO = await API.get("forme/juridique/select").then(resFO => {
      setFormeJuridique(resFO.data.FormeJuridique)
    })
    const resP = await API.get("mode/reglement/select").then(resP => {
      setModeReglement(resP.data.ModeReglement)
    })
    const resB = await API.get("banque/matricule/select").then(resP => {
      setBanque(resP.data.MatriculeBancaire)
    })
    const resTR = await API.get("taux/retenue/select").then(resTR => {
      setTauxRetenue(resTR.data.TauxRetenue)
    })
  }, [])

  const cocheTimbre = e => {
    if (e.target.checked) {
      setEtatTimbre(1)
    } else {
      setEtatTimbre(0)
    }
  }

  const cocheFodec = e => {
    if (e.target.checked) {
      setEtatFodec(1)
    } else {
      setEtatFodec(0)
    }
  }

  const cocheRegime = e => {
    if (e.target.checked) {
      setEtatRegime(1)
    } else {
      setEtatRegime(0)
    }
  }

  const cocheAssujetti = e => {
    if (e.target.checked) {
      setEtatAssujetti(1)
    } else {
      setEtatAssujetti(0)
    }
  }

  const cocheAttestation = e => {
    if (e.target.checked) {
      setEtatAttestation(1)
    } else {
      setEtatAttestation(0)
    }
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    if (etat == 1) {
      var etatTva = 1
      var etatExonore = 0
      var etatImport = 0
    } else if (etat == 2) {
      var etatTva = 0
      var etatExonore = 1
      var etatImport = 0
    } else if (etat == 2) {
      var etatTva = 0
      var etatExonore = 0
      var etatImport = 1
    }
    if (etat2 == 1) {
      var etatFactEchu = 1
      var etatFactControle = 0
    } else if (etat2 == 2) {
      var etatFactEchu = 0
      var etatFactControle = 1
    }
    // convert date réception
    if (dateReception != "") {
      let dateR = dateReception
      let month = "" + (dateR.getMonth() + 1)
      let day = "" + dateR.getDate()
      let year = dateR.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateReception = [year, month, day].join("-")
    } else {
      var convertDateReception = ""
    }
    // convert date debut
    if (dateDebut != "") {
      let dateD = dateDebut
      let month = "" + (dateD.getMonth() + 1)
      let day = "" + dateD.getDate()
      let year = dateD.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateDebut = [year, month, day].join("-")
    } else {
      var convertDateDebut = ""
    }
    // convert date fin
    if (dateFin != "") {
      let dateF = dateFin
      let month = "" + (dateF.getMonth() + 1)
      let day = "" + dateF.getDate()
      let year = dateF.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateFin = [year, month, day].join("-")
    } else {
      var convertDateFin = ""
    }
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (codeFourniseur != "" && raisonSocial != "" && code != "") {
      const res = await API.post("fournisseur/add", {
        code: codeFourniseur,
        code_comptable: codeComptable,
        raisonSocial: raisonSocial,
        representant_id: selectRepresentant.value,
        charge_id: selectCharge.value,
        portable: portable,
        tel: tel,
        email: email,
        fax: fax,
        telContact: telContact,
        portableContact: portableContact,
        emailContact: emailContact,
        siteInternet: siteInternet,
        typeIdentifiant: selectIdentifiant.value,
        code_identifiant: code,
        famille_id: selectFamille.value,
        adresse: adresse1,
        gouvernorat_id: selectGouvernorat.value,
        municipalite_id: selectMunicipalite.value,
        delegation_id: selectDelegation.value,
        codePostal: codePostal,
        formeJuridique_id: selectForme.value,
        modeReglement_id: selectModeReglement.value,
        delaiReglement: delaiReglement,
        banque_id: selectBanque.value,
        rib: rib,
        remise: remise,
        tauxRetenue_id: selectTauxRetenue.value,
        observation: observation,
        timbre: etatTimbre,
        fodec: etatFodec,
        regime_forfaitaire: etatRegime,
        assujetti: etatAssujetti,
        paieTVA: etatTva,
        exonore: etatExonore,
        import: etatImport,
        attestation_exnoration: etatAttestation,
        date_reception: convertDateReception,
        date_debut_validite: convertDateDebut,
        date_fin_validite: convertDateFin,
        fact_echu: etatFactEchu,
        fact_controle: etatFactControle,
      }).then((res) => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Informations :
                    </CardTitle>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input4">Code</Label>
                          <AvField
                            name="code"
                            placeholder="code"
                            type="text"
                            className="form-control"
                            onChange={e => setCodeFournisseur(e.target.value)}
                            errorMessage="* Code obligatoire"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input6">
                            Code comptable
                          </Label>
                          <Input
                            type="text"
                            placeholder="code comptable"
                            className="form-control"
                            onChange={e => setCodeComptable(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Raison sociale
                          </Label>
                          <AvField
                            name="nom"
                            placeholder="Raison sociale"
                            type="text"
                            errorMessage="* Raison sociale obligatoire"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={e => setRaisonSocial(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input5">
                            Représentant
                          </Label>
                          <Select
                            options={representant}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectRepresentant(e)}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input5">
                            {"Chargé d'affaire"}
                          </Label>
                          <Select
                            options={charge}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectCharge(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input14">
                            Portable
                          </Label>
                          <AvField
                            name="portable"
                            placeholder="Portable"
                            type="number"
                            onChange={e => setPortable(e.target.value)}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <AvField
                            name="number"
                            placeholder="Téléphone"
                            label="Téléphone"
                            type="number"
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                            }}
                            onChange={e => setTel(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input13">E-mail</Label>
                          <AvField
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            errorMessage="Invalid Email"
                            onChange={e => setEmail(e.target.value)}
                            validate={{
                              email: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input14">Fax</Label>
                          <AvField
                            name="fax"
                            placeholder="Fax"
                            type="number"
                            onChange={e => setFax(e.target.value)}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input13">
                            {"Logo"}
                          </Label>
                          <Input
                            onChange={e => addFile(e)}
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Solde :
                    </CardTitle>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Solde crédit
                          </Label>
                          <AvField
                            name="solde credit"
                            placeholder="Solde crédit"
                            type="number"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input14">
                            Solde en cours
                          </Label>
                          <AvField
                            name="solde en cours"
                            placeholder="Solde en cours"
                            type="number"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Contact :
                    </CardTitle>
                    <Row></Row>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Téléphone
                          </Label>
                          <AvField
                            name="Téléphone"
                            placeholder="Téléphone"
                            type="number"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                            }}
                            onChange={e => setTelContact(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input13">
                            Portable
                          </Label>
                          <AvField
                            name="Portable"
                            placeholder="Portable"
                            type="number"
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                            }}
                            onChange={e => setPortableContact(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">E-mail</Label>
                          <AvField
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            onChange={e => setEmailContact(e.target.value)}
                            validate={{
                              email: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input13">
                            Site internet
                          </Label>
                          <AvField
                            name="Site"
                            placeholder="Site internet"
                            type="text"
                            className="form-control"
                            onChange={e => setSiteInternet(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
              {/* height */}
                <Card >
                  <CardBody >
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Identifiant :
                    </CardTitle>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Type identifiant
                          </Label>
                          <Select
                            options={typeIdentifiant}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectIdentifiant(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input14">Code</Label>
                          <AvField
                            name="co"
                            placeholder="code"
                            type="text"
                            className="form-control"
                            errorMessage="* Code obligatoire"
                            // maxLength={selectIdentifiant.value == 2 ? 8 : 20}
                            validate={{ required: { value: true } }}
                            onChange={e => setCode(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row >
                      
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Famille
                          </Label>
                          <Select
                            options={famille}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectFamille(e)}
                          />
                        </div>
                      </Col>
                      
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <span className="d-none d-sm-block">Adresse</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <span className="d-none d-sm-block">Nature</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          setactiveTab("3")
                        }}
                      >
                        <span className="d-none d-sm-block">Divers</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          setactiveTab("4")
                        }}
                      >
                        <span className="d-none d-sm-block">Observation</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input12">
                                Adresse
                              </Label>
                              <AvField
                                name="Adresse"
                                placeholder="Adresse"
                                type="text"
                                className="form-control"
                                onChange={e => setAdresse1(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Gouvernorat
                              </Label>
                              <Select
                                options={gouvernorat}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectGouvernorat(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Délégation
                              </Label>
                              <Select
                                options={delegation}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectDelegation(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input13">
                                Municipalité
                              </Label>
                              <Select
                                options={municipalite}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectMunicipalite(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input13">
                                Code postal
                              </Label>
                              <AvField
                                name="cod"
                                placeholder="Code postal"
                                type="text"
                                className="form-control"
                                onChange={e => setCodePostal(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="6">
                          <Row>
                            <Col lg="12">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input5">
                                  {"Forme juridique"}
                                </Label>
                                <Select
                                  options={formeJuridique}
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  onChange={e => setSelectForme(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={6} sm={12}>
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck1"
                                    onChange={e => cocheTimbre(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck1"
                                  >
                                    Timbre
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck2"
                                    onChange={e => cocheFodec(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck2"
                                  >
                                    Fodek
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck3"
                                    onChange={e => cocheRegime(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Régime foritaire
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck4"
                                    onChange={e => cocheAssujetti(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Assujetti
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} sm={12}>
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline1"
                                    name="customRadioInline1"
                                    className="form-check-input"
                                    checked={etat == 1}
                                    onChange={e => setEtat(1)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Paie TVA
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline2"
                                    name="customRadioInline2"
                                    className="form-check-input"
                                    checked={etat == 2}
                                    onChange={e => setEtat(2)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Exonoré
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="customRadioInline3"
                                    name="customRadioInline3"
                                    className="form-check-input"
                                    checked={etat == 3}
                                    onChange={e => setEtat(3)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios3"
                                  >
                                    Import
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {etat == 2 ? (
                          <Col lg="6">
                            <Col lg="12">
                              <div className="form-check mb-3 mt-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="defaultCheck1"
                                  checked={etatAttestation == 1}
                                  onChange={e => cocheAttestation(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  {
                                    "Attestation d'exonoration de retenue à la source"
                                  }
                                </label>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Date de réception
                                </Label>
                                <Col sm={9}>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={dateReception}
                                    onChange={e => setDateReception(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Date début de validité
                                </Label>
                                <Col sm={9}>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={dateDebut}
                                    onChange={e => setDateDebut(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="row mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Date fin de validité
                                </Label>
                                <Col sm={9}>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={dateFin}
                                    onChange={e => setDateFin(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                          </Col>
                        ) : null}
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input12">
                                Mode de réglement
                              </Label>
                              <Select
                                options={modeReglement}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectModeReglement(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Délai de réglement
                              </Label>
                              <AvField
                                name="delai"
                                placeholder="Délai de réglement"
                                type="text"
                                className="form-control"
                                onChange={e =>
                                  setDelaiReglement(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input12">
                                Banque
                              </Label>
                              <Select
                                options={banque}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectBanque(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                RIB bancaire
                              </Label>
                              <AvField
                                name="rib"
                                placeholder="RIB bancaire"
                                type="text"
                                className="form-control"
                                onChange={e => setRib(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input12">
                                Remise (%)
                              </Label>
                              <AvField
                                name="remise"
                                placeholder="Remise"
                                type="text"
                                className="form-control"
                                onChange={e => setRemise(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Taux de la retenue à la source
                              </Label>
                              <Select
                                options={tauxRetenue}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectTauxRetenue(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <div className="mt-4">
                              <div className="form-check mb-3">
                                <Input
                                  type="radio"
                                  id="customRadioInline4"
                                  name="customRadioInline4"
                                  className="form-check-input"
                                  checked={etat2 == 1}
                                  onChange={e => setEtat2(1)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios4"
                                >
                                  Afficher uniquement les factures échues
                                </label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  type="radio"
                                  id="customRadioInline5"
                                  name="customRadioInline5"
                                  className="form-check-input"
                                  checked={etat2 == 2}
                                  onChange={e => setEtat2(2)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios5"
                                >
                                  {
                                    "Afficher toutes les factures avec controle sur l'échéance de la traite"
                                  }
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input12">
                              Observation
                            </Label>
                            <AvField
                              name="Observation"
                              placeholder="Observation"
                              type="textarea"
                              className="form-control"
                              onChange={e => setObservation(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  onClick={save}
                  type="submit"
                  className="btn btn-primary "
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddFournisseur
AddFournisseur.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  history: PropTypes.object,
}
