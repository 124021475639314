import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Table,
  Spinner,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddBonSortieClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee = userAuth.user.year
  //
  const [loading, setLoading] = useState(false)
  const [numBonSortie, setNumBonSortie] = useState("")
  const [dateBonSortie, setDateBonSortie] = useState(new Date())
  const [devisBc, setDevisBc] = useState("")
  const [selectDevisBc, setSelectDevisBc] = useState("")
  const [dateDevisBc, setDateDevisBc] = useState(new Date())
  const [client, setClient] = useState([])
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  const [modeRegl, setModeRegl] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [observation, setObservation] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots: [],
      product_selected_depot:null
    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [remise, setRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [fodec, setFodec] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);
  const [changedDevis, setchangedDevis]= useState(0)

  //
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }
  //
  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(async () => {
    const res = await API.post("bon/sortie/getnum",{
      annee:annee
    }).then(res => {
      setNumBonSortie(res.data.BonSortie)
      setLoading(true)
    })
    const resE = await API.get("bon/livraison/client/devi_boncommande").then(
      resE => {
        setDevisBc(resE.data.DevisBcClient)
      }
    )
    const resC = await API.get("client/select",
    ).then(resC => {
      setClient(resC.data.Client)
    })
    const resT = await API.get("mode/reglement/select").then(rest => {
      setModeRegl(rest.data.ModeReglement)
    })
    const resP = await API.post("article/select", {
      date : dateBonSortie
    }).then(resP => {
      setProduits(resP.data.Article)
    })
    const resD = await API.get("depot/select").then(resD => {
      setDepot(resD.data.Depot)
    })
  }, [])
    //selection_depot_pour_produit
    const select_articleDepot = (depot, indexOfProduit) => {
     
    
      const selectedOption = {
        value: depot.value,
        label: depot.label,
      };
      setArrayProduit((prevArrayProduit) =>
        prevArrayProduit.map((el, id) =>
          id === indexOfProduit
            ? {
                ...el,
                depot_id: depot.value,
                product_selected_depot: selectedOption,
              }
            : el
        )
      );
    };

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots:[],
      product_selected_depot:null
    }
    setArrayProduit([...arrayProduit, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal])

  const getDevisBcClient = async selectCodeClient => {
    if (selectCodeClient != null) {
      var client_id = selectCodeClient.value
    } else {
      var client_id = ""
    }
    setSelectCodeClient(selectCodeClient)
    // get Devis/BC du client
    const resD = await API.post("bon/livraison/client/get_client_by_bon_devi", {
      id: client_id,
    }).then(resD => {
      setDevisBc(resD.data.DevisBcClient)
    })
    //
    const res = await API.post("client/get_by_id", {
      id: client_id,
    }).then(res => {
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
    })

setSelectDevisBc("")
setDateDevisBc("")
    setArrayProduit([
      {
        produit_id: "",
        designation: "",
        fodec: "",
        qte: "",
        prix_ht: "",
        remise: "",
        montant_ht: 0,
        tva: "",
        montant_ttc: 0,
        montant_remise: 0,
        montant_fodec: 0,
        depot_id: "",
        depots:[],
      product_selected_depot:null
  
  
      },
    ])



  }

  const getClientDevisBc = async selectDevisBc => {
    setchangedDevis(changedDevis+1)
      var devis_bc_id = selectDevisBc.label
   
    setSelectDevisBc(selectDevisBc)
    // Verifier si DEVIS ou BC
    let num = selectDevisBc.label.substring(0, 3)
    // **DEVIS** //
  //  if ("D22" == num) {
      const res = await API.post("bon/livraison/client/get_client_by_devis_or_commande", {
        numero: devis_bc_id,
      }).then(res => {
        setSelectCodeClient(res.data.Client.client)
        setRaisonSocial(res.data.Client.raisonSocial)
        setAdresse(res.data.Client.adresseLivraison)
        setSelectFamilleClient(res.data.Client.famille_client)
        setEtatFodec(res.data.Client.fodec)
        setCodeTva(res.data.Client.codeTVA)
        setSelectRepre(res.data.Client.Representant)
        setEtatRegimeForf(res.data.Client.regimeForfutaire)
        setSelectModeRegl(res.data.Client.ModeReglement)
        setDateDevisBc(res.data.Client.date_commande)
        
      })
      const resD = await API.post("bon/livraison/client/get_ligne_by_devis_or_commande", {
        numero: devis_bc_id,
        
      }).then(resD => {
       // setDateDevisBc(new Date(resD.data.Article.date_devis))
        setArrayProduit(resD.data.Article.ligne)
        setTotalFodec(resD.data.Article.total_fodec)
        setTotalHT(resD.data.Article.total_ht)
        setTotalRemise(resD.data.Article.total_remise)
        setTotalTTC(resD.data.Article.total_ttc)
        setTotalTva(resD.data.Article.total_tva)
      })
   // }
    // **BC** //
  /*  else if ("C22" == num) {
      const res = await API.post("bon/comande/client/get_client_by_commande", {
        id: devis_bc_id,
      }).then(res => {
        setSelectCodeClient(res.data.Client.client)
        setRaisonSocial(res.data.Client.raisonSocial)
        setAdresse(res.data.Client.adresseLivraison)
        setSelectFamilleClient(res.data.Client.famille_client)
        setEtatFodec(res.data.Client.fodec)
        setCodeTva(res.data.Client.codeTVA)
        setSelectRepre(res.data.Client.Representant)
        setEtatRegimeForf(res.data.Client.regimeForfutaire)
        setSelectModeRegl(res.data.Client.ModeReglement)
      })
      const resD = await API.post("bon/comande/client/get_ligne_by_commande", {
        id: devis_bc_id,
      }).then(resD => {
        setDateDevisBc(new Date(resD.data.Article.date_cmd))
        setArrayProduit(resD.data.Article.ligne)
        setTotalFodec(resD.data.Article.total_fodec)
        setTotalHT(resD.data.Article.total_ht)
        setTotalRemise(resD.data.Article.total_remise)
        setTotalTTC(resD.data.Article.total_ttc)
        setTotalTva(resD.data.Article.total_tva)
      })
    }*/
  }

  const getProduits = async (selectProduit, indexOfProduit) => {
    if (selectProduit != null) {
      var produit_id = selectProduit.value
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
      date:dateBonSortie
    })
      .then(res => {
        setDesignation(res.data.Article.designation)
        setPrixHT(res.data.Article.prix_achat_ht)
        setTva(res.data.Article.tva)
        setFodec(res.data.Article.fodec)
        setSelectProduit(selectProduit)
        const rem=res.data.Article.remise
        return rem 
      })
      .then(rem => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                  produit_id: produit,
                  designation: designation,
                  prix_ht: prixHT,
                  tva: tva,
                  fodec: fodec,
                  remise:rem
                })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC(total_ttc)
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
  }

  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
              produit_id: produit,
              designation: designation,
              prix_ht: prixHT,
              tva: tva,
              fodec: fodec,
            })
          : el
      )
    )
  }, [selectProduit])

  const addQuantiteGrt = (e, indexOfQte) => {
    if (e < 0) {
      toast.error("Veuillez vérifier la quantite gratuite !", {
        containerId: "A",
      })
    } else {
      var qte_gratuit = e
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfQte
            ? Object.assign(el, {
                qte_gratuit: qte_gratuit,
              })
            : el
        )
      )
    }
  }

  const addQuantite = (e, indexOfQte) => {
    if (e < 1) {
      toast.error("Veuillez vérifier la quantite !", {
        containerId: "A",
      })
    } else {
      if (selectDevisBc != "") {
        if (parseInt(arrayProduit[indexOfQte].qte_rest) >= parseInt(e)) {
          setQuantite(e)
          var qte = e
          var prixHT = arrayProduit[indexOfQte].prix_ht
          var tva = arrayProduit[indexOfQte].tva.label
          var fodec = arrayProduit[indexOfQte].fodec
          var remise = arrayProduit[indexOfQte].remise
          var montantHT = prixHT * qte
          // calcule total HT avec remise
          var montantRemise = montantHT - (montantHT * remise) / 100
          // calcule total remise
          var totalRemise = montantHT - montantRemise
          // calcule fodec
          if (etatFodec == 1 && fodec == 1) {
            var montantFodec = (montantRemise * 1) / 100
          } else if (etatFodec == 0 || fodec == 0) {
            var montantFodec = 0
          }
          // calcule TTC
          var montantHT_fodec = montantRemise + montantFodec
          var montantTTC =
            (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
          setArrayProduit(
            arrayProduit.map((el, id) =>
              id === indexOfQte
                ? Object.assign(el, {
                    qte: qte,
                    montant_ht: parseFloat(montantRemise).toFixed(3),
                    montant_remise: parseFloat(totalRemise).toFixed(3),
                    montant_ttc: parseFloat(montantTTC).toFixed(3),
                    montant_fodec: parseFloat(montantFodec).toFixed(3),
                  })
                : el
            )
          )
        } else {
          toast.error(
            "Ne pas depasser la quantité de " +
              parseInt(arrayProduit[indexOfQte].qte_rest),
            {
              containerId: "A",
            }
          )
        }
      } else {
        setQuantite(e)
        var qte = e
        var prixHT = arrayProduit[indexOfQte].prix_ht
        var tva = arrayProduit[indexOfQte].tva.label
        var fodec = arrayProduit[indexOfQte].fodec
        var remise = arrayProduit[indexOfQte].remise
        var montantHT = prixHT * qte
        // calcule total HT avec remise
        var montantRemise = montantHT - (montantHT * remise) / 100
        // calcule total remise
        var totalRemise = montantHT - montantRemise
        // calcule fodec
        if (etatFodec == 1 && fodec == 1) {
          var montantFodec = (montantRemise * 1) / 100
        } else if (etatFodec == 0 || fodec == 0) {
          var montantFodec = 0
        }
        // calcule TTC
        var montantHT_fodec = montantRemise + montantFodec
        var montantTTC =
          (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfQte
              ? Object.assign(el, {
                  qte: qte,
                  montant_ht: parseFloat(montantRemise).toFixed(3),
                  montant_remise: parseFloat(totalRemise).toFixed(3),
                  montant_ttc: parseFloat(montantTTC).toFixed(3),
                  montant_fodec: parseFloat(montantFodec).toFixed(3),
                })
              : el
          )
        )
      }
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite])

  const addRemise = (e, indexOfRemise) => {
    if (e > 100 || e < 0) {
      toast.error("Veuillez vérifier la remise !", {
        containerId: "A",
      })
    } else {
      setRemise(e)
      var remise = e
      var qte = arrayProduit[indexOfRemise].qte
      var prixHT = arrayProduit[indexOfRemise].prix_ht
      var fodec = arrayProduit[indexOfRemise].fodec
      var tva = arrayProduit[indexOfRemise].tva.label
      // calcule HT avec remise
      var montantHT = prixHT * qte
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if (etatFodec == 1 && fodec == 1) {
        var montantFodec = (montantRemise * 1) / 100
      } else if (etatFodec == 0 || fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
                montant_ht: parseFloat(montantRemise).toFixed(3),
                remise: remise,
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
            : el
        )
      )
    }
  }

  useEffect(() => {
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(total_ttc)
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise])

  const save = async () => {
    setButtonClicked(true);

    let count = 0
    if (selectCodeClient != "") {
      if(selectModeRegl.label != ""){
     
        if (arrayProduit.length > 0) {
          for (let index = 0; index < arrayProduit.length; index++) {
            /*var prod = arrayProduit[index].produit_id.value
            var nameProd = arrayProduit[index].designation*/
            var qte = arrayProduit[index].qte
            if (qte <= 0) {
              count++
            }
            var depot =arrayProduit[index].depot_id
             if(depot === undefined){
           
              var selection_depot = 1;
            }
          
            if (selection_depot === 1) {
            toast.error("⛔ Selectionner un depot SVP", {
              containerId: "A",
            })

            setButtonClicked(false);

          
            }
           else if (index == arrayProduit.length - 1) {
              if (count == 0) {
               /* if (dateBonSortie != "") {
                  let date = dateBonSortie
                  let month = "" + (date.getMonth() + 1)
                  let day = "" + date.getDate()
                  let year = date.getFullYear()
                  if (month.length < 2) month = "0" + month
                  if (day.length < 2) day = "0" + day
                  var convertDateBonSortie = [year, month, day].join("-")
                } else {
                  var convertDateBonSortie = ""
                }
                let dateDBC = dateDevisBc
                let monthDBC = "" + (dateDBC.getMonth() + 1)
                let dayDBC = "" + dateDBC.getDate()
                let yearDBC = dateDBC.getFullYear()
                if (monthDBC.length < 2) monthDBC = "0" + monthDBC
                if (dayDBC.length < 2) dayDBC = "0" + dayDBC
                var convertDateDBC = [yearDBC, monthDBC, dayDBC].join("-")
                // Verifier si DEVIS ou BC
               /* if (selectDevisBc != "") {
                  var num = selectDevisBc.label.substring(0, 3)
                } else {
                  var num = null
                }*/
                // verifier la qte de chaque article
              
                    const res = API.post("bon/sortie/add", {
                      annee: annee,
                      user_id,
                      // information
                      num_bon_sortie: numBonSortie,
                      date_bon_sortie: dateBonSortie,
                      devis_bc_id: selectDevisBc.value,
                      devis_bc_label: selectDevisBc.label,
                     // type_num: num,
                      //date_devis_bc: convertDateDBC,
                      client_id: selectCodeClient.value,
                      raison_social: raisonSocial,
                      adresse: adresse,
                      famille_id: selectFamilleClient.value,
                      fodec: etatFodec,
                      code_tva: codeTva,
                      remise_excep: remiseExcep,
                      representant_id: selectRepre.value,
                      regime_forf: etatRegimeForf,
                      depot_id: selectDepot.value,
                      mode_regl_id: selectModeRegl.value,
                      // array
                      array: arrayProduit,
                      // information
                      observation: observation,
                      tot_remise: totalRemise,
                      tot_ht: totalHT,
                      tot_fodec: totalFodec,
                      tot_tva: totalTva,
                      avance_impot: "",
                      tot_ttc: totalTTC,
                    }).then(res => {
                    
                      return res.json();
                    })
                    .then(data => {
                    })
                    .catch(error => {
                      if (error.response && error.response.data && error.response.data.error) {
                        console.error('Erreur1:', error.response.data.error);
                        toast.error(error.response.data.error, {
                          containerId: "A",
                        })
                        setButtonClicked(false);

                      } else {
                        props.setSection(1);
                      }
                    });
                    
                 
                
                //
              } else {
                toast.error("La quantité doit étre supérieur a 0", {
                  containerId: "A",
                })
                setButtonClicked(false);

              }
            }
          }
        } else {
          toast.error("Veuillez choisir au moins un article", {
            containerId: "A",
          })
          setButtonClicked(false);

        }

      } else {
        toast.error("Veuillez selectionner une mode de reglement ", {
          containerId: "A",
        })
        setButtonClicked(false);

      }
     
      
    } else {
      toast.error("Veuillez selectionner un client", {
        containerId: "A",
      })
      setButtonClicked(false);

    }
  }

  useEffect (async() =>{
    const res =await API.post ("bon/sortie/change_date",{
      date:dateBonSortie,
      arrayProduit:arrayProduit
    }).then(res => {
     if (res.data[0].no_change === 0){
      setArrayProduit(res.data[1])}
      setProduits(res.data[0].articles)

    }) 


   }, [dateBonSortie,changedDevis])


  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Form>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    N° bon de sortie
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    disabled
                    value={numBonSortie}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Date de sortie</Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateBonSortie}
                      onChange={e => setDateBonSortie(e)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Devis/BC</Label>
                  <Select
                    options={devisBc}
                    isSearchable={true}
                    onChange={e => getClientDevisBc(e)}
                    formatGroupLabel={formatGroupLabel}
                    value={selectDevisBc}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Date Devis/BC</Label>
                  <Col md={12} className="pr-0">
                    <Input
                      type="date"
                      value={dateDevisBc}
                      className="form-control "
                      disabled
                    />
                  </Col>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Client
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <Select
                    isSearchable={true}
                    onChange={e => getDevisBcClient(e)}
                    value={selectCodeClient}
                    options={client}
                    //isDisabled={selectDevisBc != "" ? true : false}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Raison sociale</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Raison sociale"
                    value={raisonSocial}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Adresse</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={adresse}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Famille</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Famille"
                    value={selectFamilleClient.label}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatFodec == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Fodec
                    </Label>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Code TVA</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Code TVA"
                    value={codeTva}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Representant</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Representant"
                    value={selectRepre.label}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatRegimeForf == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Régime forfaitaire
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Mode reglement</Label>
                  <Select
                    options={modeRegl}
                    isSearchable={true}
                    onChange={e => setSelectModeRegl(e)}
                    value={selectModeRegl}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <CardTitle className="h4 mt-4">Details</CardTitle>
          <div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Article
                  </th>
            
                  <th style={{ textAlign: "center" }} scope="col">
                    Fodec
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Qte
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Gratuit
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    P.U.H.T
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Remise
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant HT
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    TVA
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant TTC
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Depot
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Supp
                  </th>
                </tr>
              </thead>
              {arrayProduit.map((el, index) => (
                <tbody key={index}>
                  <tr>
                    <td style={{ textAlign: "center", width: "13%" }}>
                      <Select
                        options={produits}
                        isSearchable={true}
                        value={el.produit_id}
                        onChange={e => getProduits(e, index)}
                      />
                    </td>
                    {/*<td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        value={el.designation}
                      />
                    </td>*/}
                    <td style={{ textAlign: "center", width: "1%" }}>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="horizontal-customCheck"
                        checked={el.fodec == 1}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte}
                        min={0}
                        onChange={e => addQuantite(e.target.value, index)}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte_gratuit}
                        min={0}
                        onChange={e => addQuantiteGrt(e.target.value, index)}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.prix_ht}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.remise}
                        min={0}
                        max={100}
                        onChange={e => addRemise(e.target.value, index)}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "11%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ht}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "7%" }}>
                      <Input value={el.tva.label} disabled />
                    </td>
                    <td style={{ textAlign: "center", width: "12%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ttc}
                      />
                    </td>
                    <td style={{ textAlign: "center", width: "12%" }}>
                    <Select
                        options={el.depots}
                        isSearchable={true}
                        onChange={e => select_articleDepot(e, index)}
                      //onChange={e => setSelectDepot(e)}
                      value={el.product_selected_depot}
                      /></td>
                    <td style={{ textAlign: "center", width: "1%" }}>
                      <i
                        style={{ color: "#cc0000" }}
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => toggle(index)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Total </CardTitle>
              <Row>
                <Col className="mt-3" md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Observation</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Observation"
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Total des remises :</td>
                      <td>{totalRemise}</td>
                    </tr>
                    <tr>
                      <td>Total HT :</td>
                      <td>{totalHT}</td>
                    </tr>
                    <tr>
                      <td>Total fodec :</td>
                      <td>{totalFodec}</td>
                    </tr>
                    <tr>
                      <td>Total TVA :</td>
                      <td>{totalTva}</td>
                    </tr>
                    <tr>
                      <td>Avance/Impot :</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Total TTC : </td>
                      <td>{totalTTC}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={buttonClicked}
                  onClick={save}
                >
                  Confirmer
                </button>
              </div>
            </Col>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          Suppression ligne
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddBonSortieClient
AddBonSortieClient.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
