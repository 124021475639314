/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import Select from "react-select"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Spinner,
} from "reactstrap"

const DetailProduit = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [id, setId] = useState("")
  const [selectMarque, setSelectMarque] = useState("")
  const [selectGroupe, setSelectGroupe] = useState("")
  const [selectUnite, setSelectUnite] = useState("")
  const [selectEmplacement, setSelectEmplacement] = useState("")
  const [selectFournisseur, setSelectFournisseur] = useState("")
  const [selectFamille, setSelectFamille] = useState("")
  const [selectType, setSelectType] = useState("")
  const [code, setCode] = useState("")
  const [actif, setActif] = useState("")
  const [homologue, setHomologue] = useState("")
  const [stockable, setStockable] = useState("")
  const [fodec, setFodec] = useState("")
  const [designation, setDesignation] = useState("")
  const [quantite, setQuantite] = useState("")
  const [quantiteDepart, setQuantiteDepart] = useState("")
  const [QuantiteMinimal, setQuantiteMinimal] = useState("")
  const [QuantiteMaximal, setQuantiteMaximal] = useState("")
  const [colisage, setColisage] = useState("")
  const [poids, setPoids] = useState("")
  const [longueur, setLongueur] = useState("")
  const [largeur, setLargeur] = useState("")
  const [hauteur, setHauteur] = useState("")
  const [volume, setVolume] = useState("")
  const [prixPondere, setPrixPondere] = useState("")
  const [prixAchat, setPrixAchat] = useState("")
  const [remiseAchat, setRemiseAchat] = useState("")
  const [prixRevient, setPrixRevient] = useState("")
  const [marge, setMarge] = useState("")
  const [prixVente, setPrixVente] = useState("")
  const [selectTva, setSelectTva] = useState("")
  const [prixVenteTTC, setPrixVenteTTC] = useState("")
  const [observation, setObservation] = useState("")
  const [loading, setLoading] = useState(false)
  //image
  const [imageSrc, setImageSrc] = useState(null);


  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("article/get_by_id", { id }).then(res => {
      setSelectMarque(res.data.Article.marque)
      setSelectGroupe(res.data.Article.group)
      setSelectUnite(res.data.Article.unite)
      setSelectEmplacement(res.data.Article.emplacement)
      setSelectFamille(res.data.Article.famille)
      setSelectType(res.data.Article.type_article)
      setSelectFournisseur(res.data.Article.fournisseur)
      setCode(res.data.Article.code)
      setActif(res.data.Article.actif)
      setHomologue(res.data.Article.homologue)
      setStockable(res.data.Article.stockable)
      setFodec(res.data.Article.fodec)
      setDesignation(res.data.Article.designation)
      setQuantite(res.data.Article.quantite)
      setQuantiteDepart(res.data.Article.quantite_depart)
      setQuantiteMinimal(res.data.Article.quantite_min)
      setQuantiteMaximal(res.data.Article.quantite_max)
      setColisage(res.data.Article.colisage)
      setPoids(res.data.Article.poids)
      setLongueur(res.data.Article.longueur)
      setLargeur(res.data.Article.largeur)
      setHauteur(res.data.Article.hauteur)
      setVolume(res.data.Article.volume)
      setPrixPondere(res.data.Article.prix_moyen)
      setPrixAchat(res.data.Article.prix_achat_ht)
      setRemiseAchat(res.data.Article.remise_achat)
      setPrixRevient(res.data.Article.prix_revient)
      setMarge(res.data.Article.marge)
      setPrixVente(res.data.Article.prix_vente_ht)
      setSelectTva(res.data.Article.tva)
      setPrixVenteTTC(res.data.Article.prix_vente_ttc)
      setObservation(res.data.Article.observation)
      setLoading(true)
    })

    //image 
    try {
      const res1 = await API.post("article/return_image", { id });
      setImageSrc(res1.data.image)
    } catch (error) {
      console.error(error);
    }


  }, []);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <AvForm>
                    <Form>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <h1 style={{color: 'black'}}>{designation}</h1>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {imageSrc && <img src={`data:image/png;base64, ${imageSrc}`} alt="Image" style={{ width: '500px', height: '350px', borderRadius: '10%' }} />}
                        </div>

                        <Col>
                          <Card className="overflow-hidden">
                            <CardBody>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Informations :
                              </CardTitle>
                              <Row>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input4">
                                      Code
                                    </Label>
                                    <AvField
                                      name="code"
                                      placeholder="code"
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={code}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input6">
                                      Marque
                                    </Label>
                                    <Select
                                      value={selectMarque}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Groupe
                                    </Label>
                                    <Select
                                      value={selectGroupe}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Unité
                                    </Label>
                                    <Select
                                      value={selectUnite}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input5">
                                      Emplacement
                                    </Label>
                                    <Select
                                      value={selectEmplacement}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input5">
                                      Fournisseur
                                    </Label>
                                    <Select
                                      value={selectFournisseur}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Famille
                                    </Label>
                                    <Select
                                      value={selectFamille}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Type
                                    </Label>
                                    <Select
                                      value={selectType}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Désignation
                                    </Label>
                                    <AvField
                                      name="designation"
                                      value={designation}
                                      disabled
                                      placeholder="Désignation"
                                      type="text"
                                      errorMessage="* Désignation obligatoire"
                                    />
                                  </div>
                                </Col>{" "}
              </Row>*/}
                              <Row>
                                <Col md="6">
                                  <div className="mb-3 mt-3">
                                    <div className="form-check form-check-inline">
                                      <Input
                                        type="checkbox"
                                        className="form-check-Input"
                                        id="horizontal-customCheck"
                                        checked={actif == 1}
                                        disabled
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="horizontal-customCheck"
                                      >
                                        Actif
                                      </Label>
                                    </div>
                                    &nbsp;
                                    <div className="form-check form-check-inline">
                                      <Input
                                        type="checkbox"
                                        className="form-check-Input"
                                        id="horizontal-customCheck"
                                        checked={homologue == 1}
                                        disabled
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="horizontal-customCheck"
                                      >
                                        Homologué
                                      </Label>
                                    </div>
                                    &nbsp;
                                    <div className="form-check form-check-inline">
                                      <Input
                                        type="checkbox"
                                        className="form-check-Input"
                                        id="horizontal-customCheck"
                                        checked={stockable == 1}
                                        disabled
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="horizontal-customCheck"
                                      >
                                        Stockable
                                      </Label>
                                    </div>
                                    &nbsp;
                                    <div className="form-check form-check-inline">
                                      <Input
                                        type="checkbox"
                                        className="form-check-Input"
                                        id="horizontal-customCheck"
                                        checked={fodec == 1}
                                        disabled
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="horizontal-customCheck"
                                      >
                                        Fodec
                                      </Label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="overflow-hidden">
                            <CardBody>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Stock :
                              </CardTitle>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Quantité
                                    </Label>
                                    <AvField
                                      name="quantité"
                                      disabled
                                      value={quantite}
                                      placeholder="Quantité"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Quantité départ
                                    </Label>
                                    <AvField
                                      name="quantitedepart"
                                      disabled
                                      value={quantiteDepart}
                                      placeholder="Quantité départ"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Quantité minimale
                                    </Label>
                                    <AvField
                                      name="quantiteminimale"
                                      disabled
                                      value={QuantiteMinimal}
                                      placeholder="Quantité minimale"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Quantité maximale
                                    </Label>
                                    <AvField
                                      name="quantitemaximale"
                                      disabled
                                      value={QuantiteMaximal}
                                      placeholder="Quantité maximale"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Colisage
                                    </Label>
                                    <AvField
                                      name="colisage"
                                      disabled
                                      value={colisage}
                                      placeholder="Colisage"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="overflow-hidden">
                            <CardBody>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Caractéristique :
                              </CardTitle>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Poids
                                    </Label>
                                    <AvField
                                      name="poids"
                                      disabled
                                      value={poids}
                                      placeholder="Poids"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Longueur
                                    </Label>
                                    <AvField
                                      name="longueur"
                                      disabled
                                      value={longueur}
                                      placeholder="Longueur"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Largeur
                                    </Label>
                                    <AvField
                                      name="largeur"
                                      disabled
                                      value={largeur}
                                      placeholder="Largeur"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Hauteur
                                    </Label>
                                    <AvField
                                      name="hauteur"
                                      disabled
                                      value={hauteur}
                                      placeholder="Hauteur"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Volume
                                    </Label>
                                    <AvField
                                      name="volume"
                                      disabled
                                      value={volume}
                                      placeholder="Volume"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card className="overflow-hidden">
                            <CardBody>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Prix :
                              </CardTitle>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Prix moyen pondéré
                                    </Label>
                                    <AvField
                                      name="prixMoyen"
                                      disabled
                                      value={prixPondere}
                                      placeholder="Prix moyen pondéré"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Prix achat HT
                                    </Label>
                                    <AvField
                                      name="prixAchat"
                                      disabled
                                      value={prixAchat}
                                      placeholder="Prix achat HT"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Remise achat
                                    </Label>
                                    <AvField
                                      name="remise"
                                      disabled
                                      value={remiseAchat}
                                      placeholder="Remise achat"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Prix de revient
                                    </Label>
                                    <AvField
                                      name="prixRevient"
                                      disabled
                                      value={prixRevient}
                                      placeholder="Prix de revient"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Marge
                                    </Label>
                                    <AvField
                                      name="marge"
                                      disabled
                                      value={marge}
                                      placeholder="Marge"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Prix de vente HT
                                    </Label>
                                    <AvField
                                      name="prixVente"
                                      disabled
                                      value={prixVente}
                                      placeholder="Prix de vente HT"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      TVA
                                    </Label>
                                    <Select
                                      value={selectTva}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Prix de vente TTC
                                    </Label>
                                    <AvField
                                      name="prixVenteTTC"
                                      disabled
                                      value={prixVenteTTC}
                                      placeholder="Prix de vente TTC"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Card>
                          <CardBody>
                            <Nav className="icon-tab nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === "1",
                                  })}
                                  onClick={() => {
                                    setactiveTab("1")
                                  }}
                                >
                                  <span className="d-none d-sm-block">
                                    <i className="fas fa-money-bill-alt"></i>{" "}
                                    Remise selon modalité de paiement
                                  </span>
                                  <span className="d-block d-sm-none">
                                    <i className="fas fa-money-bill-alt"></i>
                                  </span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: activeTab === "2",
                                  })}
                                  onClick={() => {
                                    setactiveTab("2")
                                  }}
                                >
                                  <span className="d-none d-sm-block">
                                    <i className="fas fa-eye"></i> Observation
                                  </span>
                                  <span className="d-block d-sm-none">
                                    <i className="fas fa-eye"></i>
                                  </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={activeTab}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="1"></TabPane>
                              <TabPane tabId="2">
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input12">
                                        Observation
                                      </Label>
                                      <AvField
                                        name="Observation"
                                        placeholder="Observation"
                                        type="textarea"
                                        value={observation}
                                        className="form-control"
                                        disabled
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div className="text-center mt-4">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Produits")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </AvForm>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>

      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default DetailProduit
DetailProduit.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  history: PropTypes.object,
}
