import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Label,
  CardTitle,
  Form,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ReactEcharts from 'echarts-for-react';

import Select from "react-select"


const StatRegFour = () => {
  const [etatFacturationValues, setEtatFacturationValues] = useState([]);
  const [etatFacturationValuesBanq, setEtatFacturationValuesBanq ]= useState([]);
  const [selectClient, setSelectClient] = useState("")
    const [client, setClient] = useState([])
    const [selectFournisseur, setSelectFournisseur] = useState("")
    const [fournisseur, setFournisseur] = useState([])
    const [selectBanque, setSelectBanque] = useState("")
    const [banque, setBanque] = useState([])
    const [blockList, setBlockList] = useState(false)
    const [listbanque, setListBanque] = useState([])
    const [Statbanque, setStatBanque] = useState([])
    const [sommeTotalFor, setSommeTotalFor] = useState("")
    const [sommeTotalBanq, setSommeTotalBanq] = useState("")
//setEtatFacturationValuesFour
const [etatFacturationValuesFour, setEtatFacturationValuesFour ]= useState([]);

    useEffect(async () => {
        const resN = await API.post("reglement/fournisseur/statistiqueBancaire").then(resN => {
         setListBanque(resN.data.retour.array_code)
         setStatBanque(resN.data.retour.array_banque)
        })
           const res = await API.get("fournisseur/select").then(res => {
          setFournisseur(res.data.Fournisseur)
        })
        const resB = await API.get("banque/matricule/select").then(resB => {
          setBanque(resB.data.MatriculeBancaire)
        })


        
      }, [])
      
//   useEffect(() => {
//       async function fetchData() {
//           try {
//               const response = await API.get('reglement/fournisseur/statistiqueFournisseur');
//               const data = response.data.retour;
//               setEtatFacturationValues(data);
//           } catch (error) {
//               console.error('Error fetching data:', error);
//           }
//       }

//       fetchData();
//       // async function fetchDatafact() {
//       //     try {
//       //         const response = await API.post('reglement/fournisseur/get_stat');
//       //         const data = response.data['ReglementFrs'].etat_facturation[0];
//       //         setEtatFacturationValuesFact({
//       //             totalFacturefrs: parseFloat(data.totalFacturefrs),
//       //             FacturePaye: parseFloat(data.FacturePaye),
//       //             FactureNonPay: parseFloat(data.FactureNonPay),
//       //         });
//       //     } catch (error) {
//       //         console.error('Error fetching data:', error);
//       //     }
//       // }

//       // fetchDatafact();
//   }, []);


// const search = async () => {
//     if (selectFournisseur != null && selectBanque!=null) {
//       var fournisseur_id = selectFournisseur.value
//       var Banque_id = selectBanque.value
//       const res = await API.post("reglement/fournisseur/statistiqueFournisseur", {
//         fournisseur_id: fournisseur_id,
//         matricule_bancaire_id:Banque_id
  
//       }).then(res => {
        
//         setBlockList(true)
//         console.log(res)
//         setEtatFacturationValues(res.data.retour.reglements);
//        setEtatFacturationValuesBanq(res.data.retour.piece[0]);
//       })
//     }
//   }
//   const getOption = () => {
//       const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

//       const monthData = months.map((monthName, index) => {
//           const matchingData = etatFacturationValues.find(item => item.month === index + 1); // Adjust month index
//           const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;

//           return {
//               name: monthName,
//               value: value,
//           };
//       });

//       return {
//           grid: {
//               zlevel: 0,
//               x: 50,
//               x2: 50,
//               y: 30,
//               y2: 30,
//               borderWidth: 0,
//               backgroundColor: "rgba(0,0,0,0)",
//               borderColor: "rgba(0,0,0,0)",
//           },
//           tooltip: {
//               trigger: "axis",
//               axisPointer: {
//                   type: "cross",
//                   crossStyle: {
//                       color: "#999",
//                   },
//               },
//           },
//           toolbox: {
//               feature: {
//                   dataView: { show: true, readOnly: false },
//                   magicType: { show: true, type: ["line", "bar"] },
//                   restore: { show: true },
//                   saveAsImage: { show: true },
//               },
//           },
//           color: ["#3c4ccf", "#02a499", "#38a4f8"],
//           legend: {
//               data: ["Total Montant"],
//               textStyle: {
//                   color: ["#74788d"],
//               },
//           },
//           xAxis: [
//               {
//                   type: "category",
//                   data: months, // Use the month names as categories
//                   axisPointer: {
//                       type: "shadow",
//                   },
//                   axisLine: {
//                       lineStyle: {
//                           color: "#74788d",
//                       },
//                   },
//               },
//           ],
//           yAxis: [
//               {
//                   type: "value",
//                   name: "Montant",
//                   axisLabel: {
//                       formatter: "{value}",
//                   },
//                   axisLine: {
//                       lineStyle: {
//                           color: "#74788d",
//                       },
//                   },
//               },
//           ],
//           series: [
//               {
//                   name: "Total Montant",
//                   type: "bar",
//                   data: monthData, // Use the month data here
//               },
//           ],
//           textStyle: {
//               color: ["#74788d"],
//           },
//       };
//   };



const getOption = () => {
    const months = listbanque;

    const monthData = months.map((code) => {
        const matchingBanque = Statbanque.find((banqueData) => banqueData.banque.code === code);
        const value = matchingBanque ? parseFloat(matchingBanque.piecesByType.length > 0 ? matchingBanque.piecesByType[0].totalMontant : 0) : 0;
        
        return {
            name: code,
            value: value,
        };
    });
 

    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3c4ccf", "#02a499", "#38a4f8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788d"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788d"],
        },
    };
};


const searchBanque = async selectBanque => {
    if (selectBanque != null) {
      setSelectBanque(selectBanque)
      var Banque_id = selectBanque.value
      const res = await API.post("reglement/fournisseur/statistiqueFournisseur", {
                
                matricule_bancaire_id:Banque_id
          
              }).then(res => {
                
              
          
               setEtatFacturationValuesBanq(res.data.retour.piecesByType);
               setSommeTotalBanq(res.data.retour.totalMontantTotal)
              })
    } else {
        setSelectBanque("")
    }
  }

  const searchFournisseur = async selectFournisseur => {
    if (selectFournisseur != null) {
      setSelectFournisseur(selectFournisseur)
      var fournisseur_id = selectFournisseur.value
      //stat_glob_for
      const res = await API.post("reglement/fournisseur/stat_glob_for", {
                
        fournisseur_id:fournisseur_id
          
              }).then(res => {
                
              
          
               setEtatFacturationValuesFour(res.data.retour.piecesByType);
               setSommeTotalFor(res.data.retour.totalMontantTotal)

              })
    } else {
        setSelectBanque("")
    }
  }
  const getOptionFour = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const monthData = months.map((monthName, index) => {
        const matchingData = etatFacturationValuesFour.find(item => item.month === index + 1); // Adjust month index
        const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;
        return {
            name: monthName,
            value: value,
        };
    });
    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3C4CCF", "#02A499", "#38A4F8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788D"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788D"],
        },
    };
};
  const getOptionBanq = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const monthData = months.map((monthName, index) => {
        const matchingData = etatFacturationValuesBanq.find(item => item.month === index + 1); // Adjust month index
        const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;
        return {
            name: monthName,
            value: value,
        };
    });
    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3C4CCF", "#02A499", "#38A4F8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788D"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788D"],
        },
    };
};

  return (
      <React.Fragment>
      
     <p>chiffre daffaire:Banques</p>
<ReactEcharts style={{ height: "350px" }} option={getOption()} /> 
<Form className="mt-4">
          <Row>
            {/* <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Client</Label>
                <Select
                options={client}
                isSearchable={true}
                onChange={e => setSelectClient(e)}
              />
              </div>
            </Col> */}
          {/* <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Fournisseur</Label>
                <Select
                options={fournisseur}
                isSearchable={true}
                onChange={e => setSelectFournisseur(e)}
              />
              </div>
            </Col> */}
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">banque</Label>
                <Select
                options={banque}
                isSearchable={true}
                onChange={e => searchBanque(e)}              />
              </div>
            </Col>
          </Row>
          <p>chiffre daffaire par banque</p>
          <ReactEcharts style={{ height: "350px" }} option={getOptionBanq()} />
          <div style={{ display: "flex" }}>
  <span style={{ marginRight: "10px" }}>Somme :</span>
  <input
    type="number"
    value={sommeTotalBanq}
    // onBlur={() => handleBlur(index)}
    // onChange={e => addMontant(e, index)}
    style={{
      textAlign: "center",
      borderRadius: "5px",
      border: "solid 1px #B3B3B3",
    }}
    disabled
  />
</div>
 <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Fournisseur</Label>
                <Select
                options={fournisseur}
                isSearchable={true}
                onChange={e => searchFournisseur(e)}  
              />
              </div>
            </Col>
          <p>chiffre daffaire par fournisseur</p>
<ReactEcharts style={{ height: "350px" }} option={getOptionFour()} />
<div style={{ display: "flex" }}>
  <span style={{ marginRight: "10px" }}>Somme :</span>
  <input
    type="number"
    value={sommeTotalFor}
    // onBlur={() => handleBlur(index)}
    // onChange={e => addMontant(e, index)}
    style={{
      textAlign: "center",
      borderRadius: "5px",
      border: "solid 1px #B3B3B3",
    }}
    disabled
  />
</div>
          </Form>
          

        
        <div>
{/*          
        <ReactEcharts style={{ height: "350px" }} option={getOption()} />
<ReactEcharts style={{ height: "350px" }} option={getOptionBanq()} /> */}
        </div>
      </React.Fragment>
  );

}

export default StatRegFour