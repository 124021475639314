import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Table,
  Card,
  CardBody,
  CardTitle,
  Container,
  Spinner,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const DetailFactureLivraison = props => {
  const [numFacture, setNumFacture] = useState("")
  const [dateFacture, setDateFacture] = useState(new Date())
  const [selectFournisseur, setSelectFournisseur] = useState("")
  const [adresse, setAdresse] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [codeTva, setCodeTva] = useState("")
  const [etatFodec, setEtatFodec] = useState("")
  const [reference, setReference] = useState("")
  const [dateReference, setDateReference] = useState(new Date())
  const [montantRef, setMontantRef] = useState("")
  const [arrayProduit, setArrayProduit] = useState([])
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("fournisseur/facture/get_by_id_liv", {
      id,
    }).then(res => {
      setNumFacture(res.data.FactureFournisseur.num_fact)
      setDateFacture(new Date(res.data.FactureFournisseur.date))
      setSelectFournisseur(res.data.FactureFournisseur.fournisseur_id)
      setAdresse(res.data.FactureFournisseur.adresseFournisseur)
      setRaisonSocial(res.data.FactureFournisseur.raisonSocial)
      setCodeTva(res.data.FactureFournisseur.codeTVA)
      setEtatFodec(res.data.FactureFournisseur.fodec)
      setReference(res.data.FactureFournisseur.ref_fournisseur)
      setMontantRef(res.data.FactureFournisseur.total_ref_fournisseur)
      setDateReference(
        new Date(res.data.FactureFournisseur.date_ref_fournisseur)
      )
      setTotalHT(res.data.FactureFournisseur.total_ht)
      setTotalTTC(res.data.FactureFournisseur.total_ttc)
      setTotalTva(res.data.FactureFournisseur.total_tva)
      setTotalRemise(res.data.FactureFournisseur.total_remise)
      setTotalFodec(res.data.FactureFournisseur.total_fodec)
      setArrayProduit(res.data.FactureFournisseur.ligne)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            N° facture
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            disabled
                            value={numFacture}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Date facture
                          </Label>
                          <Col md={12} className="pr-0">
                            <DatePicker
                              className="form-control ddate"
                              selected={dateFacture}
                              disabled
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Fournisseur
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            isSearchable={true}
                            disabled
                            value={selectFournisseur}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">Adresse</Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Adresse"
                            disabled
                            value={adresse}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            Raison sociale
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Raison sociale"
                            disabled
                            value={raisonSocial}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {" "}
                            Code TVA{" "}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Code tva"
                            disabled
                            value={codeTva}
                          />
                        </div>
                      </Col>
                      <Col className="mt-3" md={3}>
                        <div className="mb-3 mt-3">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="horizontal-customCheck"
                              disabled
                              checked={etatFodec == 1}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="horizontal-customCheck"
                            >
                              Fodec
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Référence fournisseur
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Input
                            placeholder="Référence"
                            type="text"
                            disabled
                            className="form-control"
                            value={reference}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Date référence du fournisseur
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Col md={12} className="pr-0">
                            <DatePicker
                              className="form-control ddate"
                              selected={dateReference}
                              disabled
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-arround",
                          }}
                        >
                          <Label for="basicpill-firstname-input1">
                            Montant référence du fournisseur
                          </Label>
                          <Label style={{ color: "red", paddingLeft: "5px" }}>
                            *
                          </Label>
                        </div>
                        <div className="mb-3">
                          <Input
                            placeholder="Montant"
                            type="number"
                            className="form-control"
                            disabled
                            value={montantRef}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Fragment>
                    <div className="row mt-4">
                      <table
                        style={{ marginTop: "10px" }}
                        className="table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Numéro piéce
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Date piéce
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Montant TTC
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Facturation
                            </th>
                          </tr>
                        </thead>
                        {arrayProduit.map((el, index) => (
                          <tbody key={index}>
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {el.numBonLiv}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {el.dateBonLiv}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {el.mntTtc}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <label style={{ paddingRight: "10px" }}>
                                  {" "}
                                  Facturer
                                </label>
                                <Input
                                  type="checkbox"
                                  className="form-check-Input"
                                  checked={el.etat}
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                    <Fragment>
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-3">Total </CardTitle>
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <tbody>
                                <tr>
                                  <td>Total des remises :</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {totalRemise}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total HT :</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {totalHT}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total fodec :</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {totalFodec}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total TVA :</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {totalTva}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Avance/Impot :</td>
                                  <td style={{ fontWeight: "bold" }}>{""}</td>
                                </tr>
                                <tr>
                                  <td>Total TTC : </td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {totalTTC}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col lg="6">
                          <div className="text-center mt-4">
                            <Button
                              type="button"
                              color="warning"
                              className="btn btn-warning  mb-2 me-2"
                              onClick={() =>
                                props.history.push("/FactureLivraison")
                              }
                            >
                              Annuler
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </Fragment>
                  </Fragment>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default DetailFactureLivraison
DetailFactureLivraison.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
