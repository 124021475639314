import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Spinner,
  Input,
  Row,
  Label,
  Form,
  CardTitle,
  Card,
  CardBody,
  Table,
  Container,
  Button
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import LogoC from "../../../assets/images/LOGOerp.png"




const DetailDevisClient = props => {
  const [loading, setLoading] = useState(false)
  const [numDevis, setNumDevis] = useState("")
  const [dateDevis, setDateDevis] = useState(new Date())
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [arrayProduit, setArrayProduit] = useState([])
  const [produits, setProduits] = useState([])
  //
  const [observation, setObservation] = useState([])
  const [totalRemise, setTotalRemise] = useState(0)
  const [totalTva, setTotalTva] = useState("")
  const [totalHT, setTotalHT] = useState(0)
  const [totalFodec, setTotalFodec] = useState(0)
  const [totalTTC, setTotalTTC] = useState(0)

  useEffect(async () => {
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("devi/get_by_id", { id }).then(res => {
      setNumDevis(res.data.DevisClient.num_devi)
      setDateDevis(new Date(res.data.DevisClient.date_devi))
      setSelectCodeClient(res.data.DevisClient.client_id)
      setRaisonSocial(res.data.DevisClient.raisonSocial)
      setAdresse(res.data.DevisClient.adresse)
      setSelectFamilleClient(res.data.DevisClient.famille_client)
      setEtatFodec(res.data.DevisClient.fodec)
      setCodeTva(res.data.DevisClient.code_tva)
      setRemiseExcep(res.data.DevisClient.remise_exeptionelle)
      setSelectRepre(res.data.DevisClient.representant)
      setEtatRegimeForf(res.data.DevisClient.regime_forfaitaire)
      setSelectModeRegl(res.data.DevisClient.mode_reglement)
      setArrayProduit(res.data.DevisClient.ligne)
      setObservation(res.data.DevisClient.observation)
      setTotalRemise(res.data.DevisClient.total_remise)
      setTotalTva(res.data.DevisClient.total_tva)
      setTotalHT(res.data.DevisClient.total_ht)
      setTotalFodec(res.data.DevisClient.total_fodec)
      setTotalTTC(res.data.DevisClient.total_ttc)
      setLoading(true)
    })
  }, [])

  const generatePDF = () => {
    const doc = new jsPDF();
    const margin = 10;
    const lineHeight = 10;

    doc.setDrawColor(0); 
    doc.setLineWidth(0.5);  
    doc.setFillColor(0); 
    doc.rect(10, 10, 190, 30, 'F');
    const logo = LogoC;
    doc.addImage(logo, "PNG", 15, 15, 25, 25); 
    const nomSociete = "CresusERP";
    doc.setFontSize(16);
    doc.text(nomSociete, 50, 25); 
    const offsetY = 50; 
    const yStart = margin + lineHeight * 4; 

    doc.setFontSize(12);
    doc.text(`Numéro de devis : ${numDevis}`, doc.internal.pageSize.width - margin, yStart, { align: 'right' });
    doc.text(`Date de devis : ${dateDevis.toLocaleDateString()}`, margin, yStart);
    doc.setFont("helvetica", "bold");
    doc.text('Client', margin, yStart + lineHeight);
    doc.setFont("helvetica", "normal");
    doc.text(`Raison sociale : ${raisonSocial}`, margin, yStart + lineHeight * 2);
    doc.text(`Adresse : ${adresse}`, margin, yStart + lineHeight * 3);

    const xRepresentant = margin + 150; 
    const yRepresentant = yStart + lineHeight * 3.1; 
    doc.setFont("helvetica", "bold");
    doc.text('Représentant', xRepresentant, yRepresentant);
    doc.setFont("helvetica", "normal");
    doc.text("Nom :", xRepresentant, yRepresentant + lineHeight);
    doc.text(selectRepre.label, xRepresentant + 20, yRepresentant + lineHeight);

    addDevisTable(doc, margin, yStart + lineHeight * 5, lineHeight);
    
    const totalHTValue = parseFloat(totalHT);
    const totalTVAValue = parseFloat(totalTva);
    const totalTTCValue = parseFloat(totalTTC);
    const yTotalStart = yStart + lineHeight * 5 + lineHeight * arrayProduit.length;

    doc.setFillColor(220, 220, 220); 
    doc.rect(margin, yTotalStart + lineHeight, 100, lineHeight, 'F'); 
    doc.rect(margin, yTotalStart + lineHeight * 2, 100, lineHeight, 'F'); 
    doc.rect(margin, yTotalStart + lineHeight * 3, 100, lineHeight, 'F'); 

    doc.setTextColor(0, 0, 0);
    doc.text(`Total HT: ${totalHTValue.toFixed(2)}`, margin + 2, yTotalStart + lineHeight + 5);
    doc.text(`Total TVA: ${totalTVAValue.toFixed(2)}`, margin + 2, yTotalStart + lineHeight * 2 + 5);
    doc.text(`Total TTC: ${totalTTCValue.toFixed(2)}`, margin + 2, yTotalStart + lineHeight * 3 + 5);

    doc.save('Devis_Client.pdf');
};

  
  const addDevisTable = (doc, startX, startY, lineHeight) => {
    const columns = ["Désignation", "Qte", "Gratuit", "Remise","P.U.H.T",  "Montant HT"];
    const data = arrayProduit.map(el => [
      el.designation,
      el.qte,
      el.qte_gratuit,
      el.remise,
      el.prix_ht,
     
      el.montant_ht
    ]);
    
   
    doc.autoTable({
      startY: startY,
      head: [columns],
      body: data,
      theme: 'grid',
      styles: {
        fontSize: 10,
        fontStyle: 'normal',
        cellPadding: 3,
        overflow: 'linebreak',
        textColor: [0, 0, 0],
        halign: 'center', 
        valign: 'middle' 
      },
      columnStyles: {
        0: { cellWidth: 60 }, 
        1: { cellWidth: 20 },
        2: { cellWidth: 20 }, 
        3: { cellWidth: 20 }, 
        4: { cellWidth: 30 }, 
        5: { cellWidth: 40 }  
      }
    });
  };
  
  
  
  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid={true}>
            <Row>
              <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                Consultation devis client
              </CardTitle>
              <Form>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">N° devis</Label>
                      <Input
                        lg="3"
                        type="text"
                        className="form-control"
                        disabled
                        value={numDevis}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Date devis</Label>
                      <DatePicker
                        name="Date devis"
                        selected={dateDevis}
                        className="form-control ddate"
                        dateFormat="dd/MM/yyyy"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Client</Label>
                      <Select
                        isSearchable={true}
                        value={selectCodeClient}
                        isDisabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Raison sociale
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Raison sociale"
                        value={raisonSocial}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Adresse</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Adresse"
                        value={adresse}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Famille</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Famille"
                        value={selectFamilleClient.label}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatFodec == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Fodec
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">Code TVA</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Code TVA"
                        value={codeTva}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Remise exceptionelle
                      </Label>
                      <Col md={12} className="pr-0">
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="Remise exceptionelle"
                          value={remiseExcep}
                          disabled
                        />
                      </Col>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Representant
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Representant"
                        value={selectRepre.label}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3 mt-3">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck"
                          checked={etatRegimeForf == 1}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="horizontal-customCheck"
                        >
                          Régime forfaitaire
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Mode reglement
                      </Label>
                      <Select
                        isSearchable={true}
                        value={selectModeRegl}
                        isDisabled
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <CardTitle className="h4 mt-4">Details</CardTitle>
              <div>
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Article
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Désignation
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Qte
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Gratuit
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        P.U.H.T
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Remise
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Fodec
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant HT
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        TVA
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Montant TTC
                      </th>
                    </tr>
                  </thead>
                  {arrayProduit.map((el, index) => (
                    <tbody key={index}>
                      <tr>
                        <td style={{ textAlign: "center", width: "24%" }}>
                          <Select
                            options={produits}
                            isSearchable={true}
                            value={el.produit_id}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="..."
                            value={el.designation}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={parseInt(el.qte)}
                            min={0}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={parseInt(el.qte_gratuit)}
                            min={0}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "10%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.prix_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.remise}
                            min={0}
                            max={100}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "2%" }}>
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="horizontal-customCheck"
                            checked={el.fodec == 1}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ht}
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "7%" }}>
                          <Input value={el.tva.label} disabled />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <Input
                            type="number"
                            className="form-control"
                            placeholder="..."
                            value={el.montant_ttc}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Total </CardTitle>
                  <Row>
                    <Col className="mt-3" md={12}>
                      <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                          Observation
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Observation"
                          value={observation}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>Total des remises :</td>
                          <td>{totalRemise}</td>
                        </tr>
                        <tr>
                          <td>Total HT :</td>
                          <td>{totalHT}</td>
                        </tr>
                        <tr>
                          <td>Total fodec :</td>
                          <td>{totalFodec}</td>
                        </tr>
                        <tr>
                          <td>Total TVA :</td>
                          <td>{totalTva}</td>
                        </tr>
                        <tr>
                          <td>Avance/Impot :</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Total TTC : </td>
                          <td>{totalTTC}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <Row className="justify-content-center">
              <Col md={3} className="text-center">
                  <button  className="btn btn-primary " onClick={generatePDF} >
                  <FontAwesomeIcon icon={faFilePdf} size="2x" />
                  Télécharger PDF
                </button>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default DetailDevisClient
DetailDevisClient.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
