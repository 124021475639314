import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Container,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"

const DetailClient = props => {
  const [loading, setLoading] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  // information
  const [code, setCode] = useState("")
  const [codeCompt, setCodeCompt] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [contact, setContact] = useState("")
  const [selectFamille, setSelectFamille] = useState("")
  const [selectRepresentant, setSelectRepresentant] = useState("")
  const [selectChargeAffaire, setSelectChargeAffaire] = useState("")
  const [gerant, setGerant] = useState("")
  const [cin, setCin] = useState("")
  const [dateOuverture, setDateOuverture] = useState("")
  const [etatClient, setEtatClient] = useState(0)
  const [selectCharge, setSelectCharge] = useState("")
  // solde
  const [soldeVenteCredit, setSoldeVenteCredit] = useState("")
  const [soldeVenteEcheance, setSoldeVenteEcheance] = useState("")
  const [plafondVenteCredit, setPlafondVenteCredit] = useState("")
  const [plafondVenteEcheance, setPlafondVenteEcheance] = useState("")
  const [echeanceSaisieRegl, setEcheanceSaisieRegl] = useState("")
  const [signalerDepassEche, setSignalerDepassEche] = useState("")
  const [etatAutDepassRemiseSec, setEtatAutDepassRemiseSec] = useState(0)
  const [autDepassRemiseSec, setAutDepassRemiseSec] = useState("")
  const [etatAutCalcSeuilCredAut, setEtatAutCalcSeuilCredAut] = useState(0)
  const [autCalcSeuilCredAut, setAutCalcSeuilCredAut] = useState("")
  // contact
  const [tel, setTel] = useState("")
  const [fax, setFax] = useState("")
  const [email, setEmail] = useState("")
  const [siteInternet, setSiteInternet] = useState("")
  // Adresse
  // *** Livraison *** //
  const [adresseLiv, setAdresseLiv] = useState("")
  const [selectGouvernoratLiv, setSelectGouvernoratLiv] = useState("")
  const [selectDelegationLiv, setSelectDelegationLiv] = useState("")
  const [selectMunicipaliteLiv, setSelectMunicipaliteLiv] = useState("")
  const [codePostalLiv, setCodePostalLiv] = useState("")
  // *** Facturation *** //
  const [adresseFact, setAdresseFact] = useState("")
  const [selectGouvernoratFact, setSelectGouvernoratFact] = useState("")
  const [selectDelegationFact, setSelectDelegationFact] = useState("")
  const [selectMunicipaliteFact, setSelectMunicipaliteFact] = useState("")
  const [codePostalFact, setCodePostalFact] = useState("")
  // Nature
  const [codeTva, setCodeTva] = useState("")
  const [etatTimbre, setEtatTimbre] = useState(0)
  const [etatFodec, setEtatFodec] = useState(0)
  const [etatRegime, setEtatRegime] = useState(0)
  const [etatAssujetti, setEtatAssujetti] = useState(0)
  const [etatComptabilite, setEtatComptabilite] = useState(0)
  const [etatPaieTva, setEtatPaieTva] = useState(0)
  const [etatExonore, setEtatExonore] = useState(0)
  const [etatExport, setEtatExport] = useState(0)
  const [exp, setExp] = useState("")
  const [debutExoneration, setDebutExoneration] = useState("")
  const [finExoneration, setFinExoneration] = useState("")
  const [mntPlafExo, setMntPlafExo] = useState("")
  // Divers
  const [selectFormeJuridique, setSelectFormeJuridique] = useState("")
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [selectMatBanc, setSelectMatBanc] = useState("")
  const [ribBanc, setRibBanc] = useState("")
  const [etatBloquerFact, setEtatBloquerFact] = useState(0)
  const [etatContentieux, setEtatContentieux] = useState(0)
  const [selectTypeRemise, setSelectTypeRemise] = useState("")
  // Observation
  const [observation, setObservation] = useState("")
  const [garantie, setGarantie] = useState("")
  // Avis
  const [avis, setAvis] = useState("")

  useEffect(async () => {
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("client/get_by_id", { id }).then(res => {
      setCode(res.data.Client.code),
        setCodeCompt(res.data.Client.codeComptable),
        setRaisonSocial(res.data.Client.raisonSocial),
        setContact(res.data.Client.contact),
        setSelectFamille(res.data.Client.famille_client),
        setSelectRepresentant(res.data.Client.Representant),
        setSelectChargeAffaire(res.data.Client.Charge),
        setGerant(res.data.Client.gerant),
        setCin(res.data.Client.cin),
        setDateOuverture(new Date(res.data.Client.dateOuverture)),
        setEtatClient(res.data.Client.actif),
        setTel(res.data.Client.telephone),
        setFax(res.data.Client.fax),
        setEmail(res.data.Client.email),
        setSiteInternet(res.data.Client.siteInternet),
        setSoldeVenteCredit(res.data.Client.soldeCredit),
        setSoldeVenteEcheance(res.data.Client.soldeEcheance),
        setPlafondVenteCredit(res.data.Client.plafondCredit),
        setPlafondVenteEcheance(res.data.Client.plafondEcheance),
        setEcheanceSaisieRegl(res.data.Client.echeance_reglement),
        setSignalerDepassEche(res.data.Client.depasseEcheance),
        setEtatAutDepassRemiseSec(res.data.Client.depasseRemiseDecurite),
        setAutDepassRemiseSec(res.data.Client.nombre_mois),
        setEtatAutCalcSeuilCredAut(res.data.Client.calculSeuilleCredit),
        setAdresseLiv(res.data.Client.adresseLivraison),
        setSelectGouvernoratLiv(res.data.Client.gouvernorat_livraison),
        setSelectDelegationLiv(res.data.Client.Delegation_Livraison),
        setSelectMunicipaliteLiv(res.data.Client.municipaliteLivraison),
        setCodePostalLiv(res.data.Client.codePostalLivraison),
        setAdresseFact(res.data.Client.adresseFacturation),
        setSelectGouvernoratFact(res.data.Client.gouvernorat_facturation),
        setSelectDelegationFact(res.data.Client.DelegationFacturation),
        setSelectMunicipaliteFact(res.data.Client.MunicipaliteFacturation),
        setCodePostalFact(res.data.Client.codePostalFacturation),
        setCodeTva(res.data.Client.codeTVA),
        setEtatTimbre(res.data.Client.timbre),
        setEtatFodec(res.data.Client.fodec),
        setEtatRegime(res.data.Client.regimeForfutaire),
        setEtatComptabilite(res.data.Client.comptabilite),
        setEtatAssujetti(res.data.Client.assujeti),
        setEtatPaieTva(res.data.Client.paieTVA),
        setEtatExonore(res.data.Client.exonore),
        setEtatExport(res.data.Client.exportbtn),
        setExp(res.data.Client.export),
        setDebutExoneration(new Date(res.data.Client.debutExonoration)),
        setFinExoneration(new Date(res.data.Client.finExonoration)),
        setMntPlafExo(res.data.Client.montantPlafonExonoration),
        setSelectFormeJuridique(res.data.Client.FormeJuridique),
        setSelectModeRegl(res.data.Client.ModeReglement),
        setSelectMatBanc(res.data.Client.banque),
        setRibBanc(res.data.Client.rib),
        setEtatBloquerFact(res.data.Client.bloqueFacturation),
        setEtatContentieux(res.data.Client.contentieux),
        setSelectTypeRemise(res.data.Client.TypeRemise),
        setObservation(res.data.Client.observation),
        setGarantie(res.data.Client.garantie),
        setAvis(res.data.Client.avis),
        setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client | Cresus </title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row>
              <Form>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Informations :
                        </CardTitle>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input4">
                                Code
                              </Label>
                              <Input
                                type="text"
                                value={code}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input6">
                                Code comptable
                              </Label>
                              <Input
                                type="text"
                                value={codeCompt}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Raison sociale
                              </Label>
                              <Input
                                placeholder="Inserer une raison sociale"
                                type="text"
                                className="form-control"
                                value={raisonSocial}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Contact
                              </Label>
                              <Input
                                placeholder="Inserer un contact"
                                type="text"
                                className="form-control"
                                value={raisonSocial}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Famille
                              </Label>
                              <Select
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                value={selectFamille}
                                isDisabled={true}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Représentant
                              </Label>
                              <Select
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                value={selectRepresentant}
                                isDisabled={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Chargé d'affaire"}
                              </Label>
                              <Select
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                value={selectChargeAffaire}
                                isDisabled={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gérant
                              </Label>
                              <Input
                                name="gerant"
                                placeholder="Inserer un gérant"
                                type="text"
                                className="form-control"
                                value={gerant}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">CIN</Label>
                              <Input
                                name="cin"
                                placeholder="Inserer CIN"
                                type="text"
                                className="form-control"
                                value={cin}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Date d'ouverture"}
                              </Label>
                              <DatePicker
                                className="form-control ddate"
                                selected={dateOuverture}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Etat client"}
                              </Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatClient}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Actif
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Contact :
                        </CardTitle>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Téléphone
                              </Label>
                              <Input
                                name="tel"
                                placeholder="Inserer un téléphone"
                                label="Téléphone"
                                type="number"
                                value={tel}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Fax
                              </Label>
                              <Input
                                name="fax"
                                placeholder="Inserer un Fax"
                                type="number"
                                value={fax}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input13">
                                E-mail
                              </Label>
                              <Input
                                name="Email"
                                placeholder="Inserer un E-mail"
                                type="email"
                                value={email}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Site web
                              </Label>
                              <Input
                                placeholder="Inserer un site web"
                                type="text"
                                className="form-control"
                                value={siteInternet}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Solde :
                        </CardTitle>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Solde de vente a crédit
                              </Label>
                              <Input
                                name="solde credit"
                                placeholder="Solde de vente a crédit"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={soldeVenteCredit}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Solde de vente a échéance
                              </Label>
                              <Input
                                name="solde echeance"
                                placeholder="Solde de vente a échéance"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={soldeVenteEcheance}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Plafond de vente à crédit
                              </Label>
                              <Input
                                name="plafond vente"
                                placeholder="Plafond de vente à crédit"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={plafondVenteCredit}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Plafond de vente a échéance
                              </Label>
                              <Input
                                name="plafond"
                                placeholder="Plafond de vente a échéance"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={plafondVenteEcheance}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Echéance saisie reglement
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="echeance"
                                    placeholder="Inserer echéance saisie reglement"
                                    type="number"
                                    min={"1"}
                                    className="form-control"
                                    value={echeanceSaisieRegl}
                                    disabled
                                  />
                                </div>
                                <div style={{ width: "8%" }}>jours</div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Signaler le depassement de l'écheance"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="Signalement"
                                    placeholder="Inserer le signalement de depassement de l'écheance"
                                    type="number"
                                    min={"1"}
                                    value={signalerDepassEche}
                                    className="form-control"
                                    disabled
                                  />
                                </div>
                                <div style={{ width: "8%" }}>jours</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                <input
                                  style={{ marginRight: "5px" }}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatAutDepassRemiseSec}
                                />
                                Autoriser le depassement remise de securité
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="depassement"
                                    placeholder="Inserer le depassement remise de securité"
                                    type="number"
                                    min={"1"}
                                    className="form-control"
                                    value={autDepassRemiseSec}
                                    disabled
                                  />
                                </div>
                                <div style={{ width: "8%" }}>mois</div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Calcul seuil crédit automatiquement"}
                              </Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatAutCalcSeuilCredAut}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Autoriser calcul
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Card>
                    <CardBody>
                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              setactiveTab("1")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-home"></i> Adresse
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              setactiveTab("2")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-user"></i> Nature
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-user"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              setactiveTab("3")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fab fa-facebook-messenger"></i>{" "}
                              Divers
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fab fa-facebook-messenger"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              setactiveTab("4")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-eye"></i> Observation
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-eye"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              setactiveTab("5")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-question-circle"></i> Avis
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div style={{ width: "45%" }}>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Livraison :
                              </CardTitle>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Adresse livraison
                                    </Label>
                                    <Input
                                      name="Adresse"
                                      placeholder="Inserer une adresse"
                                      type="text"
                                      className="form-control"
                                      value={adresseLiv}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Gouvernorat
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectGouvernoratLiv}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Délégation
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectDelegationLiv}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Municipalité
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectMunicipaliteLiv}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Code postal
                                    </Label>
                                    <Input
                                      name="code"
                                      placeholder="Inserer un code postal"
                                      type="text"
                                      className="form-control"
                                      value={codePostalLiv}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div style={{ width: "45%" }}>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Facturation :
                              </CardTitle>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Adresse facturation
                                    </Label>
                                    <Input
                                      name="Adresse"
                                      placeholder="Inserer une adresse"
                                      type="text"
                                      className="form-control"
                                      value={adresseFact}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Gouvernorat
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectGouvernoratFact}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Délégation
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectDelegationFact}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Municipalité
                                    </Label>
                                    <Select
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectMunicipaliteFact}
                                      isDisabled={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Code postal
                                    </Label>
                                    <Input
                                      name="code"
                                      placeholder="Inserer un code postal"
                                      type="text"
                                      className="form-control"
                                      value={codePostalFact}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg="6">
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input5">
                                      {"Code TVA"}
                                    </Label>
                                    <Input
                                      placeholder="Inserer un code TVA"
                                      type="text"
                                      className="form-control"
                                      value={codeTva}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck1"
                                    checked={etatTimbre}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck1"
                                  >
                                    Timbre
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck2"
                                    checked={etatFodec}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck2"
                                  >
                                    Fodek
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck3"
                                    checked={etatRegime}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Régime foritaire
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck4"
                                    checked={etatAssujetti}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Assujetti
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck5"
                                    checked={etatComptabilite}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Comptabilité
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline1"
                                    name="customRadioInline1"
                                    className="form-check-input"
                                    checked={etatPaieTva == 1 ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Paie TVA
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline2"
                                    name="customRadioInline2"
                                    className="form-check-input"
                                    checked={etatExonore == 1 ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Exonoré
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="customRadioInline3"
                                    name="customRadioInline3"
                                    className="form-check-input"
                                    checked={etatExport == 1 ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios3"
                                  >
                                    Export
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <Row>
                                <Col lg="6">
                                  <Label for="basicpill-lastname-input12">
                                    Export
                                  </Label>
                                  <Input
                                    placeholder="Inserer export"
                                    type="text"
                                    className="form-control"
                                    value={exp}
                                    disabled
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label>Début exonération</Label>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={debutExoneration}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col lg="6">
                                  <Label>Fin exonération</Label>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={finExoneration}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label for="basicpill-lastname-input12">
                                    {"Montant plafond d'exonération"}
                                  </Label>
                                  <Input
                                    placeholder="Montant plafond d'exonération"
                                    type="number"
                                    step="0.001"
                                    className="form-control"
                                    value={mntPlafExo}
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Forme juridique
                                </Label>
                                <Select
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectFormeJuridique}
                                  isDisabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  Mode de reglement
                                </Label>
                                <Select
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectModeRegl}
                                  isDisabled={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Matricule bancaire
                                </Label>
                                <Select
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectMatBanc}
                                  isDisabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  RIB bancaire
                                </Label>
                                <Input
                                  name="rib"
                                  placeholder="Inserer un RIB bancaire"
                                  type="text"
                                  className="form-control"
                                  value={ribBanc}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  <input
                                    style={{ marginRight: "5px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={etatBloquerFact}
                                  />
                                  Bloquer la facturation
                                </Label>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  <input
                                    style={{ marginRight: "5px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={etatContentieux}
                                  />
                                  Contentieux
                                </Label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Type de remise
                                </Label>
                                <Select
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectTypeRemise}
                                  isDisabled={true}
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Observation
                                </Label>
                                <Input
                                  name="Observation"
                                  placeholder="Inserer une observation"
                                  type="textarea"
                                  className="form-control"
                                  value={observation}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Garantie
                                </Label>
                                <Input
                                  name="Garantie"
                                  placeholder="Inserer une garantie"
                                  type="textarea"
                                  className="form-control"
                                  value={garantie}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Avis
                                </Label>
                                <Input
                                  name="avis"
                                  placeholder="Inserer un avis"
                                  type="textarea"
                                  className="form-control"
                                  value={avis}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Row>
              </Form>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours ...
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DetailClient
DetailClient.propTypes = {
  history: PropTypes.object,
}
