/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import Select from "react-select"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

const AddProduit = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [marque, setMarque] = useState([])
  const [selectMarque, setSelectMarque] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [unite, setUnite] = useState([])
  const [selectUnite, setSelectUnite] = useState("")
  const [emplacement, setEmplacement] = useState([])
  const [selectEmplacement, setSelectEmplacement] = useState("")
  const [fournisseur, setFournisseur] = useState([])
  const [selectFournisseur, setSelectFournisseur] = useState("")


  const [famille, setFamille] = useState([])
  const [selectFamille, setSelectFamille] = useState("")

  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [code, setCode] = useState("")
  const [actif, setActif] = useState("")
  const [homologue, setHomologue] = useState("")
  const [stockable, setStockable] = useState("")
  const [fodec, setFodec] = useState("")
  const [designation, setDesignation] = useState("")
  const [file, setFile] = useState()
  const [quantite, setQuantite] = useState("")
  const [quantiteDepart, setQuantiteDepart] = useState("")
  const [QuantiteMinimal, setQuantiteMinimal] = useState("")
  const [QuantiteMaximal, setQuantiteMaximal] = useState("")
  const [colisage, setColisage] = useState("")
  //depot
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState("")
  
  const [poids, setPoids] = useState("")
  const [longueur, setLongueur] = useState("")
  const [largeur, setLargeur] = useState("")
  const [hauteur, setHauteur] = useState("")
  const [volume, setVolume] = useState("")
  const [prixPondere, setPrixPondere] = useState("")
  const [prixAchat, setPrixAchat] = useState("")
  const [remiseAchat, setRemiseAchat] = useState("")
  const [prixRevient, setPrixRevient] = useState("")
  const [marge, setMarge] = useState("")
  const [prixVente, setPrixVente] = useState("")
  const [tva, setTva] = useState([])
  const [selectTva, setSelectTva] = useState("")
  const [prixVenteTTC, setPrixVenteTTC] = useState("")
  const [observation, setObservation] = useState("")
//maxselect
const maxHeight =100
  useEffect(async () => {
    const res = await API.get("marque/select").then(res => {
      setMarque(res.data.Marque)
    })
    const resC = await API.get("groupe/article/select").then(resC => {
      setGroupe(resC.data.GroupeArticle)
    })
    const resT = await API.get("unite/select").then(resT => {
      setUnite(resT.data.Unite)
    })
    const resG = await API.get("emplacement/select").then(resG => {
      setEmplacement(resG.data.Emplacement)
    })
    const resM = await API.get("fournisseur/select").then(resM => {
      setFournisseur(resM.data.Fournisseur)
    })
    const resD = await API.get("famille/groupe/article/select").then(resD => {
      setFamille(resD.data.FamilleGroupeArticle)
    })
    const resDE = await API.get("depot/select").then(resDE => {
      setDepot(resDE.data.Depot)

    })


    const resFO = await API.get("type/article/select").then(resFO => {
      setType(resFO.data.TypeArticle)
    })
    const resTVA = await API.get("tva/select").then(resTVA => {
      setTva(resTVA.data.Tva)
    })
  }, [])

  const addFile = e => {
    var photo = e.target.files[0]
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
    const fileExtension = photo.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("⛔ L'extension du fichier n'est pas autorisée. Veuillez sélectionner une photo avec une extension différente de jpg, png ou webp.", {
          containerId: "A",
      });
      // Réinitialiser la valeur de l'input de fichier pour effacer le fichier sélectionné
      e.target.value = '';
      return;
  }

    const reader =new FileReader();
    reader.onload=()=>{
      setFile(reader.result)
    }
   // setFile(file)
   reader.readAsDataURL(photo)
  }

  const cocheActif = e => {
    if (e.target.checked) {
      setActif(1)
    } else {
      setActif(0)
    }
  }

  const cocheHomologue = e => {
    if (e.target.checked) {
      setHomologue(1)
    } else {
      setHomologue(0)
    }
  }

  const cocheStockable = e => {
    if (e.target.checked) {
      setStockable(1)
    } else {
      setStockable(0)
    }
  }

  const cocheFodec = e => {
    if (e.target.checked) {
      setFodec(1)
    } else {
      setFodec(0)
    }
  }

  /*const calculQuantiteMaximal = e => {
    if (e < parseInt(QuantiteMinimal)) {
      toast.error(
        "⛔ Quantité maximal doit étre supérieur à quantité minimal",
        {
          containerId: "A",
        }
      )
    } else {
      setQuantiteMaximal(e)
    }
  }*/

  const calculVolumeLarg = e => {
    setLargeur(e)
    var volume = hauteur * longueur * e
    setVolume(volume)
  }

  const calculVolumeLong = e => {
    setLongueur(e)
    var volume = largeur * hauteur * e
    setVolume(volume)
  }

  const calculVolumeHaut = e => {
    setHauteur(e)
    var volume = largeur * longueur * e
    setVolume(volume)
  }

  const save = async () => {
    /*var pathFile = ""
    if (file != null) {
      pathFile = file 
    }*/
  
    if (QuantiteMinimal < QuantiteMaximal) {
      toast.error(
        "⛔ Quantité maximal doit étre supérieur à quantité minimal",
        {
          containerId: "A",
        }
      )
    } else if (
      selectMarque != "" &&
      selectGroupe != "" &&
      selectUnite != "" &&
      selectFournisseur != "" &&
      selectFamille != "" &&
      selectDepot != "" &&
      designation != "" &&
      QuantiteMinimal != "" &&
      QuantiteMaximal != "" &&
      colisage != "" &&
      prixAchat != "" &&
      remiseAchat != "" &&
      marge != "" &&
      prixVente != "" &&
      selectTva != "" &&
      prixVenteTTC != ""
    ) { 
      const userA = JSON.parse(localStorage.getItem('userAuth'))
      const userID= userA.user.id;
      const res = await API.post("article/add", {
        
        marque_id: selectMarque.value,
        groupe_id: selectGroupe.value,
        unite_id: selectUnite.value,
        emplacement_id: selectEmplacement.value,
        fournisseur_id: selectFournisseur.value,
        famille_id: selectFamille.value,
        type_id: selectType.value,
        //depot
        depot_id: selectDepot.value,
        code: code,
        designation: designation,
        actif: actif,
        homologue: homologue,
        stockable: stockable,
        fodec: fodec,
        quantite_depart: quantiteDepart,
        quantite_min: QuantiteMinimal,
        quantite_max: QuantiteMaximal,
        colisage: colisage,
        poids: poids,
        longueur: longueur,
        largeur: largeur,
        hauteur: hauteur,
        volume: volume,
        prix_moyen: prixPondere,
        prix_achat_ht: prixAchat,
        remise_achat: remiseAchat,
        prix_revient: prixRevient,
        marge: marge,
        prix_vente_ht: prixVente,
        tva: selectTva.value,
        prix_vente_ttc: prixVenteTTC,
        observation: observation,
        file: file,
       user_id: userID,
       
      }).then((res15) => {
        props.setSection(1)
        

        /*if (pathFile != "") {
          const formData = new FormData()
          formData.append("document", file)
          const resA = API.post("file/import", formData)
        }*/
      })
    } else {
      toast.error("⛔ Remplir les champs obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>
            <Row>
              <Col>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Informations :
                    </CardTitle>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input4">Code</Label>
                          <AvField
                            name="code"
                            placeholder="code"
                            type="text"
                            className="form-control"
                            errorMessage="* Code obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setCode(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Marque
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={marque}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectMarque(e)}
                              styles={{
                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                              }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Groupe
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={groupe}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectGroupe(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Unité
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={unite}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectUnite(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input5">
                            Emplacement
                          </Label>
                          <Select
                            options={emplacement}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectEmplacement(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Fournisseur
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={fournisseur}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectFournisseur(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Famille
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={famille}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectFamille(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">Type</Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={type}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectType(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Désignation
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="designation"
                            placeholder="Désignation"
                            type="text"
                            errorMessage="* Désignation obligatoire"
                            onChange={e => setDesignation(e.target.value)}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input13">
                            {"Photo de l'article"}
                          </Label>
                          <Input
                            onChange={e => addFile(e)}
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3 mt-3">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="actif"
                              onClick={e => cocheActif(e)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="actif"
                            >
                              Actif
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="Homologue"
                              onClick={e => cocheHomologue(e)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="Homologue"
                            >
                              Homologué
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="Stockable"
                              onClick={e => cocheStockable(e)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="Stockable"
                            >
                              Stockable
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="Fodec"
                              onClick={e => cocheFodec(e)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="Fodec"
                            >
                              Fodec
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="my-custom-card">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Stock :
                    </CardTitle>
                    <Row>
                   
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Quantité départ
                          </Label>
                          <AvField
                            name="quantitedepart"
                            placeholder="Quantité départ"
                            type="number"
                            onChange={e => setQuantiteDepart(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Quantité minimale
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="quantiteminimale"
                            placeholder="Quantité minimale"
                            type="number"
                            errorMessage="* Quantité minimale obligatoire"
                            onChange={e => setQuantiteMinimal(e.target.value)}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Quantité maximale
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="quantitemaximale"
                            placeholder="Quantité maximale"
                            type="number"
                            min={QuantiteMinimal ?? 0}  
                            errorMessage="* Quantité maximale obligatoire/
                            Quantité maximal doit étre supérieur à quantité minimal"
                            onChange={e =>
                              setQuantiteMaximal(e.target.value)
                            }
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Colisage
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="colisage"
                            placeholder="Colisage"
                            type="number"
                            onChange={e => setColisage(e.target.value)}
                            errorMessage="* Colisage obligatoire"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      
                      
                      <Col md={3}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Depot
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={depot}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectDepot(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Caractéristique :
                    </CardTitle>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">Poids</Label>
                          <AvField
                            name="poids"
                            placeholder="Poids"
                            type="number"
                            onChange={e => setPoids(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Longueur
                          </Label>
                          <AvField
                            name="longueur"
                            placeholder="Longueur"
                            type="number"
                            onChange={e => calculVolumeLong(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Largeur
                          </Label>
                          <AvField
                            name="largeur"
                            placeholder="Largeur"
                            type="number"
                            onChange={e => calculVolumeLarg(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Hauteur
                          </Label>
                          <AvField
                            name="hauteur"
                            placeholder="Hauteur"
                            type="number"
                            onChange={e => calculVolumeHaut(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">Volume</Label>
                          <AvField
                            name="volume"
                            placeholder="Volume"
                            type="number"
                            value={volume}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card >
                  <CardBody>
                    <CardTitle style={{ color: "#556ee6" }} className="h4">
                      Prix :
                    </CardTitle>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Prix moyen pondéré
                          </Label>
                          <AvField
                            name="prixMoyen"
                            placeholder="Prix moyen pondéré"
                            type="number"
                            onChange={e => setPrixPondere(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Prix achat HT
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="prixAchat"
                            placeholder="Prix achat HT"
                            type="number"
                            errorMessage="* Prix achat HT obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setPrixAchat(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Remise achat (%)
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="remise"
                            placeholder="Remise achat"
                            type="number"
                            errorMessage="* Remise achat obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setRemiseAchat(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input12">
                            Prix de revient
                          </Label>
                          <AvField
                            name="prixRevient"
                            placeholder="Prix de revient"
                            type="number"
                            errorMessage="* Prix de revient obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setPrixRevient(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Marge
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="marge"
                            placeholder="Marge"
                            type="number"
                            errorMessage="* Marge obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setMarge(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Prix vente HT
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="prixVente"
                            placeholder="Prix de vente HT"
                            type="number"
                            errorMessage="* Prix de vente HT obligatoire"
                            validate={{ required: { value: true } }}
                            onChange={e => setPrixVente(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">TVA</Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <Select
                            options={tva}
                            isSearchable={true}
                            classNamePrefix="select2-selection"
                            onChange={e => setSelectTva(e)}
                            styles={{
                              menu: base => ({ ...base, maxHeight, overflowY: 'auto' }) 
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-arround",
                            }}
                          >
                            <Label for="basicpill-firstname-input1">
                              Prix vente TTC
                            </Label>
                            <Label style={{ color: "red", paddingLeft: "5px" }}>
                              *
                            </Label>
                          </div>
                          <AvField
                            name="prixVenteTTC"
                            placeholder="Prix de vente TTC"
                            type="number"
                            onChange={e => setPrixVenteTTC(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-money-bill-alt"></i> Remise selon
                          modalité de paiement
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-money-bill-alt"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-eye"></i> Observation
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-eye"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1"></TabPane>
                    <TabPane tabId="2">
                      {" "}
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-lastname-input12">
                              Observation
                            </Label>
                            <AvField
                              name="Observation"
                              placeholder="Observation"
                              type="textarea"
                              className="form-control"
                              onChange={e => setObservation(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  onClick={save}
                  type="submit"
                  className="btn btn-primary "
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddProduit
AddProduit.propTypes = {
  back: PropTypes.object,
  setSection: PropTypes.object,
  history: PropTypes.object,
}
