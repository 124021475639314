import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';
const StatDirect = () => {
    const [etatFacturationValues, setEtatFacturationValues] = useState({ total_fact: 0, total_facture_direct: 0 });
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('facture/client/get_stat_facture');
                const data = response.data.FactureClient;
                setEtatFacturationValues({
                    total_facture_direct: parseFloat(data.pourcentageFactureDirect),
                    facture_bl: parseFloat(data.pourcentageBl),
                    facture_avoir: parseFloat(data.pourcentageAvoir),
                    totalFact: parseFloat(data.totalFact),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);
    const getOptionFacturation = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 10, // Adjust the left position as needed
                data: ['Direct Facture', 'total des factures'], // Update legend data
                textStyle: {
                    color: ['#74788D'],
                },
            },
            color: ['#02A499', '#F8B425', '#EC4561', '#38A4F8', '#3C4CCF'],
            series: [
                {
                    name: 'Total sales',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: [
                        { value: etatFacturationValues.total_facture_direct, name: 'Direct Facture' },
                        { value: etatFacturationValues.totalFact - etatFacturationValues.total_facture_direct, name: 'total des factures' },
                    ],
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };
    return (
        <React.Fragment>
            <p>Facture Direct</p>
            <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
        </React.Fragment>
    );
};
export default StatDirect;