/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Row, Col, Spinner, Modal, ModalBody, Button } from "reactstrap"
import { withRouter } from "react-router"
//lang
import { withTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import { Search } from "react-bootstrap-table2-toolkit"

const HistoriqueDeplacement = props => {
  const [loading, setLoading] = useState(false)
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [data, setData] = useState([])
  const [userdata, setUserdata] = useState([])
  const [perm, setPerm] = useState({
    add: 0,
    delete: 0,
    edit: 0,
    imp: 0,
    view: 0
  });

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    console.log(userAuth)
    setUserdata(userAuth.user.userData)
    console.log(userAuth.user.userData)
  }, [])


  useEffect(() => {
    console.log(userdata)
    const typeArticleSubMenu = userdata.find(user =>
      user.sous_menus.some(subMenu => subMenu.sous_menu === "Deplacement")
    );

    if (typeArticleSubMenu) {
      console.log(typeArticleSubMenu)
      const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
        subMenu => subMenu.sous_menu === "Deplacement"
      ).permissions[0];
      console.log(typeArticlePermission)
      setPerm(prevPerm => ({
        ...prevPerm,
        add: typeArticlePermission.add,
        delete: typeArticlePermission.delete,
        edit: typeArticlePermission.edit,
        imp: typeArticlePermission.imp,
        view: typeArticlePermission.view,

      }));
    }
  }, [userdata]);
  console.log(perm)
  ///////////permission////
  const columns = [
    {
      name: "code",
      selector: "id",
      sortable: false,
      center: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: false,
      center: true,
    },
    {
      name: "Depot sortie",
      selector: "depot_sortie.designation",
      sortable: false,
      center: true,
    },
    {
      name: "Article",
      selector: "article.designation",
      sortable: false,
      center: true,
    },
    {
      name: "Depot Entree",
      selector: "depot_entree.designation",
      sortable: false,
      center: true,
    },

    {
      name: "Quantite",
      selector: "quantite",
      sortable: false,
      center: true,
    },






  ]

  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        backgroundColor: "#eff2f7",
      },
    },
    cells: {
      style: {
        fontSize: "13px",
      },
    },
  }

  useEffect(async () => {
    const res = await API.post("stock/historique_deplacement", {
      nbrElParPage: 5,
      page: 1,
    }).then(res => {


      setData(res.data.data)
      setNbrTotlEl(res.data.total)
    })
    setLoading(true)
  }, [])

  const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.post("stock/historique_deplacement", {
      nbrElParPage: nbrEltperPage,
      page: page,
    }).then(res => {
      setData(res.data.data)

      setNbrTotlEl(res.data.total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.post("stock/historique_deplacement", {
      nbrElParPage: currentRowsPerPage,
      page: currentPage,
    }).then(res => {
      setData(res.data.data)
      setNbrTotlEl(res.data.total)
    })
  }

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  /*const toggleSupp = async (id) => {
    const res = await API.post("article/delete", {
      id: id,
    }).then(res => {
      setModal(!modal)
      const resD = API.post("article/list", {
        nbrElParPage: nbrEltperPage,
        page: mycurrentPage,
      }).then(resD => {
        setData(resD.data.Article[0].Article)
        setNbrTotlEl(resD.data.Article[0].total)
      })
    })
  }*/

  const { SearchBar } = Search
  const handleSearch = value => {
    setFilterElement(value);
  };

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <SearchBar
                    onSearch={handleSearch} />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col xs="12">
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover={false}
                pagination={true}
                paginationServer={true}
                onChangePage={(page, totalRows) => newElement(page, totalRows)}
                paginationTotalRows={nbrTotlEl}
                paginationPerPage={nbrEltperPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                  perPage(currentRowsPerPage, currentPage)
                }
                paginationResetDefaultPage={resetFirstPage}
                paginationComponentOptions={{
                  rowsPerPageText: "Elements par page:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                }}
              />
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <Delete> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>
                {props.t("Êtes-Vous sûr de vouloir supprimer cet article ?")}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                //onClick={toggleSupp}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(HistoriqueDeplacement))
HistoriqueDeplacement.propTypes = {
  personnel: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
