import axios from "axios";

// Déterminez si un token existe dans le localStorage
let token = '';
if (localStorage.getItem("userAuth")) {
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  token = userAuth.user.api_token;
}

// Définissez une variable globale warning_message initialisée à une chaîne vide
let warning_message = ""; // Utilisation de let à la place de var

// Créez votre instance personnalisée d'Axios
const axiosInstance = axios.create({
  withCredentials: true,
 baseURL: `https://backcommercial.cresus-institut.ovh/api/`,
  //baseURL: `http://localhost:8000/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
    apipassword: "sKgkGkRhObr0kXdrNyjW5S",
  },
});

// Définissez l'intercepteur de réponse sur votre instance personnalisée
/*axiosInstance.interceptors.response.use(
  response => {

    console.log("ALL_RESPONSES", response);
    // Mettez à jour la variable warning_message uniquement en cas de succès de la réponse
   
      warning_message = "OKK";
    console.log("wm",warning_message)
    return response;
  },
  error => {
    // En cas d'erreur, vous pouvez également mettre à jour la variable warning_message ici si nécessaire
    return Promise.reject(error);
  }
);*/

export default axiosInstance;
//export { warning_message };
