import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { Col, Label, Progress, Row, Spinner, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { options } from "toastr";
import API from "../../api"
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
import { check } from "prettier";


import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import moment from "moment/moment";

const ModifPromo = props => {
    const [loading, setLoading] = useState(false);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [Taux, setTaux] = useState(0);
    const [NomPromo, setNomPromo]= useState("");
    const [newTaux, setNewTaux] = useState(0);
    const [Type, setType] = useState("");
    const [SelectType, setSelectType] = useState("");
    const [data, setData] = useState([])
    const [arrayProduit, setArrayProduit] = useState([])
    const [arrayClient, setArrayClient] = useState([])
    const [diplayArticles, setDisplayArticles] = useState(false)
    const [checkMode, setCheckMode] = useState(false)

    const [disabledDateDebut, setDisabledDateDebut] = useState(false)
    const [disabledDateFin, setDisabledDateFin] = useState(false)

    const [marque, setMarque] = useState([])
    const [selectMarque, setSelectMarque] = useState("")
    const [groupe, setGroupe] = useState([])
    const [selectGroupe, setSelectGroupe] = useState("")

    const [famille, setFamille] = useState([])
    const [selectFamille, setSelectFamille] = useState("")
    const [famille_Client, setFamille_Client] = useState([])

    const [type_article, setTypeArticle] = useState([])
    const [selectTypeArticle, setSelectTypeArticle] = useState("")
    const [checked_all, setchecked_all] = useState(false)
    const [checked_all_clients, setchecked_all_clients] = useState(false)
    const [CIN_Client, setCIN_Client] = useState("")
    const [selected_familleClient, setselected_familleClient] = useState({ value: null, label: "(None)" })

    const [displayedArticles, setDisplayedArticles] = useState([])
    const [displayBlockClient, setDisplayBlockClient] = useState(false);
    const [displayedClients, setDisplayedClients] = useState([])

    const maxHeight = 190
    const [Id, setId] = useState()
    const [promo, setPromo] = useState([])

    const [selectedType, setSelectedType] = useState("")
    const [dateSysteme,setDateSysteme] = useState(new Date());











    const options = [
        { value: 'Articles', label: 'Sur articles' },
        { value: 'Client', label: 'Sur client' },
        //{ value: 'Facture', label: 'Sur facture' },
        //{ value: 'Commande', label: 'Sur commande' },
        // { value: 'Livraison', label: 'Sur livraison' },


    ];
    useEffect(async () => {

      /*  const formattedDate = dateSysteme.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).replace(/\//g, '-');
          
          setDateSysteme(formattedDate);*/
          

        const res = await API.get("marque/select")

            .then(res => {
                const elementVide = { id: null, label: "(None)" };
                const nouvellesMarques = [elementVide, ...res.data.Marque];
                setMarque(nouvellesMarques);
            });

        const resC = await API.get("groupe/article/select")

            .then(resC => {
                const elementVide = { id: null, label: "(None)" };
                const nouveauxGroupes = [elementVide, ...resC.data.GroupeArticle];
                setGroupe(nouveauxGroupes);
            });


        const resD = await API.get("famille/groupe/article/select")
            .then(resD => {
                const elementVide = { id: null, label: "(None)" };
                const nouvellesFamilles = [elementVide, ...resD.data.FamilleGroupeArticle];
                setFamille(nouvellesFamilles);
            });

        const resFO = await API.get("type/article/select")
            .then(resFO => {
                const elementVide = { id: null, label: "(None)" };
                const nouvellesArticles = [elementVide, ...resFO.data.TypeArticle];
                setTypeArticle(nouvellesArticles);
            });


        const res1 = await API.get("famille/client/select").then(res => {
            const elementVide = { value: null, label: "(None)" };
            const nouvellesFamilles_Client = [elementVide, ...res.data.FamilleClient];
            setFamille_Client(nouvellesFamilles_Client)
        })
    }, [])

    useEffect(async () => {
        const filteredArticles = arrayProduit.filter(produit => {
            return (selectMarque.value == null || produit.marque && produit.marque.id === selectMarque.value) &&
                (selectGroupe.value == null || produit.groupe && produit.groupe.id === selectGroupe.value) &&
                (selectFamille.value == null || produit.famille && produit.famille.id === selectFamille.value) &&
                (selectTypeArticle.value == null || produit.type && produit.type.id === selectTypeArticle.value);
        });



        // const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);
        // const filteredArticles = arrayProduit.filter(produit => produit.groupe.id == selectGroupe.value);
        /*  const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);
          const filteredArticles = arrayProduit.filter(produit => produit.marque.id == selectMarque.value);*/
        // Mettre à jour l'état displayedArticles avec les articles filtrés
        setDisplayedArticles(filteredArticles);
        setchecked_all(false)
        /*console.log("Marque", selectMarque)
         const res = await API.post("article/recherche", {
           marque_id: selectMarque.value,
           groupe_id: selectGroupe.value,
           famille_id: selectFamille.value,
           type_article_id: selectTypeArticle.value
         }
         ).then(res => {
           console.log("recherche", res)
           setArrayProduit(res.data.Data[0].Articles)
         })*/
    }, [selectMarque, selectGroupe, selectFamille, selectTypeArticle]);



    useEffect(async () => {

        var type_promo = promo.type_promo
        if (type_promo !== undefined) {
            if (type_promo.value === "Articles") {
                setDisplayArticles(true)
                setDisplayBlockClient(false)
            } else if (type_promo.value === "Client") {
                setDisplayBlockClient(true)
                setDisplayArticles(false)
            }
        }

    }, [promo])

    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        setId(id)

        const res = await API.post("promotion/display_to_update", { id }).then(res => {
            if (res.data.promo.type_promo.label === "Articles") {
                setDisplayedArticles(res.data.lignes)
                setArrayProduit(res.data.lignes)
                setPromo(res.data.promo)
                setSelectedType(res.data.promo.type_promo)
                setNomPromo( res.data.promo.nom_promo)
                
                const date_deb_prom = new Date(res.data.promo.date_debut_promotion)
               const dateDebutPromotion = moment(date_deb_prom ).format('DD-MM-YYYY');
               setDateDebut(dateDebutPromotion)
                if (dateDebutPromotion <  moment(new Date() ).format('DD-MM-YYYY') ) {
                    setDisabledDateDebut(true)
                }
                
                const date_fin_prom = new Date(res.data.promo.date_fin_promotion)
               const dateFinPromotion = moment(date_fin_prom).format('DD-MM-YYYY');
               setDateFin(dateFinPromotion)
                if (dateFinPromotion <  moment(new Date() ).format('DD-MM-YYYY') ) {
                    setDisabledDateFin(true)
                }
            }
            else if (res.data.promo.type_promo.label === "Client") {
                setDisplayedClients(res.data.lignes)
                setArrayClient(res.data.lignes)
                setPromo(res.data.promo)
                setSelectedType(res.data.promo.type_promo)
                setNomPromo( res.data.promo.nom_promo)

                setDateDebut(res.data.promo.date_debut_promotion)
                setDateFin(res.data.promo.date_fin_promotion)
            }
            //setTaux(res.data.promo.taux)

            setLoading(true)
        })

    }, [])

    const change_all = (e) => {
        setCheckMode(!checkMode)
        if (e.target.checked) {
            setArrayProduit(
                arrayProduit.map(el => {
                    if (el.etat === 1) {
                        return {
                            ...el,

                            taux: promo.taux,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                        };
                    }
                    return el
                }
                ));
            setDisplayedArticles(
                arrayProduit.map(el => {
                    if (el.etat === 1) {
                        return {
                            ...el,

                            taux: promo.taux,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                        };
                    }
                    return el
                }
                ));
        }

    }

    const check_all = (event) => {
        if (event.target.checked == true) {
            setchecked_all(true)
            let etat = 1;
            const modifiedIds = displayedArticles.map(el => el.id);
            setArrayProduit(
                arrayProduit.map(el => {
                    if (checkMode || el.changed === 'no') {
                        if (modifiedIds.includes(el.id)) {
                            return {
                                ...el,
                                etat: etat,
                                taux: promo.taux,
                                prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                            };
                        }
                    }
                    else if (el.changed === 'yes') {
                        if (modifiedIds.includes(el.id)) {

                            return {
                                ...el,
                                etat: etat,
                                taux: el.taux,
                                prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                            };
                        }
                    }
                    return el;
                })
            );

            setDisplayedArticles(
                displayedArticles.map(el => {
                    if (checkMode || el.changed === 'no') {
                        return {
                            ...el,
                            etat: etat,
                            taux: promo.taux,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                        };
                    }
                    else if (el.changed === 'yes') {


                        return {
                            ...el,
                            etat: etat,
                            taux: el.taux,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                        };
                    }
                    return el;
                })
            );


        }
        else {
            setchecked_all(false)
            let etat = 0;

            setArrayProduit(
                arrayProduit.map((el, id) => {
                    return {
                        ...el,
                        etat: etat,
                        taux: el.changed === "yes" ? el.taux : 0,
                        prix_apres_prom: undefined
                    };
                })
            );
            setDisplayedArticles(
                displayedArticles.map((el, id) => {
                    return {
                        ...el,
                        etat: etat,
                        taux: el.changed === "yes" ? el.taux : 0,
                        prix_apres_prom: undefined
                    };
                })
            );

        }

    }


    const coche = (event, index, id) => {
        if (event.target.checked == true) {
            let etat = 1

            setArrayProduit(
                arrayProduit.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: etat,
                            taux: el.taux !== 0 ? el.taux : promo.taux,
                            prix_apres_prom: (el.prix_vente_ht - (el.prix_vente_ht * (el.taux / 100)))
                        })
                        : el
                )
            )
            setDisplayedArticles(
                displayedArticles.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: etat,
                            taux: el.taux !== 0 ? el.taux : promo.taux,
                            prix_apres_prom: (el.prix_vente_ht - (el.prix_vente_ht * (el.taux / 100)))
                        })
                        : el
                )
            )

        } else {
            setArrayProduit(
                arrayProduit.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: 0,
                            // taux: 0,
                            prix_apres_prom: undefined
                        })
                        : el
                )
            )
            setDisplayedArticles(
                displayedArticles.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: 0,
                            // taux: 0,
                            prix_apres_prom: undefined
                        })
                        : el
                )
            )
        }
    }
    const change_taux = (e, index, id) => {
        if (e.target.value < 101) {
            setArrayProduit(
                arrayProduit.map((el, idx) =>
                    el.id == id
                        ? {
                            ...el,
                            taux: e.target.value,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (e.target.value / 100)),
                            changed: "yes"
                        }
                        : el
                )
            );
            setDisplayedArticles(
                displayedArticles.map((el, idx) =>
                    el.id == id
                        ? {
                            ...el,
                            taux: e.target.value,
                            prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (e.target.value / 100)),
                            changed: "yes"
                        }
                        : el
                )
            );
        }
        else {
            toast.error(
                "Le taux de remise ne doit pas dépasser 100%",
                {
                    containerId: "A",
                }
            )
        }

    };

    useEffect(async () => {
      

        const contientEtatZero = displayedArticles.some(objet => objet.etat === 0 || objet.etat === undefined);
        if (contientEtatZero) {
            setchecked_all(false)
        }
        else { setchecked_all(true) }


    }, [arrayProduit, displayedArticles]);


    useEffect(async () => {
        setArrayProduit(
            arrayProduit.map((el) => {
                if (el.etat === 1 && checkMode === true) {
                    return {
                        ...el,
                        taux: el.etat === 1 ? newTaux : el.taux,
                        prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                    };
                }
                else if (el.etat === 1 && el.changed !== "yes") {
                    return {
                        ...el,
                        taux: el.etat === 1 ? newTaux : el.taux,
                        prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                    }
                } else {
                    return el;
                }
            })
        );
        setDisplayedArticles(
            displayedArticles.map((el) => {
                if (el.etat === 1 && checkMode === true) {
                    return {
                        ...el,
                        taux: el.etat === 1 ? newTaux : el.taux,
                        prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                    };
                }
                else if (el.etat === 1 && el.changed !== "yes") {
                    return {
                        ...el,
                        taux: el.etat === 1 ? newTaux : el.taux,
                        prix_apres_prom: el.prix_vente_ht - (el.prix_vente_ht * (Taux / 100))
                    }
                } else {
                    return el;
                }
            })
        );


    }, [newTaux]);


    const change_taux_total = (e) => {

        if (e.target.value < 101) {
            setNewTaux(e.target.value)
            setPromo({
                ...promo,
                taux: e.target.value,
            });

            setTaux(e.target.value);

        }


        else {
            toast.error(
                "Le taux de remise ne doit pas dépasser 100%",
                {
                    containerId: "A",
                }
            )
        }

    };


    useEffect(async () => {


        const contientEtatZero = displayedClients.some(objet => objet.etat === 0 || objet.etat === undefined);
        if (contientEtatZero) {
            setchecked_all_clients(false)
        }
        else { setchecked_all_clients(true) }
    }, [arrayClient, displayedClients]);


    const save = async () => {
        if (selectedType.label === "Articles") {
            const res = await API.post("promotion/edit", {
                promo: promo,
                dateDebut: dateDebut,
                dateFin: dateFin,
                Taux: promo.taux,
                NomPromo:NomPromo,
                
                Type: SelectType.value,
                arrayProduit: arrayProduit

            }).then(res => {
                window.location.href ='/promotions';

            })
        }
        else if (selectedType.label === "Client") {
            const res = await API.post("promotion/edit", {
                promo: promo,
                dateDebut: dateDebut,
                dateFin: dateFin,
                Taux: promo.taux,
                NomPromo:NomPromo,

                Type: SelectType.value,
                arrayClient: arrayClient

            }).then(res => {
                window.location.href ='/promotions';

            })

        }

    }

    const coche_client = (event, index, id) => {
        if (event.target.checked == true) {
            let etat = 1
            setArrayClient(
                arrayClient.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: etat,
                        })
                        : el
                )
            )
            setDisplayedClients(
                displayedClients.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: etat,
                        })
                        : el
                )
            )

        } else {
            setArrayClient(
                arrayClient.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: 0,
                        })
                        : el
                )
            )
            setDisplayedClients(
                displayedClients.map((el, idx) =>
                    el.id == id
                        ? Object.assign(el, {
                            etat: 0,
                        })
                        : el
                )
            )
        }
    }

    const check_all_clients = (event) => {
        if (event.target.checked == true) {
            setchecked_all_clients(true)
            let etat = 1;

            const modifiedIds = displayedClients.map(el => el.id);
            setArrayClient(arrayClient.map(el => {
                if (modifiedIds.includes(el.id)) {
                    return {
                        ...el,
                        etat: etat
                    };
                }
                return el;
            }));
            setDisplayedClients(displayedClients.map(el => ({
                ...el,
                etat: etat,
            })));
        }
        else {
            setchecked_all_clients(false)
            let etat = 0;
            setArrayClient(
                arrayClient.map((el, id) => {
                    return {
                        ...el,
                        etat: etat,
                    };
                })
            );
            setDisplayedClients(
                displayedClients.map((el, id) => {
                    return {
                        ...el,
                        etat: etat
                    };
                })
            );
        }
    }

    useEffect(async () => {
        
        const filteredClients = arrayClient.filter(client => {
            return (!CIN_Client || client.cin && client.cin.includes(CIN_Client)) &&
                (selected_familleClient.value === null || client.famille && client.famille.value === selected_familleClient.value);
        });

        setDisplayedClients(filteredClients);
        setchecked_all(false)

    }, [CIN_Client, selected_familleClient]);

   
    const changeDateDebut = (date) => {
        
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');

        
        const dateDebutPromotion = new Date(date);
        if (dateDebutPromotion > dateSysteme) {
            setDateDebut(formattedDate)

        } else {
            toast.error(
                "La date de début ne peut pas être antérieure à la date d'aujourd'hui",
                {
                    containerId: "A",
                }
            )
        }
    }
    const changeDateFin = (date) => {
        
       
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');




        const dateDebutPromotion = new Date(date);
        if (dateDebutPromotion > dateSysteme) {
            setDateFin(formattedDate)

        } else {
            toast.error(
                "La date de début ne peut pas être antérieure à la date système",
                {
                    containerId: "A",
                }
            )
        }


        

        /*
                if (date > dateSysteme) {
                    setDateFin(formattedDate)
                }
                else {
                    toast.error(
                        "La date de fin  ne peut pas être antérieure à la date système",
                        {
                            containerId: "A",
                        }
                    )
                }*/
    }


    return (
        <React.Fragment>
            <div style={{ textAlign: "center", marginTop: "10%", marginLeft: "3%", marginRight: "3%", marginBottom: "10%" }}>
                {loading ? (
                    <Row>
                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <div
                                    style={{
                                        display: "flex",
                                        //justifyContent: "space-around",
                                    }}
                                >
                                    <Label for="basicpill-firstname-input2">
                                        Type
                                    </Label>
                                    <Label style={{ color: "red", paddingLeft: "5px" }}>
                                        *
                                    </Label>
                                </div>

                                <Select

                                    options={options}
                                    isSearchable={true}
                                    classNamePrefix="select2-selection"
                                    value={selectedType}
                                    isDisabled={true}

                                />

                            </div>
                        </Col>

                        <Col md={3}>
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Nom De Promotion
                                            </Label>

                                        </div>
                                        <Input
                                            classNamePrefix="select2-selection"
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                            value={NomPromo}
                                            onChange={e =>{setNomPromo(e.target.value)}}

                                        />
                                    </div>
                                </Col>

                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">Date Début</Label>
                                <Col md={12} className="pr-0">
                                    <DatePicker
                                        className="form-control ddate"
                                        // selected={dateDebut}
                                        onChange={date => changeDateDebut(date)}
                                         dateFormat="dd-MM-yyyy"
                                        required
                                        value={dateDebut}
                                        disabled={disabledDateDebut}


                                    />
                                </Col>
                            </div>
                        </Col>

                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">Date Fin</Label>
                                <Col md={12} className="pr-0">
                                    <DatePicker
                                        className="form-control ddate"
                                       // selected={dateFin}
                                        onChange={date => changeDateFin(date)}
                                        // dateFormat="dd-MM-yyyy"
                                        value={dateFin}
                                        required
                                        disabled={disabledDateFin}


                                    />
                                </Col>
                            </div>
                        </Col>

                        <Col md={4} sm={6}>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">Taux</Label>
                                <Col md={12} className="pr-0">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input
                                            type="number"
                                            value={promo.taux}
                                            onChange={e => change_taux_total(e)}
                                        />

                                    </div>
                                </Col>
                            </div>
                        </Col>
                        {diplayArticles ? (
                            <Col md={4} sm={6}>
                                <div className="mb-3">

                                    <Col md="6">
                                        <div className="mb-3 mt-3">
                                            <div className="form-check form-check-inline">

                                            </div>
                                            &nbsp;
                                            <div className="form-check form-check-inline">

                                            </div>
                                            &nbsp;
                                            <div className="form-check form-check-inline">

                                            </div>
                                            &nbsp;
                                            <div className="form-check form-check-inline">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-Input"
                                                    id="change_all"
                                                    onChange={e => change_all(e)}
                                                    checked={checkMode}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="change_all"
                                                >
                                                    Changer tous
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>

                                </div>
                            </Col>
                        ) : null}





                        {diplayArticles ? (


                            <div>
                                <Row>
                                    <Col md={3}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Marque
                                            </Label>

                                        </div>
                                        <Select
                                            options={marque}
                                            isSearchable={true}
                                            classNamePrefix="select2-selection"
                                            onChange={e => {
                                                setSelectMarque(e);

                                            }}

                                        />
                                    </Col>
                                    <Col md={3}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-arround",
                                            }}
                                        >
                                            <Label for="basicpill-firstname-input1">
                                                Groupe
                                            </Label>

                                        </div>
                                        <Select
                                            options={groupe}
                                            isSearchable={true}
                                            classNamePrefix="select2-selection"
                                            onChange={e => setSelectGroupe(e)}
                                            styles={{
                                                menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                            }}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-arround",
                                                }}
                                            >
                                                <Label for="basicpill-firstname-input1">
                                                    Famille
                                                </Label>

                                            </div>
                                            <Select
                                                options={famille}
                                                isSearchable={true}
                                                classNamePrefix="select2-selection"
                                                onChange={e => setSelectFamille(e)}
                                                styles={{
                                                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-arround",
                                                }}
                                            >
                                                <Label for="basicpill-firstname-input1">
                                                    Type Article
                                                </Label>

                                            </div>
                                            <Select
                                                options={type_article}
                                                isSearchable={true}
                                                classNamePrefix="select2-selection"
                                                onChange={e => setSelectTypeArticle(e)}
                                                styles={{
                                                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <table className="table table-bordered mt-4">
                                    <thead>
                                        <tr>

                                            <th style={{ textAlign: "center" }} scope="col">
                                                Code
                                            </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Prix de achat
                                            </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Prix de vente                     </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Taux de promotion
                                            </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Prix apres promotion
                                            </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Cocher { }
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-Input"
                                                    onChange={e => check_all(e)}
                                                    checked={checked_all}

                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    {displayedArticles.map((el, index) => (
                                        <tbody key={index}>
                                            {el && <tr>

                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el && el.nom}
                                                </td>
                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el && el.prix_achat_ht}                </td>
                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el && el.prix_vente_ht}
                                                </td>
                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    <Input type="number" value={el.etat === 1 ? el.taux : ''}
                                                        onChange={e => change_taux(e, index, el.id)}
                                                        disabled={!el.etat}
                                                    />
                                                </td>
                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    <Input value={el.prix_apres_prom !== undefined ? el.prix_apres_prom : ""} disabled />
                                                </td>
                                                <td className="col-md-1">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Input
                                                            type="checkbox"
                                                            className="form-check-Input"
                                                            onChange={e => coche(e, index, el.id)}
                                                            checked={el.etat === 1 ? true : false}
                                                        />
                                                    </div>
                                                </td>

                                            </tr>}
                                        </tbody>
                                    ))}


                                </table>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",

                                    }}
                                    className="text-center mt-4"
                                >
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={save}
                                    >
                                        Confirmer
                                    </button>
                                </div>





                            </div>



                        ) : null}


                        {displayBlockClient ? (


                            <div>
                                <Row>

                                    <Col md={3}>
                                        <div className="mb-3">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-arround",
                                                }}
                                            >
                                                <Label for="basicpill-firstname-input1">
                                                    Famille
                                                </Label>

                                            </div>
                                            <Select
                                                options={famille_Client}
                                                isSearchable={true}
                                                classNamePrefix="select2-selection"
                                                onChange={e => setselected_familleClient(e)}
                                                styles={{
                                                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                                }}
                                                value={selected_familleClient}

                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-arround",
                                                }}
                                            >
                                                <Label for="basicpill-firstname-input1">
                                                    CIN
                                                </Label>

                                            </div>
                                            <Input
                                                onChange={e => setCIN_Client(e.target.value)}
                                                styles={{
                                                    menu: base => ({ ...base, maxHeight, overflowY: 'auto' })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <table className="table table-bordered mt-4">
                                    <thead>
                                        <tr>

                                            <th style={{ textAlign: "center" }} scope="col">
                                                CIN
                                            </th>

                                            <th style={{ textAlign: "center" }} scope="col">
                                                Nom client

                                            </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Telephone        </th>
                                            <th style={{ textAlign: "center" }} scope="col">
                                                Famille de client         </th>

                                            <th style={{ textAlign: "center" }} scope="col">
                                                Cocher { }
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-Input"
                                                    onChange={e => check_all_clients(e)}
                                                    checked={checked_all_clients}

                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    {displayedClients.map((el, index) => (
                                        <tbody key={index}>
                                            <tr>

                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el.cin}
                                                </td>

                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el.nom}
                                                </td>

                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el.telephone}
                                                </td>
                                                <td style={{ textAlign: "center", width: "10%" }}>
                                                    {el.famille.label}
                                                </td>



                                                <td className="col-md-1">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Input
                                                            type="checkbox"
                                                            className="form-check-Input"
                                                            onChange={e => coche_client(e, index, el.id)}
                                                            checked={el.etat === 1 ? true : false}
                                                        />
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    ))}

                                </table>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={save}
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </div>



                        ) : null}





                    </Row>



                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    );
};

ModifPromo.propTypes = {
    back: PropTypes.object,
    setSection: PropTypes.func.isRequired,
    t: PropTypes.any
};

export default withTranslation()(ModifPromo);
