import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Table,
  Spinner,
} from "reactstrap"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddFactClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const user_id = userAuth.user.id
  const annee =userAuth.user.year
  //
  const [loading, setLoading] = useState(false)
  const [numFact, setNumFact] = useState("")
  const [dateFact, setDateFact] = useState(new Date())
  const [devisBc, setDevisBc] = useState("")
  const [selectDevisBc, setSelectDevisBc] = useState("")
  const [dateDevisBc, setDateDevisBc] = useState(new Date())
  const [client, setClient] = useState([])
  const [selectCodeClient, setSelectCodeClient] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [adresse, setAdresse] = useState("")
  const [selectFamilleClient, setSelectFamilleClient] = useState("")
  const [etatFodec, setEtatFodec] = useState(0)
  const [codeTva, setCodeTva] = useState("")
  const [remiseExcep, setRemiseExcep] = useState("")
  const [selectRepre, setSelectRepre] = useState("")
  const [etatRegimeForf, setEtatRegimeForf] = useState(0)
  const [depot, setDepot] = useState([])
  const [selectDepot, setSelectDepot] = useState([])
  const [modeRegl, setModeRegl] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [observation, setObservation] = useState("")
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [arrayProduit, setArrayProduit] = useState([
    {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots:[],
      product_selected_depot:null



    },
  ])
  const [produits, setProduits] = useState([])
  const [selectProduit, setSelectProduit] = useState("")
  const [indexOfProduit, setIndexOfProduit] = useState("")
  //
  const [prixHT, setPrixHT] = useState("")
  const [designation, setDesignation] = useState("")
  const [tva, setTva] = useState("")
  const [quantite, setQuantite] = useState("")
  const [totalHT, setTotalHT] = useState("")
  const [totalTTC, setTotalTTC] = useState("")
  const [totalTva, setTotalTva] = useState("")
  const [totalRemise, setTotalRemise] = useState("")
  const [remise, setRemise] = useState("")
  const [totalFodec, setTotalFodec] = useState("")
  const [fodec, setFodec] = useState("")
  const [selectDepotArticle ,setSelectDepotArticle]= useState("")
  //
  const [etatBlocFactClient, setEtatBlocFactClient] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);
  const [changedDevis, setchangedDevis] =useState(0)
  const [taux_promotion_client, setTaux_promotion_client] = useState(0);


  
  const [ExistePromotionClient, setExistePromotionClient] = useState(false)
  const [ExistePromotionArticle, setExistePromotionArticle] = useState(false)
  const [selectedTypePromotion, setselectedTypePromotion] = useState("all")
  const [initTauxPromoClient, setinitTauxPromoClient] = useState(0)
  const options = [

    {value: 'none', label : 'Select...'},
    { value: 'Articles', label: 'Sur articles' },
    { value: 'Client', label: 'Sur client' },

  ]
  const selectOptions = options.slice(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [reloadData, setReloadData] = useState(false)
  const [idPromo ,setIdPromo]= useState(0) 
  const [typePromoDevis, setTypePromoDevis]=useState("")






  //
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }
  //
  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(async () => {
    setselectedTypePromotion(options[0])
    const res = await API.post("facture/client/getnum",{
      annee:annee
    }
    ).then(res => {
      setNumFact(res.data.FactureClient)
      setLoading(true)
    })
    const resE = await API.get("bon/livraison/client/devi_boncommande").then(
      resE => {
        setDevisBc(resE.data.DevisBcClient)
      }
    )
    const resC = await API.get("client/select").then(resC => {
      setClient(resC.data.Client)
    })
    const resT = await API.get("mode/reglement/select").then(rest => {
      setModeRegl(rest.data.ModeReglement)
    })
  
    const resD = await API.get("depot/select").then(resA => {
      setDepot(resA.data.Depot)
    })

    const resVerif = await API.post("article/verif_exist_promos_article",
    {
      date: dateFact
    }).then(resVerif => {
      setExistePromotionArticle(resVerif.data)

    })
    getDevisBcClient(selectCodeClient)
   
    setReloadData(false)
  }, [dateFact])
  //selection_depot_pour_produit
 

  const addLigne = () => {
    let element = {
      produit_id: "",
      designation: "",
      fodec: "",
      qte: "",
      prix_ht: "",
      remise: "",
      montant_ht: 0,
      tva: "",
      montant_ttc: 0,
      montant_remise: 0,
      montant_fodec: 0,
      depot_id: "",
      depots:[],
      product_selected_depot:null


      
    }
    setArrayProduit([...arrayProduit, element])
  }
  const select_articleDepot = (depot, indexOfProduit) => {
  
  
    const selectedOption = {
      value: depot.value,
      label: depot.label,
    };

  
    setArrayProduit((prevArrayProduit) =>
      prevArrayProduit.map((el, id) =>
        id === indexOfProduit
          ? {
              ...el,
              depot_id: depot.value,
              product_selected_depot: selectedOption,

            }
          : el
      )
    );
  };
  

 

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayProduit.splice(indexLigne, 1)
    setModal(false)
  }

  useEffect(() => {
    
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [modal ,selectCodeClient,taux_promotion_client])

  const getDevisBcClient = async selectCodeClient => {
    
    setArrayProduit([
      {
        produit_id: "",
        designation: "",
        fodec: "",
        qte: "",
        prix_ht: "",
        remise: "",
        montant_ht: 0,
        tva: "",
        montant_ttc: 0,
        montant_remise: 0,
        montant_fodec: 0,
        depot_id: "",
        depots:[],
        product_selected_depot:null
  
  
  
      }

    ])
    if (selectCodeClient != null) {
      var client_id = selectCodeClient.value
    } else {
      var client_id = ""
    }
    
   
  setSelectDevisBc("")
  setTypePromoDevis(null)


    // get Devis/BC du client
    if(selectCodeClient){
      const resD = await API.post("bon/livraison/client/get_client_by_bon_devi", {
        id: client_id,
      }).then(resD => {
        setDevisBc(resD.data.DevisBcClient)
      })
    }
    else{
      const resE = await API.get("bon/livraison/client/devi_boncommande").then(
        resE => {
          setDevisBc(resE.data.DevisBcClient)
        }
      )
    }
    
    //
    const res = await API.post("client/get_by_id", {
      id: client_id,
      date:dateFact
    }).then(res => {
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
      setEtatBlocFactClient(res.data.Client.bloqueFacturation)
      if (res.data.Client.promotions && res.data.Client.promotions.length > 0 && res.data.Client.promotions[0].taux) {
        setTaux_promotion_client(res.data.Client.promotions[0].taux);
        setinitTauxPromoClient(res.data.Client.promotions[0].taux);
        setExistePromotionClient(true)
      }
      else {
        setTaux_promotion_client(0);
        setinitTauxPromoClient(0);
        setExistePromotionClient(false)

      }

      setSelectCodeClient(selectCodeClient)
    })
  }
  useEffect (()=> {
    if (selectCodeClient.value == null || selectDevisBc.value === "") {
      setErrorMessage('Veuillez sélectionner un client ou bien Devis/BC.');

    } else if (selectedTypePromotion.value === "none"  && ExistePromotionArticle && ExistePromotionClient && (selectDevisBc.value === "" ||selectDevisBc.value ===undefined )) {
      setErrorMessage('Veuillez sélectionner un type de promotion ');}
      else {
      setErrorMessage('');
    }

  },[selectCodeClient,selectedTypePromotion,ExistePromotionArticle,ExistePromotionClient])

  const getClientDevisBc = async selectDevisBc => {
    setchangedDevis(changedDevis+1)
    if (selectDevisBc != null) {
      var devis_bc_id = selectDevisBc.value
    } else {
      var devis_bc_id = ""
    }
    setSelectDevisBc(selectDevisBc)
    // Verifier si DEVIS ou BC
    let num = selectDevisBc.label.substring(0, 3)
    // **DEVIS** //
    /*if ("D22" == num) {
      const res = await API.post("devi/get_client_by_devi", {
        id: devis_bc_id,
      }).then(res => {
        setSelectCodeClient(res.data.Client.client)
        setRaisonSocial(res.data.Client.raisonSocial)
        setAdresse(res.data.Client.adresseLivraison)
        setSelectFamilleClient(res.data.Client.famille_client)
        setEtatFodec(res.data.Client.fodec)
        setCodeTva(res.data.Client.codeTVA)
        setSelectRepre(res.data.Client.Representant)
        setEtatRegimeForf(res.data.Client.regimeForfutaire)
        setSelectModeRegl(res.data.Client.ModeReglement)
        setEtatBlocFactClient(res.data.Client.bloqueFacturation)
      })
      const resD = await API.post("devi/get_ligne_by_devi", {
        id: devis_bc_id,
      }).then(resD => {
        setDateDevisBc(new Date(resD.data.Article.date_devis))
        setArrayProduit(resD.data.Article.ligne)
        setTotalFodec(resD.data.Article.total_fodec)
        setTotalHT(resD.data.Article.total_ht)
        setTotalRemise(resD.data.Article.total_remise)
        setTotalTTC(resD.data.Article.total_ttc)
        setTotalTva(resD.data.Article.total_tva)
      })
    }
    // **BC** //
    else if ("C22" == num) {
      const res = await API.post("bon/comande/client/get_client_by_commande", {
        id: devis_bc_id,
      }).then(res => {
        setSelectCodeClient(res.data.Client.client)
        setRaisonSocial(res.data.Client.raisonSocial)
        setAdresse(res.data.Client.adresseLivraison)
        setSelectFamilleClient(res.data.Client.famille_client)
        setEtatFodec(res.data.Client.fodec)
        setCodeTva(res.data.Client.codeTVA)
        setSelectRepre(res.data.Client.Representant)
        setEtatRegimeForf(res.data.Client.regimeForfutaire)
        setSelectModeRegl(res.data.Client.ModeReglement)
        setEtatBlocFactClient(res.data.Client.bloqueFacturation)
      })
      const resD = await API.post("bon/comande/client/get_ligne_by_commande", {
        id: devis_bc_id,
      }).then(resD => {
        setDateDevisBc(new Date(resD.data.Article.date_cmd))
        setArrayProduit(resD.data.Article.ligne)
        setTotalFodec(resD.data.Article.total_fodec)
        setTotalHT(resD.data.Article.total_ht)
        setTotalRemise(resD.data.Article.total_remise)
        setTotalTTC(resD.data.Article.total_ttc)
        setTotalTva(resD.data.Article.total_tva)
      })
    }*/
    const res = await API.post("bon/livraison/client/get_client_by_devis_or_commande", {
      numero:selectDevisBc.label,
      date:dateFact
    }).then(res => {
      setRaisonSocial(res.data.Client.raisonSocial)
      setAdresse(res.data.Client.adresseLivraison)
      setSelectFamilleClient(res.data.Client.famille_client)
      setEtatFodec(res.data.Client.fodec)
      setCodeTva(res.data.Client.codeTVA)
      setSelectRepre(res.data.Client.Representant)
      setEtatRegimeForf(res.data.Client.regimeForfutaire)
      setSelectModeRegl(res.data.Client.ModeReglement)
      setSelectCodeClient(res.data.Client.client)
      if (res.data.Client.promotions && res.data.Client.promotions.length > 0 && res.data.Client.promotions[0].taux) {
        setTaux_promotion_client(res.data.Client.promotions[0].taux);
      }
      else {
        setTaux_promotion_client(0);
      }

     
     
     });


     const res1 = await API.post("bon/livraison/client/get_ligne_by_devis_or_commande", {
      numero: selectDevisBc.label,
  }).then(res1 => {
      
  if (res1.data.Article.date_devis!= undefined) {
      setDateDevisBc(new Date(res1.data.Article.date_devis));
  } else {
      setDateDevisBc(new Date(res1.data.Article.date_cmd));
  }
      setArrayProduit(res1.data.Article.ligne)
      setTotalFodec(res1.data.Article.total_fodec)
      setTotalHT(res1.data.Article.total_ht)
      setTotalRemise(res1.data.Article.total_remise)
      setTotalTTC(res1.data.Article.total_ttc)
      setTotalTva(res1.data.Article.total_tva)
      setTypePromoDevis(res1.data.Article.promotion)

      //setSelectCodeClient(selectCodeClient)
      //setArrayProduit(res1.data.Articles)
      
  });
  }

  const getProduits = async (selectProduit, indexOfProduit) => {

    const selectedOption = {
      value: undefined,
      label: undefined,
    };

  
    setArrayProduit((prevArrayProduit) =>
      prevArrayProduit.map((el, id) =>
        id === indexOfProduit
          ? {
              ...el,
             
              depot_id: undefined,
              product_selected_depot: selectedOption,

            }
          : el
      )
    );


    if (selectProduit != null) {
      var produit_id = selectProduit.value
    
    } else {
      var produit_id = ""
    }
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }
    setIndexOfProduit(indexOfProduit)
    const res = await API.post("article/get_by_id", {
      id: produit_id,
      date:dateFact
    })
      .then(res => {
        setArrayProduit(
          arrayProduit.map((el, id) =>
            id === indexOfProduit
              ? Object.assign(el, {
                  produit_id: produit,
                  designation: res.data.Article.designation,
                  prix_ht: res.data.Article.prix_vente_ht,
                  tva: res.data.Article.tva,
                  fodec: res.data.Article.fodec,
                  depots:res.data.Article.depots,
                  product_selected_depot:selectedOption,
                  remise:res.data.Article.remise,
                  qte:0,
                  montant_ht: 0,
                  montant_ttc: 0,
                })
              : el
          )
        )
      })
      .then(() => {
        var tot_ht = arrayProduit
          .map(elem => elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ht = parseFloat(tot_ht).toFixed(3)
        setTotalHT(total_ht)
        var tot_ttc = arrayProduit
          .map(elem => elem.montant_ttc)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_ttc = parseFloat(tot_ttc).toFixed(3)
        setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
        var tot_tva = arrayProduit
          .map(elem => elem.montant_ttc - elem.montant_ht)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_tva = parseFloat(tot_tva).toFixed(3)
        setTotalTva(total_tva)
        var tot_remise = arrayProduit
          .map(elem => elem.montant_remise)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_rem = parseFloat(tot_remise).toFixed(3)
        setTotalRemise(total_rem)
        var tot_fodec = arrayProduit
          .map(elem => elem.montant_fodec)
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        var total_fodec = parseFloat(tot_fodec).toFixed(3)
        setTotalFodec(total_fodec)
      })
    
  }

  useEffect(() => {
    if (selectProduit != null) {
      var produit = selectProduit
    } else {
      var produit = ""
    }

    const selectedOption = {
      value: "",
      label: "",
    };

    setArrayProduit(
      arrayProduit.map((el, id) =>
        id === indexOfProduit
          ? Object.assign(el, {
              produit_id: produit,
              designation: designation,
              prix_ht: prixHT,
              tva: tva,
              fodec: fodec,
              qte:0,
              montant_ht: 0,
              montant_ttc: 0,
              product_selected_depot:selectedOption
            })
          : el
      )
    )
  }, [selectProduit])

  const addQuantiteGrt = (e, indexOfQte) => {
    if (e < 0) {
      toast.error("Veuillez vérifier la quantite gratuite !", {
        containerId: "A",
      })
    } else {
      var qte_gratuit = e
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfQte
            ? Object.assign(el, {
                qte_gratuit: qte_gratuit,
              })
            : el
        )
      )
    }
  }

  const addQuantite = (e, indexOfQte) => {
 
      setQuantite(e)
      var qte = e
      var prixHT = arrayProduit[indexOfQte].prix_ht
      var tva = arrayProduit[indexOfQte].tva.label
      var fodec = arrayProduit[indexOfQte].fodec
      var remise = arrayProduit[indexOfQte].remise
      var montantHT = prixHT * qte
      // calcule total HT avec remise
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if (fodec == 1) {
        var montantFodec = (montantRemise * 0.07)
      } else if (fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfQte
            ? Object.assign(el, {
                qte: qte,
                montant_ht: parseFloat(montantRemise).toFixed(3),
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
            : el
        )
      )
    
  }

  useEffect(() => {
   
    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [quantite,selectCodeClient,taux_promotion_client])

  const addRemise = (e, indexOfRemise) => {
    if (e > 100 || e < 0) {
      toast.error("Veuillez vérifier la remise !", {
        containerId: "A",
      })
    } else {
      setRemise(e)
      var remise = e
      var qte = arrayProduit[indexOfRemise].qte
      var prixHT = arrayProduit[indexOfRemise].prix_ht
      var fodec = arrayProduit[indexOfRemise].fodec
      var tva = arrayProduit[indexOfRemise].tva.label
      // calcule HT avec remise
      var montantHT = prixHT * qte
      var montantRemise = montantHT - (montantHT * remise) / 100
      // calcule total remise
      var totalRemise = montantHT - montantRemise
      // calcule fodec
      if (fodec == 1) {
        var montantFodec = (montantRemise *0.07) 
      } else if (fodec == 0) {
        var montantFodec = 0
      }
      // calcule TTC
      var montantHT_fodec = montantRemise + montantFodec
      var montantTTC =
        (montantHT_fodec * tva) / 100 + parseFloat(montantHT_fodec)
      setArrayProduit(
        arrayProduit.map((el, id) =>
          id === indexOfRemise
            ? Object.assign(el, {
                montant_ht: parseFloat(montantRemise).toFixed(3),
                remise: remise,
                montant_remise: parseFloat(totalRemise).toFixed(3),
                montant_ttc: parseFloat(montantTTC).toFixed(3),
                montant_fodec: parseFloat(montantFodec).toFixed(3),
              })
            : el
        )
      )
    }
  }

  useEffect(() => {
    

    var tot_ht = arrayProduit
      .map(elem => elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ht = parseFloat(tot_ht).toFixed(3)
    setTotalHT(total_ht)
    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC((parseFloat(tot_ttc) - (parseFloat(tot_ttc) * (taux_promotion_client / 100))).toFixed(3))
    var tot_tva = arrayProduit
      .map(elem => elem.montant_ttc - elem.montant_ht)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_tva = parseFloat(tot_tva).toFixed(3)
    setTotalTva(total_tva)
    var tot_remise = arrayProduit
      .map(elem => elem.montant_remise)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_rem = parseFloat(tot_remise).toFixed(3)
    setTotalRemise(total_rem)
    var tot_fodec = arrayProduit
      .map(elem => elem.montant_fodec)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_fodec = parseFloat(tot_fodec).toFixed(3)
    setTotalFodec(total_fodec)
  }, [remise,selectCodeClient,taux_promotion_client])

  const save = async () => {
    var all_depots_selected = true
    setButtonClicked(true)
    let count = 0
    if (selectCodeClient != "") {
      if(selectModeRegl.label != "") {
        if (arrayProduit.length > 0) {
            for (let index = 0; index < arrayProduit.length; index++) {
              let qte = arrayProduit[index].qte
              
              if (qte <= 0) {
                count++
              }
              let depot = arrayProduit[index].depot_id
              if(!depot){
                all_depots_selected=false
              }
            }
           
                
             
              
              
           //   if (index == arrayProduit.length - 1) {
            
            if(all_depots_selected){
                if (count == 0) {
                  if (dateFact != "") {
                    let date = dateFact
                    let month = "" + (date.getMonth() + 1)
                    let day = "" + date.getDate()
                    let year = date.getFullYear()
                    if (month.length < 2) month = "0" + month
                    if (day.length < 2) day = "0" + day
                    var convertDateFact = [year, month, day].join("-")
                  } else {
                    var convertDateFact = ""
                  }
                  let dateDBC = dateDevisBc
                  let monthDBC = "" + (dateDBC.getMonth() + 1)
                  let dayDBC = "" + dateDBC.getDate()
                  let yearDBC = dateDBC.getFullYear()
                  if (monthDBC.length < 2) monthDBC = "0" + monthDBC
                  if (dayDBC.length < 2) dayDBC = "0" + dayDBC
                  var convertDateDBC = [yearDBC, monthDBC, dayDBC].join("-")
                  // Verifier si DEVIS ou BC
                //  let num = selectDevisBc.label.substring(0, 3)
                  //
                  const res = await API.post("facture/client/add", {
                    annee: annee,
                    user_id,
                    devis_bc_label: selectDevisBc.label,
                    idPromo: idPromo,
                    // information
                    num_fact: numFact,
                    date_fact: convertDateFact,
                    devis_bc_id: selectDevisBc.value,
                   // type_num: num,
                    date_devis_bc: convertDateDBC,
                    client_id: selectCodeClient.value,
                    raison_social: raisonSocial,
                    adresse: adresse,
                    famille_id: selectFamilleClient.value,
                    fodec: etatFodec,
                    code_tva: codeTva,
                    remise_excep: remiseExcep,
                    representant_id: selectRepre.value,
                    regime_forf: etatRegimeForf,
                    depot_id: selectDepot.value,
                    mode_regl_id: selectModeRegl.value,
                    // array
                    array: arrayProduit,
                    // information
                    observation: observation,
                    tot_remise: totalRemise,
                    tot_ht: totalHT,
                    tot_fodec: totalFodec,
                    tot_tva: totalTva,
                    avance_impot: "",
                    tot_ttc: totalTTC,
                  }).then(res => {
                   
                    return res.json();
                  })
                  .then(data => {
                  })
                  .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                      console.error('Erreur1:', error.response.data.error);
                      toast.error(error.response.data.error, {
                        containerId: "A",
                      })
                      setButtonClicked(false)

                    } else {
                      props.setSection(1)
                    }
                  });
                  
                } else {
                  toast.error("La quantité doit étre supérieur a 0", {
                    containerId: "A",
                  })
                  setButtonClicked(false)

                }
              }
           // }
            
            else{
              toast.error("Veuillez choisir un depot ", {
                containerId: "A",
              })
              setButtonClicked(false)


            }
           } else {
            toast.error("Veuillez choisir au moins un article", {
              containerId: "A",
            })
            setButtonClicked(false)


           }
            } else {
              toast.error("Veuillez choisir une mode de reglement", {
            containerId: "A",
              })
              setButtonClicked(false)

        }
     
    } else {
      toast.error("Veuillez selectionner un client", {
        containerId: "A",
      })
      setButtonClicked(false)

    }
  }
  useEffect (async() =>{
    if(!selectDevisBc){
    const res =await API.post ("bon/livraison/client/change_date",{
      Client_Id:selectCodeClient.value,
      date:dateFact,
      arrayProduit:arrayProduit,
      selected_type_promotion:selectedTypePromotion

    }).then(res => {
      setIdPromo(res.data.id_promo_article )

      if (selectedTypePromotion.value === "Client" && res.data[1] && res.data[1][0] && res.data[1][0].taux) {
        setTaux_promotion_client(res.data[1][0].taux)
        setinitTauxPromoClient(res.data[1][0].taux)
        setIdPromo(res.data[1][0].id )

      } else {
        setTaux_promotion_client(0)
        setinitTauxPromoClient(0)
        
       

      }
      if (res.data.no_change === 0) {
        setArrayProduit(res.data[0])
      }
      
      if (ExistePromotionArticle && ExistePromotionClient) {
        setProduits([])
      }else{
        setProduits(res.data.articles)
      }

    })
}}, [dateFact,selectedTypePromotion ,selectCodeClient])


   useEffect(async () => {
    const resP = await API.post("article/select", {
      date: dateFact,
      typePromo: selectedTypePromotion
    }).then(resP => {

      setProduits(resP.data.Article)
    })

  }, [selectedTypePromotion])
  const verifTypePromo =(e) =>{
    setselectedTypePromotion(e)
    if ( e.value === "Articles")
    {
      setTaux_promotion_client(0)
    }else {
      setTaux_promotion_client(initTauxPromoClient)
    }

  }

  useEffect(()=> {
    
  
    if (ExistePromotionArticle && ExistePromotionClient) {
      setProduits([])
    } else if(ExistePromotionClient && !ExistePromotionArticle)  {
      setselectedTypePromotion(options[2])
    }
    else {
      setselectedTypePromotion(options[0])
    }

  },[ExistePromotionArticle,ExistePromotionClient])

  useEffect(async () => {
    //setTotalTTC(parseFloat(totalTTC)+1)

    var tot_ttc = arrayProduit
      .map(elem => elem.montant_ttc)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    var total_ttc = parseFloat(tot_ttc).toFixed(3)
    setTotalTTC(parseFloat(total_ttc) - (parseFloat(total_ttc) * (taux_promotion_client / 100)))
  
  }, [arrayProduit, selectedTypePromotion])


  const change_dateFact= (e)=> {
    setReloadData(true)
    setDateFact(e)


  }
  

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <Form>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">N° facture</Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    disabled
                    value={numFact}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    Date de la facture
                  </Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateFact}
                      onChange={e => change_dateFact(e)}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Devis/BC</Label>
                  <Select
                    options={devisBc}
                    isSearchable={true}
                    onChange={e => getClientDevisBc(e)}
                    formatGroupLabel={formatGroupLabel}
                    value={selectDevisBc}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Date Devis/BC</Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                      className="form-control ddate"
                      selected={dateDevisBc}
                      onChange={() => setDateDevisBc(e)}
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </Col>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Client
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <Select
                    isSearchable={true}
                    onChange={e => getDevisBcClient(e)}
                    value={selectCodeClient}
                    options={client}
                    //isDisabled={selectDevisBc != "" ? true : false}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Raison sociale</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Raison sociale"
                    value={raisonSocial}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Adresse</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={adresse}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Famille</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Famille"
                    value={selectFamilleClient.label}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatFodec == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Fodec
                    </Label>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Code TVA</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Code TVA"
                    value={codeTva}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Representant</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Representant"
                    value={selectRepre.label}
                    disabled
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="mb-3 mt-3">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="horizontal-customCheck"
                      checked={etatRegimeForf == 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="horizontal-customCheck"
                    >
                      Régime forfaitaire
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              
              <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Mode reglement</Label>
                  <Select
                    options={modeRegl}
                    isSearchable={true}
                    onChange={e => setSelectModeRegl(e)}
                    value={selectModeRegl}
                  />
                </div>
              </Col>
              {typePromoDevis &&  (
                <Col md={3}>
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    Type de promotion devis
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="type de promortion "
                    value={typePromoDevis.type}
                    disabled={true}
                    //onChange={e => setNumCmdInt(e.target.value)}
                  />
                </div>
              </Col>
              )}



              {ExistePromotionArticle && ExistePromotionClient && !selectDevisBc &&   (
                <Col md={3}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Type de promotion</Label>
                    <Select
                      options={selectOptions}
                      isSearchable={true}
                      onChange={e => verifTypePromo(e)}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Form>
          {reloadData ? (
  <h1>Chargement ...</h1> 
) : (<html>
          <CardTitle className="h4 mt-4">Details</CardTitle>
          <div>
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Article
                  </th>
                
                  <th style={{ textAlign: "center" }} scope="col">
                    Fodec
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Qte
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Gratuit
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    P.U.H.T
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Remise
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant HT
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    TVA
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant TTC
                  </th>

                  <th style={{ textAlign: "center" }} scope="col">
                    Depot
                  </th>

                 
                  {selectDevisBc == "" ? (
                    <th style={{ textAlign: "center" }} scope="col">
                      <i
                        style={{
                          color: "#a0ceb8",
                          cursor: "pointer",
                        }}
                        className="fas fa-plus"
                        onClick={addLigne}
                      />
                    </th>
                  ) : null}
                </tr>
              </thead>
              {arrayProduit.map((el, index) => (
                <tbody key={index}>
                  <tr>
                    <td style={{ textAlign: "center", width: "20%" }}>
                      <Select
                        options={produits}
                        isSearchable={true}
                        value={el.produit_id}
                        onChange={e => getProduits(e, index)}
                        isDisabled={selectDevisBc || !selectCodeClient}



                      />
                    </td>
              
                    <td style={{ textAlign: "center", width: "1%" }}>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="horizontal-customCheck"
                        checked={el.fodec == 1}
                        disabled={!selectCodeClient}

                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte}
                        min={0}
                        onChange={e => addQuantite(e.target.value, index)}
                        disabled={!selectCodeClient}


                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.qte_gratuit}
                        min={0}
                       onChange={e => addQuantiteGrt(e.target.value, index)}
                       disabled={!selectCodeClient}


                      />
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.prix_ht}
                        disabled={!selectCodeClient}


                      />
                    </td>
                    <td style={{ textAlign: "center", width: "8%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.remise}
                        min={0}
                        max={100}
                        onChange={e => addRemise(e.target.value, index)}
                        disabled={!selectCodeClient}

                      />
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ht}
                        disabled={!selectCodeClient}

                      />
                    </td>
                    <td style={{ textAlign: "center", width: "7%" }}>
                      <Input value={el.tva.label} disabled />
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="..."
                        value={el.montant_ttc}
                        disabled={!selectCodeClient}

                      />
                    </td>
                    <td style={{ textAlign: "center", width: "14%" }}>
                    <Select  
                        options={el.depots}
                        isSearchable={true}
                        onChange={e => select_articleDepot(e, index)}
                       // value={el.depot_id}
                       value={el.product_selected_depot}
                        
                      //onChange={e => setSelectDepot(e)}
                      />
                    </td>
                    {selectDevisBc == "" ? (
                      <td style={{ textAlign: "center", width: "1%" }}>
                        <i
                          style={{ color: "#cc0000" }}
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => toggle(index)}
                        />
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Total </CardTitle>
              <Row>
                <Col className="mt-3" md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">Observation</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Observation"
                      onChange={e => setObservation(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <tbody>
                  <tr>
                  <td colSpan={ 2}>
                        {taux_promotion_client > 0 && selectedTypePromotion.value  === "Client" &&


                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{ color: 'blue' }}
                          > Promotion pour le Client&nbsp;{taux_promotion_client}%
                          </Label>

                        }
                     
                      {typePromoDevis && typePromoDevis.type === "Client" && (

                          <Label
                            className="form-check-label"
                            htmlFor="horizontal-customCheck"
                            style={{ color: 'blue' }}
                          > Taux de promotion devis pour le Client &nbsp;{typePromoDevis.taux}%
                          </Label>

                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Total des remises :</td>
                      <td>{totalRemise}</td>
                    </tr>
                    <tr>
                      <td>Total HT :</td>
                      <td>{totalHT}</td>
                    </tr>
                    <tr>
                      <td>Total fodec :</td>
                      <td>{totalFodec}</td>
                    </tr>
                    <tr>
                      <td>Total TVA :</td>
                      <td>{totalTva}</td>
                    </tr>
                    <tr>
                      <td>Avance/Impot :</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Total TTC : </td>
                      <td>{totalTTC}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          </html>
)}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={buttonClicked}
                  onClick={save}
                >
                  Confirmer
                </button>
              </div>
            </Col>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            Chargement en cours ...{" "}
          </h4>
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          Suppression ligne{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddFactClient
AddFactClient.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
