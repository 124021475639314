import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddFamArt from "./AddFamArt"
import DataTableFamArt from "./DataTableFamArt"
import PropTypes from "prop-types"
//lang
import { withTranslation } from "react-i18next"

const FamArt = props => {
       //////permission//////
       const [userdata, setUserdata] = useState([])
       const [perm, setPerm] = useState({
         add: 0,
         delete:0,
         edit:0,
         imp:0,
         view:0
       });
     
       useEffect(async () => {
         const userAuth = JSON.parse(localStorage.getItem('userAuth'));
         console.log(userAuth)
         setUserdata(userAuth.user.userData)
     console.log(userAuth.user.userData)
       }, [])
     
     
       useEffect(() => {
       console.log(userdata)
         const typeArticleSubMenu = userdata.find(user => 
           user.sous_menus.some(subMenu => subMenu.sous_menu === "Familles Articles")
         );
     
         if (typeArticleSubMenu) {
           console.log(typeArticleSubMenu)
           const typeArticlePermission = typeArticleSubMenu.sous_menus.find(
             subMenu => subMenu.sous_menu === "Familles Articles"
           ).permissions[0];
           console.log(typeArticlePermission)
           setPerm(prevPerm => ({
             ...prevPerm,
             add: typeArticlePermission.add,
             delete:typeArticlePermission.delete,
             edit:typeArticlePermission.edit,
             imp:typeArticlePermission.imp,
             view:typeArticlePermission.view
           }));
         }
       }, [userdata]);
       console.log(perm)
     ///////////permission////
  const [section, setSection] = useState(1)
  const back = () => {
    setSection(1)
  }
  const renderBlock = section => {
    if (section === 1) {
      return <DataTableFamArt />
    } else if (section === 2 && perm.add ===1) {
      return <AddFamArt setSection={setSection} back={back} />
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Familles Articles")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {props.t("Familles Articles")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  {props.t("Ajouter famille d'article")}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(FamArt))
FamArt.propTypes = {
  t: PropTypes.any,
}
