import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../api';

const StatBl = () => {
    const [etatFacturationValues, setEtatFacturationValues] = useState({ total_fact: 0, facture_bl: 0 });

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await API.post('facture/client/get_stat_facture');
                const data = response.data.FactureClient;
                setEtatFacturationValues({
                    facture_bl: parseFloat(data.pourcentageBl),
                    totalFact: parseFloat(data.totalFact),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const getOptionFacturation = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 10, // Adjust the left position as needed
                data: ['Facture Bl', 'Total des factures'], // Update legend data
                textStyle: {
                    color: ['#74788d'],
                },
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'Total sales',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: [
                        { value: etatFacturationValues.facture_bl, name: 'Facture Bl' },
                        { value: etatFacturationValues.totalFact - etatFacturationValues.facture_bl, name: 'Total des factures' },
                    ],
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    };

    return (
        <React.Fragment>
            <p>Facture Bl</p>
            <ReactEcharts style={{ height: '350px' }} option={getOptionFacturation()} />
        </React.Fragment>
    );
};

export default StatBl;
