import usFlag from "../assets/images/flags/us.jpg"
import tunisie from "../assets/images/flags/tun.jpg"
import france from "../assets/images/flags/france.jpg"

const languages = {
  fr: {
    label: "Français",
    flag: france,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
  ar: {
    label: "Arabe",
    flag: tunisie,
  },
}

export default languages
