import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Label,
  CardTitle,
  Form,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ReactEcharts from 'echarts-for-react';

import Select from "react-select"
const StatRegCli = () => {
    const [etatFacturationValues, setEtatFacturationValues] = useState([]);
    //setEtatFacturationValuesBanq
    const [etatFacturationValuesBanq, setEtatFacturationValuesBanq] = useState([]);
    const [etatFacturationValuesfact, setEtatFacturationValuesFact] = useState([]);
    //etatFacturationValuesCli
    const [etatFacturationValuesCli, setEtatFacturationValuesCli] = useState([]);

    const [selectFournisseur, setSelectFournisseur] = useState("")
    const [fournisseur, setFournisseur] = useState([])
    const [selectClient, setSelectClient] = useState("")
    const [client, setClient] = useState([])
    const [selectBanque, setSelectBanque] = useState("")
    const [banque, setBanque] = useState([])
    const [listbanque, setListBanque] = useState([])
    const [Statbanque, setStatBanque] = useState([])
    const [sommeTotalBanq, setSommeTotalBanq] = useState("")
    const [sommeTotalCli, setSommeTotalCli] = useState("")


    useEffect(async () => {
        const resN = await API.post("reglement/client/statistiqueBancaire").then(resN => {
            setListBanque(resN.data.retour.array_code)
            setStatBanque(resN.data.retour.array_banque)
           })
        const res = await API.get("client/select").then(res => {
          setClient(res.data.Client)
        })
        const resB = await API.get("banque/matricule/select").then(resB => {
          setBanque(resB.data.MatriculeBancaire)
        })
        
      }, [client])



 
    //   const searchClient = async selectClient => {
    //     if (selectClient != null) {
    //       setSelectClient(selectClient)
    //       var client_id = selectClient.value
    //       //stat_glob_for
    //       const res = await API.post("reglement/fournisseur/statistiqueBancaire", {
                    
    //         client_id:client_id
              
    //               }).then(res => {
                    
                  
    //                 console.log(res.data.retour.piecesByType)
              
    //                setEtatFacturationValuesFour(res.data.retour.piecesByType);
    //               })
    //     } else {
    //         setSelectBanque("")
    //     }
    //   }

 
  const getOption = () => {
    const months = listbanque;

    const monthData = months.map((code) => {
        const matchingBanque = Statbanque.find((banqueData) => banqueData.banque.code === code);
        const value = matchingBanque ? parseFloat(matchingBanque.piecesByType.length > 0 ? matchingBanque.piecesByType[0].totalMontant : 0) : 0;
        
        return {
            name: code,
            value: value,
        };
    });
 

    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3c4ccf", "#02a499", "#38a4f8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788d"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788d"],
        },
    };
};
//   const getOption = () => {
//     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
//     const monthData = months.map((monthName, index) => {
//         const matchingData = etatFacturationValues.find(item => item.month === index + 1); // Adjust month index
//         const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;
//         return {
//             name: monthName,
//             value: value,
//         };
//     });
//     return {
//         grid: {
//             zlevel: 0,
//             x: 50,
//             x2: 50,
//             y: 30,
//             y2: 30,
//             borderWidth: 0,
//             backgroundColor: "rgba(0,0,0,0)",
//             borderColor: "rgba(0,0,0,0)",
//         },
//         tooltip: {
//             trigger: "axis",
//             axisPointer: {
//                 type: "cross",
//                 crossStyle: {
//                     color: "#999",
//                 },
//             },
//         },
//         toolbox: {
//             feature: {
//                 dataView: { show: true, readOnly: false },
//                 magicType: { show: true, type: ["line", "bar"] },
//                 restore: { show: true },
//                 saveAsImage: { show: true },
//             },
//         },
//         color: ["#3C4CCF", "#02A499", "#38A4F8"],
//         legend: {
//             data: ["Total Montant"],
//             textStyle: {
//                 color: ["#74788D"],
//             },
//         },
//         xAxis: [
//             {
//                 type: "category",
//                 data: months, // Use the month names as categories
//                 axisPointer: {
//                     type: "shadow",
//                 },
//                 axisLine: {
//                     lineStyle: {
//                         color: "#74788D",
//                     },
//                 },
//             },
//         ],
//         yAxis: [
//             {
//                 type: "value",
//                 name: "Montant",
//                 axisLabel: {
//                     formatter: "{value}",
//                 },
//                 axisLine: {
//                     lineStyle: {
//                         color: "#74788D",
//                     },
//                 },
//             },
//         ],
//         series: [
//             {
//                 name: "Total Montant",
//                 type: "bar",
//                 data: monthData, // Use the month data here
//             },
//         ],
//         textStyle: {
//             color: ["#74788D"],
//         },
//     };
// };

// const search = async () => {
//   if (selectFournisseur != null && selectBanque!=null) {
//     var fournisseur_id = selectFournisseur.value
//     var Banque_id = selectBanque.value
//     //reglement/fournisseur/statistiqueFournisseur
//     const res = await API.post("reglement/fournisseur/statistiqueFournisseur", {
//       fournisseur_id: fournisseur_id,
//       matricule_bancaire_id:Banque_id

//     }).then(res => {
      
//       setBlockList(true)
//       setEtatFacturationValues(res.data.reglements);
//       setEtatFacturationValuesBanq(res.data.piece[0]);
//     })
//   }
// }

const searchBanque = async selectBanque => {
    if (selectBanque != null) {
      setSelectBanque(selectBanque)
      var Banque_id = selectBanque.value
      const res = await API.post("reglement/client/statistiqueClient", {
                
                matricule_bancaire_id:Banque_id
          
              }).then(res => {
                
              
          
               setEtatFacturationValuesBanq(res.data.retour.piecesByType);
               setSommeTotalBanq(res.data.retour.totalMontantTotal)
              })
    } else {
        setSelectBanque("")
    }
  }
  const searchClient = async selectClient => {
    if (selectClient != null) {
      setSelectClient(selectClient)
      var client_id = selectClient.value
      //stat_glob_for
      const res = await API.post("reglement/client/stat_glob_cli", {
                
        client_id:client_id
          
              }).then(res => {
                
              
                console.log(res.data.retour.piecesByType)
          
               setEtatFacturationValuesCli(res.data.retour.piecesByType);
               setSommeTotalCli(res.data.retour.totalMontantTotal)

              })
    } else {
        setSelectBanque("")
    }
  }

  const getOptionCli = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const monthData = months.map((monthName, index) => {
        const matchingData = etatFacturationValuesCli.find(item => item.month === index + 1); // Adjust month index
        const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;
        return {
            name: monthName,
            value: value,
        };
    });
    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3C4CCF", "#02A499", "#38A4F8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788D"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788D"],
        },
    };
};
  const getOptionBanq = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const monthData = months.map((monthName, index) => {
        const matchingData = etatFacturationValuesBanq.find(item => item.month === index + 1); // Adjust month index
        const value = matchingData ? parseFloat(matchingData.totalMontant) : 0;
        return {
            name: monthName,
            value: value,
        };
    });
    return {
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999",
                },
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
        color: ["#3C4CCF", "#02A499", "#38A4F8"],
        legend: {
            data: ["Total Montant"],
            textStyle: {
                color: ["#74788D"],
            },
        },
        xAxis: [
            {
                type: "category",
                data: months, // Use the month names as categories
                axisPointer: {
                    type: "shadow",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                name: "Montant",
                axisLabel: {
                    formatter: "{value}",
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788D",
                    },
                },
            },
        ],
        series: [
            {
                name: "Total Montant",
                type: "bar",
                data: monthData, // Use the month data here
            },
        ],
        textStyle: {
            color: ["#74788D"],
        },
    };
};


  return (
    <React.Fragment>
<p>chiffre daffaire:Banques</p>
<ReactEcharts style={{ height: "350px" }} option={getOption()} /> 
   <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">banque</Label>
                <Select
                options={banque}
                isSearchable={true}
                onChange={e => searchBanque(e)}              />
              </div>
            </Col>
            <p>chiffre daffaire par banque</p>
          <ReactEcharts style={{ height: "350px"  }} option={getOptionBanq()} />
          <div style={{ display: "flex" }}>
  <span style={{ marginRight: "10px" }}>Somme :</span>
  <input
    type="number"
    value={sommeTotalBanq}

    // onBlur={() => handleBlur(index)}
    // onChange={e => addMontant(e, index)}
    style={{
      textAlign: "center",
      borderRadius: "5px",
      border: "solid 1px #B3B3B3",
    }}
    disabled
  />
</div>
           <Form className="mt-4">
          <Row>
          <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Client</Label>
                <Select
                options={client}
                isSearchable={true}
                onChange={e => searchClient(e)}  
              />
              </div>
            </Col>
            <p>chiffre daffaire par client</p>
<ReactEcharts style={{ height: "350px" }} option={getOptionCli()} />
            
<div style={{ display: "flex" }}>
  <span style={{ marginRight: "10px" }}>Somme :</span>
  <input
    type="number"
    value={sommeTotalCli}
    // onBlur={() => handleBlur(index)}
    // onChange={e => addMontant(e, index)}
    style={{
      textAlign: "center",
      borderRadius: "5px",
      border: "solid 1px #B3B3B3",
    }}
    disabled
  />
</div>
          </Row>
          
          </Form>
       
       
        <div>
         
        </div>

    </React.Fragment>
  )
}

export default StatRegCli