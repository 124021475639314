import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../../api"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Col,
  Input,
  Form,
  Label,
  Row,
  CardTitle,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Container,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"

const EditClient = props => {
  var userAuth = JSON.parse(localStorage.getItem("userAuth"))
  const userName = userAuth.user.name
  //
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  const [activeTab, setactiveTab] = useState("1")
  // information
  const [code, setCode] = useState("")
  const [codeCompt, setCodeCompt] = useState("")
  const [raisonSocial, setRaisonSocial] = useState("")
  const [contact, setContact] = useState("")
  const [famille, setFamille] = useState([])
  const [selectFamille, setSelectFamille] = useState("")
  const [representant, setRepresentant] = useState([])
  const [selectRepresentant, setSelectRepresentant] = useState("")
  const [chargeAffaire, setChargeAffaire] = useState([])
  const [selectChargeAffaire, setSelectChargeAffaire] = useState("")
  const [gerant, setGerant] = useState("")
  const [cin, setCin] = useState("")
  const [dateOuverture, setDateOuverture] = useState(new Date())
  const [etatClient, setEtatClient] = useState(0)
  const [charge, setCharge] = useState([])
  const [selectCharge, setSelectCharge] = useState("")
  // solde
  const [soldeVenteCredit, setSoldeVenteCredit] = useState("")
  const [soldeVenteEcheance, setSoldeVenteEcheance] = useState("")
  const [plafondVenteCredit, setPlafondVenteCredit] = useState("")
  const [plafondVenteEcheance, setPlafondVenteEcheance] = useState("")
  const [echeanceSaisieRegl, setEcheanceSaisieRegl] = useState("")
  const [signalerDepassEche, setSignalerDepassEche] = useState("")
  const [etatAutDepassRemiseSec, setEtatAutDepassRemiseSec] = useState(0)
  const [autDepassRemiseSec, setAutDepassRemiseSec] = useState("")
  const [etatAutCalcSeuilCredAut, setEtatAutCalcSeuilCredAut] = useState(0)
  const [autCalcSeuilCredAut, setAutCalcSeuilCredAut] = useState("")
  // contact
  const [tel, setTel] = useState("")
  const [fax, setFax] = useState("")
  const [email, setEmail] = useState("")
  const [siteInternet, setSiteInternet] = useState("")
  // Adresse
  // *** Livraison *** //
  const [adresseLiv, setAdresseLiv] = useState("")
  const [gouvernoratLiv, setGouvernoratLiv] = useState([])
  const [selectGouvernoratLiv, setSelectGouvernoratLiv] = useState("")
  const [delegationLiv, setDelegationLiv] = useState([])
  const [selectDelegationLiv, setSelectDelegationLiv] = useState("")
  const [municipaliteLiv, setMunicipaliteLiv] = useState([])
  const [selectMunicipaliteLiv, setSelectMunicipaliteLiv] = useState("")
  const [codePostalLiv, setCodePostalLiv] = useState("")
  // *** Facturation *** //
  const [adresseFact, setAdresseFact] = useState("")
  const [gouvernoratFact, setGouvernoratFact] = useState([])
  const [selectGouvernoratFact, setSelectGouvernoratFact] = useState("")
  const [delegationFact, setDelegationFact] = useState([])
  const [selectDelegationFact, setSelectDelegationFact] = useState("")
  const [municipaliteFact, setMunicipaliteFact] = useState([])
  const [selectMunicipaliteFact, setSelectMunicipaliteFact] = useState("")
  const [codePostalFact, setCodePostalFact] = useState("")
  // Nature
  const [codeTva, setCodeTva] = useState("")
  const [etatTimbre, setEtatTimbre] = useState(0)
  const [etatFodec, setEtatFodec] = useState(0)
  const [etatRegime, setEtatRegime] = useState(0)
  const [etatAssujetti, setEtatAssujetti] = useState(0)
  const [etatComptabilite, setEtatComptabilite] = useState(0)
  const [etatPaieTva, setEtatPaieTva] = useState(0)
  const [etatExonore, setEtatExonore] = useState(0)
  const [etatExport, setEtatExport] = useState(0)
  const [etat, setEtat] = useState(1)
  const [exp, setExp] = useState("")
  const [debutExoneration, setDebutExoneration] = useState(new Date())
  const [finExoneration, setFinExoneration] = useState(new Date())
  const [mntPlafExo, setMntPlafExo] = useState("")
  // Divers
  const [formeJuridique, setFormeJuridique] = useState([])
  const [selectFormeJuridique, setSelectFormeJuridique] = useState("")
  const [modeReglement, setModeReglement] = useState([])
  const [selectModeRegl, setSelectModeRegl] = useState("")
  const [matBanc, setMatBanc] = useState([])
  const [selectMatBanc, setSelectMatBanc] = useState("")
  const [ribBanc, setRibBanc] = useState("")
  const [etatBloquerFact, setEtatBloquerFact] = useState(0)
  const [etatContentieux, setEtatContentieux] = useState(0)
  const [typeRemise, setTypeRemise] = useState([])
  const [selectTypeRemise, setSelectTypeRemise] = useState("")
  //***//
  const [dateCreation, setDateCreation] = useState("")
  const [cree, setCree] = useState("")
  const [datePrMvt, setDatePrMvt] = useState("")
  const [piecePrMvt, setPiecePrMvt] = useState("")
  const [dateDerMvt, setDateDerMvt] = useState("")
  const [pieceDerMvt, setPieceDerMvt] = useState("")
  // Observation
  const [observation, setObservation] = useState("")
  const [garantie, setGarantie] = useState("")
  // Avis
  const [avis, setAvis] = useState("")

  useEffect(async () => {
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("client/get_by_id", { id }).then(res => {
      setCode(res.data.Client.code),
        setCodeCompt(res.data.Client.codeComptable),
        setRaisonSocial(res.data.Client.raisonSocial),
        setContact(res.data.Client.contact),
        setSelectFamille(res.data.Client.famille_client),
        setSelectRepresentant(res.data.Client.Representant),
        setSelectChargeAffaire(res.data.Client.Charge),
        setGerant(res.data.Client.gerant),
        setCin(res.data.Client.cin),
        setDateOuverture(new Date(res.data.Client.dateOuverture)),
        setEtatClient(res.data.Client.actif),
        setTel(res.data.Client.telephone),
        setFax(res.data.Client.fax),
        setEmail(res.data.Client.email),
        setSiteInternet(res.data.Client.siteInternet),
        setSoldeVenteCredit(res.data.Client.soldeCredit),
        setSoldeVenteEcheance(res.data.Client.soldeEcheance),
        setPlafondVenteCredit(res.data.Client.plafondCredit),
        setPlafondVenteEcheance(res.data.Client.plafondEcheance),
        setEcheanceSaisieRegl(res.data.Client.echeance_reglement),
        setSignalerDepassEche(res.data.Client.depasseEcheance),
        setEtatAutDepassRemiseSec(res.data.Client.depasseRemiseSecurite),
        setAutDepassRemiseSec(res.data.Client.nombre_mois),
        setEtatAutCalcSeuilCredAut(res.data.Client.calculSeuilleCredit),
        setAdresseLiv(res.data.Client.adresseLivraison),
        setSelectGouvernoratLiv(res.data.Client.gouvernorat_livraison),
        setSelectDelegationLiv(res.data.Client.Delegation_Livraison),
        setSelectMunicipaliteLiv(res.data.Client.municipaliteLivraison),
        setCodePostalLiv(res.data.Client.codePostalLivraison),
        setAdresseFact(res.data.Client.adresseFacturation),
        setSelectGouvernoratFact(res.data.Client.gouvernorat_facturation),
        setSelectDelegationFact(res.data.Client.DelegationFacturation),
        setSelectMunicipaliteFact(res.data.Client.MunicipaliteFacturation),
        setCodePostalFact(res.data.Client.codePostalFacturation),
        setCodeTva(res.data.Client.codeTVA),
        setEtatTimbre(res.data.Client.timbre),
        setEtatFodec(res.data.Client.fodec),
        setEtatRegime(res.data.Client.regimeForfutaire),
        setEtatComptabilite(res.data.Client.comptabilite),
        setEtatAssujetti(res.data.Client.assujeti),
        setEtat(res.data.Client.etat)
      setExp(res.data.Client.export),
        setDebutExoneration(new Date(res.data.Client.debutExonoration)),
        setFinExoneration(new Date(res.data.Client.finExonoration)),
        setMntPlafExo(res.data.Client.montantPlafonExonoration),
        setSelectFormeJuridique(res.data.Client.FormeJuridique),
        setSelectModeRegl(res.data.Client.ModeReglement),
        setSelectMatBanc(res.data.Client.banque),
        setRibBanc(res.data.Client.rib),
        setEtatBloquerFact(res.data.Client.bloqueFacturation),
        setEtatContentieux(res.data.Client.contentieux),
        setSelectTypeRemise(res.data.Client.TypeRemise),
        setObservation(res.data.Client.observation),
        setGarantie(res.data.Client.garantie),
        setAvis(res.data.Client.avis),
        //
        setDateCreation(new Date(res.data.Client.dateCreation))
      setCree(res.data.Client.utilisateur)
      //   setDatePrMvt(res.data.Client.datePremiereMouvement)
      setPiecePrMvt(res.data.Client.nombrePiecePremierMouvement)
      //   setDateDerMvt(res.data.Client.dateDernierMouvement)
      setPieceDerMvt(res.data.Client.nombrePieceDernierMouvement)
      //
      setLoading(true)
      // code client
      const resCo = API.get("client/code").then(resCo => {
        setCode(resCo.data.Code.code)
        setCodeCompt(resCo.data.Code.code_contable)
        setLoading(true)
      })
      // Famille client
      const resF = API.get("famille/client/select").then(resF => {
        setFamille(resF.data.FamilleClient)
      })
      // representant
      const resR = API.get("personnel/select").then(resR => {
        setRepresentant(resR.data.Personnel)
      })
      // charge d'affaire
      const resC = API.get("personnel/select").then(resC => {
        setChargeAffaire(resC.data.Personnel)
      })
      // gouvernerat livraison
      const resG = API.get("gouvernerat/select").then(resG => {
        setGouvernoratLiv(resG.data.Gouvernerat)
      })
      // delegation livraison
      const resM = API.get("minicipalite/select").then(resM => {
        setMunicipaliteLiv(resM.data.Delegation)
      })
      // municipalite livraison
      const resD = API.get("delegation/select").then(resD => {
        setDelegationLiv(resD.data.Minicipalite)
      })
      // gouvernerat facturation
      const resGF = API.get("gouvernerat/select").then(resGF => {
        setGouvernoratFact(resGF.data.Gouvernerat)
      })
      // delegation facturation
      const resDF = API.get("minicipalite/select").then(resDF => {
        setMunicipaliteFact(resDF.data.Delegation)
      })
      // municipalite facturation
      const resMF = API.get("delegation/select").then(resMF => {
        setDelegationFact(resMF.data.Minicipalite)
      })
      // forme juridique
      const resFO = API.get("forme/juridique/select").then(resFO => {
        setFormeJuridique(resFO.data.FormeJuridique)
      })
      // mode reglement
      const resP = API.get("mode/reglement/select").then(resP => {
        setModeReglement(resP.data.ModeReglement)
      })
      // matricule bancaire
      const resMB = API.get("banque/matricule/select").then(resMB => {
        setMatBanc(resMB.data.MatriculeBancaire)
      })
      // type remise
      const resT = API.get("type/remise/select").then(resT => {
        setTypeRemise(resT.data.TypeRemise)
      })
    })
  }, [])

  const changeEtatClient = e => {
    if (e.target.checked) {
      setEtatClient(1)
    } else {
      setEtatClient(0)
    }
  }

  const changeEtatDepassRemiseSec = e => {
    if (e.target.checked) {
      setEtatAutDepassRemiseSec(1)
    } else {
      setEtatAutDepassRemiseSec(0)
    }
  }

  const changeEtatCalcSeuilCreditAut = e => {
    if (e.target.checked) {
      setEtatAutCalcSeuilCredAut(1)
    } else {
      setEtatAutCalcSeuilCredAut(0)
    }
  }

  const getDelegationLiv = e => {
    setSelectGouvernoratLiv(e)
    const res = API.post("delegation/gouvernerat/delegation/select", {
      gouvernerat_id: e.value,
    }).then(res => {
      setDelegationLiv(res.data.Delegation)
    })
  }

  const getMunicipaliteLiv = e => {
    setSelectDelegationLiv(e)
    const res = API.post("minicipalite/delegation/minicipalite/select", {
      delegation_id: e.value,
    }).then(res => {
      setMunicipaliteLiv(res.data.Minicipalite)
    })
  }

  const getDelegationFact = e => {
    setSelectGouvernoratFact(e)
    const res = API.post("delegation/gouvernerat/delegation/select", {
      gouvernerat_id: e.value,
    }).then(res => {
      setDelegationFact(res.data.Delegation)
    })
  }

  const getMunicipaliteFact = e => {
    setSelectDelegationFact(e)
    const res = API.post("minicipalite/delegation/minicipalite/select", {
      delegation_id: e.value,
    }).then(res => {
      setMunicipaliteFact(res.data.Minicipalite)
    })
  }

  const cocheTimbre = e => {
    if (e.target.checked) {
      setEtatTimbre(1)
    } else {
      setEtatTimbre(0)
    }
  }

  const cocheFodec = e => {
    if (e.target.checked) {
      setEtatFodec(1)
    } else {
      setEtatFodec(0)
    }
  }

  const cocheRegime = e => {
    if (e.target.checked) {
      setEtatRegime(1)
    } else {
      setEtatRegime(0)
    }
  }

  const cocheAssujetti = e => {
    if (e.target.checked) {
      setEtatAssujetti(1)
    } else {
      setEtatAssujetti(0)
    }
  }

  const cocheComptabilite = e => {
    if (e.target.checked) {
      setEtatComptabilite(1)
    } else {
      setEtatComptabilite(0)
    }
  }

  const changeEtatBloqFact = e => {
    if (e.target.checked) {
      setEtatBloquerFact(1)
    } else {
      setEtatBloquerFact(0)
    }
  }

  const changeEtatCont = e => {
    if (e.target.checked) {
      setEtatContentieux(1)
    } else {
      setEtatContentieux(0)
    }
  }

  const save = async () => {
    if (etat == 1) {
      var etatTva = 1
      var etatExonore = 0
      var etatExport = 0
    } else if (etat == 2) {
      var etatTva = 0
      var etatExonore = 1
      var etatExport = 0
    } else if (etat == 3) {
      var etatTva = 0
      var etatExonore = 0
      var etatExport = 1
    }
    // convert date ouverture
    if (dateOuverture != "") {
      let dateR = dateOuverture
      let month = "" + (dateR.getMonth() + 1)
      let day = "" + dateR.getDate()
      let year = dateR.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDateOuverture = [year, month, day].join("-")
    } else {
      var convertDateOuverture = ""
    }
    // convert debut exonération
    if (debutExoneration != "") {
      let dateD = debutExoneration
      let month = "" + (dateD.getMonth() + 1)
      let day = "" + dateD.getDate()
      let year = dateD.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDebutExoneration = [year, month, day].join("-")
    } else {
      var convertDebutExoneration = ""
    }
    // convert fin exonération
    if (finExoneration != "") {
      let dateF = finExoneration
      let month = "" + (dateF.getMonth() + 1)
      let day = "" + dateF.getDate()
      let year = dateF.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertFinExoneration = [year, month, day].join("-")
    } else {
      var convertFinExoneration = ""
    }
    if (selectFamille != "") {
      const res = await API.post("client/update", {
        id: id,
        utilisateur: userName,
        code: code,
        codeComptable: codeCompt,
        raisonSocial: raisonSocial,
        contact: contact,
        famille_client_id: selectFamille.value,
        representant_id: selectRepresentant.value,
        charge_id: selectChargeAffaire.value,
        gerant: gerant,
        cin: cin,
        dateOuverture: convertDateOuverture,
        actif: etatClient,
        telephone: tel,
        fax: fax,
        email: email,
        siteInternet: siteInternet,
        soldeCredit: soldeVenteCredit,
        soldeEcheance: soldeVenteEcheance,
        plafondCredit: plafondVenteCredit,
        plafondEcheance: plafondVenteEcheance,
        echeance_reglement: echeanceSaisieRegl,
        depasseEcheance: signalerDepassEche,
        depasseRemiseSecurite: etatAutDepassRemiseSec,
        nombre_mois: autDepassRemiseSec,
        calculSeuilleCredit: etatAutCalcSeuilCredAut,
        adresseLivraison: adresseLiv,
        gouvernorat_livraison_id: selectGouvernoratLiv.value,
        delegation_livraison_id: selectDelegationLiv.value,
        municipalite_livraison_id: selectMunicipaliteLiv.value,
        codePostalLivraison: codePostalLiv,
        adresseFacturation: adresseFact,
        gouvernorat_facturation_id: selectGouvernoratFact.value,
        delegation_facturation_id: selectDelegationFact.value,
        municipalite_facturation_id: selectMunicipaliteFact.value,
        codePostalFacturation: codePostalFact,
        codeTVA: codeTva,
        timbre: etatTimbre,
        fodec: etatFodec,
        regimeForfutaire: etatRegime,
        comptabilite: etatComptabilite,
        assujeti: etatAssujetti,
        paieTVA: etatTva,
        exonore: etatExonore,
        exportbtn: etatExport,
        export: exp,
        debutExonoration: convertDebutExoneration,
        finExonoration: convertFinExoneration,
        montantPlafonExonoration: mntPlafExo,
        forme_juridique_id: selectFormeJuridique.value,
        mode_reglement_id: selectModeRegl.value,
        banque_id: selectMatBanc.value,
        rib: ribBanc,
        bloqueFacturation: etatBloquerFact,
        contentieux: etatContentieux,
        type_remise_id: selectTypeRemise.value,
        observation: observation,
        garantie: garantie,
        avis: avis,
      }).then(res => {
        props.history.push("/Client")
      })
    } else {
      toast.error("Veuillez selectionner une famille", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client | Cresus </title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row>
              <Form>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Informations :
                        </CardTitle>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input4">
                                Code{" "}
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                value={code}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input6">
                                Code comptable
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                value={codeCompt}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Raison sociale
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                placeholder="Inserer une raison sociale"
                                type="text"
                                className="form-control"
                                value={raisonSocial}
                                onChange={e => setRaisonSocial(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Contact
                              </Label>
                              <Input
                                placeholder="Inserer un contact"
                                type="text"
                                className="form-control"
                                value={contact}
                                onChange={e => setContact(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Famille
                                <span
                                  style={{ color: "red", paddingLeft: "5px" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Select
                                options={famille}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectFamille(e)}
                                value={selectFamille}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Représentant
                              </Label>
                              <Select
                                options={representant}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectRepresentant(e)}
                                value={selectRepresentant}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Chargé d'affaire"}
                              </Label>
                              <Select
                                options={chargeAffaire}
                                isSearchable={true}
                                classNamePrefix="select2-selection"
                                onChange={e => setSelectChargeAffaire(e)}
                                value={selectChargeAffaire}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gérant
                              </Label>
                              <Input
                                name="gerant"
                                placeholder="Inserer un gérant"
                                type="text"
                                className="form-control"
                                value={gerant}
                                onChange={e => setGerant(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">CIN</Label>
                              <Input
                                name="cin"
                                placeholder="Inserer CIN"
                                type="text"
                                className="form-control"
                                value={cin}
                                onChange={e => setCin(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Date d'ouverture"}
                              </Label>
                              <DatePicker
                                className="form-control ddate"
                                selected={dateOuverture}
                                onChange={e => setDateOuverture(e)}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Etat client"}
                              </Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatClient}
                                  onChange={e => changeEtatClient(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Actif
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Contact :
                        </CardTitle>
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Téléphone
                              </Label>
                              <Input
                                name="tel"
                                placeholder="Inserer un téléphone"
                                label="Téléphone"
                                type="number"
                                validate={{
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: "Only Numbers",
                                  },
                                }}
                                onChange={e => setTel(e.target.value)}
                                value={tel}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input14">
                                Fax
                              </Label>
                              <Input
                                name="fax"
                                placeholder="Inserer un Fax"
                                type="number"
                                onChange={e => setFax(e.target.value)}
                                validate={{
                                  pattern: {
                                    value: "^[0-9]+$",
                                    errorMessage: "Only Numbers",
                                  },
                                }}
                                value={fax}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input13">
                                E-mail
                              </Label>
                              <Input
                                name="Email"
                                placeholder="Inserer un E-mail"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Site web
                              </Label>
                              <Input
                                placeholder="Inserer un site web"
                                type="text"
                                className="form-control"
                                value={siteInternet}
                                onChange={e => setSiteInternet(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle style={{ color: "#556ee6" }} className="h4">
                          Solde :
                        </CardTitle>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Solde de vente a crédit
                              </Label>
                              <Input
                                name="solde credit"
                                placeholder="Solde de vente a crédit"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={soldeVenteCredit}
                                onChange={e =>
                                  setSoldeVenteCredit(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Solde de vente a échéance
                              </Label>
                              <Input
                                name="solde echeance"
                                placeholder="Solde de vente a échéance"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={soldeVenteEcheance}
                                onChange={e =>
                                  setSoldeVenteEcheance(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Plafond de vente à crédit
                              </Label>
                              <Input
                                name="plafond vente"
                                placeholder="Plafond de vente à crédit"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={plafondVenteCredit}
                                onChange={e =>
                                  setPlafondVenteCredit(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                Plafond de vente a échéance
                              </Label>
                              <Input
                                name="plafond"
                                placeholder="Plafond de vente a échéance"
                                type="number"
                                step="0.001"
                                className="form-control"
                                value={plafondVenteEcheance}
                                onChange={e =>
                                  setPlafondVenteEcheance(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Echéance saisie reglement
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="echeance"
                                    placeholder="Inserer echéance saisie reglement"
                                    type="number"
                                    min={"1"}
                                    className="form-control"
                                    value={echeanceSaisieRegl}
                                    onChange={e =>
                                      setEcheanceSaisieRegl(e.target.value)
                                    }
                                  />
                                </div>
                                <div style={{ width: "8%" }}>jours</div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {"Signaler le depassement de l'écheance"}
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="Signalement"
                                    placeholder="Inserer le signalement de depassement de l'écheance"
                                    type="number"
                                    min={"1"}
                                    value={signalerDepassEche}
                                    className="form-control"
                                    onChange={e =>
                                      setSignalerDepassEche(e.target.value)
                                    }
                                  />
                                </div>
                                <div style={{ width: "8%" }}>jours</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                <input
                                  style={{ marginRight: "5px" }}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatAutDepassRemiseSec}
                                  onChange={e => changeEtatDepassRemiseSec(e)}
                                />
                                Autoriser le depassement remise de securité
                              </Label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div style={{ width: "90%" }}>
                                  <Input
                                    name="depassement"
                                    placeholder="Inserer le depassement remise de securité"
                                    type="number"
                                    min={"1"}
                                    className="form-control"
                                    value={autDepassRemiseSec}
                                    onChange={e =>
                                      setAutDepassRemiseSec(e.target.value)
                                    }
                                  />
                                </div>
                                <div style={{ width: "8%" }}>mois</div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input5">
                                {"Calcul seuil crédit automatiquement"}
                              </Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={etatAutCalcSeuilCredAut}
                                  onChange={e =>
                                    changeEtatCalcSeuilCreditAut(e)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Autoriser calcul
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Card>
                    <CardBody>
                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              setactiveTab("1")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-home"></i> Adresse
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              setactiveTab("2")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-user"></i> Nature
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-user"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              setactiveTab("3")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fab fa-facebook-messenger"></i>{" "}
                              Divers
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fab fa-facebook-messenger"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              setactiveTab("4")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-eye"></i> Observation
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-eye"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              setactiveTab("5")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-question-circle"></i> Avis
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div style={{ width: "45%" }}>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Livraison :
                              </CardTitle>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Adresse livraison
                                    </Label>
                                    <Input
                                      name="Adresse"
                                      placeholder="Inserer une adresse"
                                      type="text"
                                      className="form-control"
                                      value={adresseLiv}
                                      onChange={e =>
                                        setAdresseLiv(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Gouvernorat
                                    </Label>
                                    <Select
                                      options={gouvernoratLiv}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectGouvernoratLiv}
                                      onChange={e => getDelegationLiv(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Délégation
                                    </Label>
                                    <Select
                                      options={delegationLiv}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectDelegationLiv}
                                      onChange={e => getMunicipaliteLiv(e)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Municipalité
                                    </Label>
                                    <Select
                                      options={municipaliteLiv}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectMunicipaliteLiv}
                                      onChange={e =>
                                        setSelectMunicipaliteLiv(e)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Code postal
                                    </Label>
                                    <Input
                                      name="code"
                                      placeholder="Inserer un code postal"
                                      type="text"
                                      className="form-control"
                                      value={codePostalLiv}
                                      onChange={e =>
                                        setCodePostalLiv(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div style={{ width: "45%" }}>
                              <CardTitle
                                style={{ color: "#556ee6" }}
                                className="h4"
                              >
                                Facturation :
                              </CardTitle>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input12">
                                      Adresse facturation
                                    </Label>
                                    <Input
                                      name="Adresse"
                                      placeholder="Inserer une adresse"
                                      type="text"
                                      className="form-control"
                                      value={adresseFact}
                                      onChange={e =>
                                        setAdresseFact(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Gouvernorat
                                    </Label>
                                    <Select
                                      options={gouvernoratFact}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectGouvernoratFact}
                                      onChange={e => getDelegationFact(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input14">
                                      Délégation
                                    </Label>
                                    <Select
                                      options={delegationFact}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectDelegationFact}
                                      onChange={e => getMunicipaliteFact(e)}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Municipalité
                                    </Label>
                                    <Select
                                      options={municipaliteFact}
                                      isSearchable={true}
                                      classNamePrefix="select2-selection"
                                      value={selectMunicipaliteFact}
                                      onChange={e =>
                                        setSelectMunicipaliteFact(e)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input13">
                                      Code postal
                                    </Label>
                                    <Input
                                      name="code"
                                      placeholder="Inserer un code postal"
                                      type="text"
                                      className="form-control"
                                      value={codePostalFact}
                                      onChange={e =>
                                        setCodePostalFact(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg="6">
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input5">
                                      {"Code TVA"}
                                    </Label>
                                    <Input
                                      placeholder="Inserer un code TVA"
                                      type="text"
                                      className="form-control"
                                      value={codeTva}
                                      onChange={e => setCodeTva(e.target.value)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck1"
                                    checked={etatTimbre}
                                    onChange={e => cocheTimbre(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck1"
                                  >
                                    Timbre
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck2"
                                    checked={etatFodec}
                                    onChange={e => cocheFodec(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck2"
                                  >
                                    Fodek
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck3"
                                    checked={etatRegime}
                                    onChange={e => cocheRegime(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Régime foritaire
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck4"
                                    checked={etatAssujetti}
                                    onChange={e => cocheAssujetti(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Assujetti
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck5"
                                    checked={etatComptabilite}
                                    onChange={e => cocheComptabilite(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck3"
                                  >
                                    Comptabilité
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mt-4">
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline1"
                                    name="customRadioInline1"
                                    className="form-check-input"
                                    checked={etat == 1}
                                    onChange={e => setEtat(1)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Paie TVA
                                  </label>
                                </div>
                                <div className="form-check mb-3">
                                  <Input
                                    type="radio"
                                    id="customRadioInline2"
                                    name="customRadioInline2"
                                    className="form-check-input"
                                    checked={etat == 2}
                                    onChange={e => setEtat(2)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Exonoré
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="customRadioInline3"
                                    name="customRadioInline3"
                                    className="form-check-input"
                                    checked={etat == 3}
                                    onChange={e => setEtat(3)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios3"
                                  >
                                    Export
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <Row>
                                <Col lg="6">
                                  <Label for="basicpill-lastname-input12">
                                    Export
                                  </Label>
                                  <Input
                                    placeholder="Inserer export"
                                    type="text"
                                    className="form-control"
                                    value={exp}
                                    onChange={e => setExp(e.target.value)}
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label>Début exonération</Label>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={debutExoneration}
                                    onChange={e => setDebutExoneration(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col lg="6">
                                  <Label>Fin exonération</Label>
                                  <DatePicker
                                    className="form-control ddate"
                                    selected={finExoneration}
                                    onChange={e => setFinExoneration(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                                <Col lg="6">
                                  <Label for="basicpill-lastname-input12">
                                    {"Montant plafond d'exonération"}
                                  </Label>
                                  <Input
                                    placeholder="Montant plafond d'exonération"
                                    type="number"
                                    step="0.001"
                                    className="form-control"
                                    value={mntPlafExo}
                                    onChange={e =>
                                      setMntPlafExo(e.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Forme juridique
                                </Label>
                                <Select
                                  options={formeJuridique}
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectFormeJuridique}
                                  onChange={e => setSelectFormeJuridique(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  Mode de reglement
                                </Label>
                                <Select
                                  options={modeReglement}
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectModeRegl}
                                  onChange={e => setSelectModeRegl(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Matricule bancaire
                                </Label>
                                <Select
                                  options={matBanc}
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectMatBanc}
                                  onChange={e => setSelectMatBanc(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  RIB bancaire
                                </Label>
                                <Input
                                  name="rib"
                                  placeholder="Inserer un RIB bancaire"
                                  type="text"
                                  className="form-control"
                                  value={ribBanc}
                                  onChange={e => setRibBanc(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  <input
                                    style={{ marginRight: "5px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={etatBloquerFact}
                                    onChange={e => changeEtatBloqFact(e)}
                                  />
                                  Bloquer la facturation
                                </Label>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input14">
                                  <input
                                    style={{ marginRight: "5px" }}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={etatContentieux}
                                    onChange={e => changeEtatCont(e)}
                                  />
                                  Contentieux
                                </Label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Type de remise
                                </Label>
                                <Select
                                  options={typeRemise}
                                  isSearchable={true}
                                  classNamePrefix="select2-selection"
                                  value={selectTypeRemise}
                                  onChange={e => setSelectTypeRemise(e)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Date de création
                                </Label>
                                <DatePicker
                                  className="form-control ddate"
                                  selected={dateCreation}
                                  dateFormat="dd/MM/yyyy"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Crée par
                                </Label>
                                <Input
                                  name="cree"
                                  placeholder="Inserer crée par"
                                  type="text"
                                  className="form-control"
                                  value={cree}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Date premiére mouvement
                                </Label>
                                <DatePicker
                                  className="form-control ddate"
                                  selected={datePrMvt}
                                  dateFormat="dd/MM/yyyy"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  N° de la piéce du premier mouvement
                                </Label>
                                <Input
                                  name="piece"
                                  placeholder="Inserer n° de la piéce du premier mouvement"
                                  type="text"
                                  className="form-control"
                                  value={piecePrMvt}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Date dernier mouvement
                                </Label>
                                <DatePicker
                                  className="form-control ddate"
                                  selected={dateDerMvt}
                                  dateFormat="dd/MM/yyyy"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  N° de la piéce du dernier mouvement
                                </Label>
                                <Input
                                  name="piece"
                                  placeholder="Inserer n° de la piéce du dernier mouvement"
                                  type="text"
                                  className="form-control"
                                  value={pieceDerMvt}
                                  disabled
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Observation
                                </Label>
                                <Input
                                  name="Observation"
                                  placeholder="Inserer une observation"
                                  type="textarea"
                                  className="form-control"
                                  value={observation}
                                  onChange={e => setObservation(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Garantie
                                </Label>
                                <Input
                                  name="Garantie"
                                  placeholder="Inserer une garantie"
                                  type="textarea"
                                  className="form-control"
                                  value={garantie}
                                  onChange={e => setGarantie(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input12">
                                  Avis
                                </Label>
                                <Input
                                  name="avis"
                                  placeholder="Inserer un avis"
                                  type="textarea"
                                  className="form-control"
                                  value={avis}
                                  onChange={e => setAvis(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Row>
              </Form>
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() => props.history.push("/Client")}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    onClick={save}
                    type="submit"
                    className="btn btn-primary "
                  >
                    Confirmer
                  </button>
                </div>
              </Col>
            </div>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              Chargement en cours ...
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default EditClient
EditClient.propTypes = {
  history: PropTypes.object,
}
