import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import API from "../../api"

class PierChartTier extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fournisseur: "",
      client: "",
      prospect: "",
    }
  }
  componentDidMount() {
    const res = API.get("stat_tiers").then(res => {
      this.setState({
        fournisseur: res.data.Stat.fr,
        client: res.data.Stat.clt,
        prospect: res.data.Stat.pros,
      })
    })
  }
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["Client", "Fournisseur", "Prospect"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561"],
      series: [
        {
          name: "Total Tier",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: this.state.client, name: "Client" },
            { value: this.state.fournisseur, name: "Fournisseur" },
            { value: this.state.prospect, name: "Prospect" },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default PierChartTier
